<template>
  <div class="tickets_execution_time">

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-menu
            v-model="menuPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="picker"
                label="Month"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              @input="menuPicker = false"
              type="month"
              min="2023-02"
              :max=current_month
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            disabled
            label="Something else filter"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="dashboardXYChart" ref="div_chart"></div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
// imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';

// init
am4core.useTheme(am4themes_animated);

export default {
  name: 'TicketsExecutionTime',

  data() {
    return {
      chart_data: [],

      mngr_avrg: 0,
      sims_avrg: 0,
      tech_avrg: 0,
      avrg: 0,

      menuPicker: false,
      picker: null,
      current_month: new Date().toISOString().substr(0, 7),

      contracts: [],
      contract_selected: null,

    }
  },

  created() {
    this.getXYChartData().then(this.getXYChart);
  },

  methods: {
    getXYChartData() {
      let self = this;

      return this.$http({
        method: 'get',
        url: 'dashboard_api/tickets_execution/',

        params: {
          month: this.picker,
//          contract: this.contract_selected,

        },
        data: {},
      })
      .then(function (response){
        self.chart_data = response.data.chart_data;

        self.mngr_avrg = response.data.mngr_avrg;
        self.sims_avrg = response.data.sims_avrg;
        self.tech_avrg = response.data.tech_avrg;

        self.avrg = response.data.avrg;

//        self.contracts = response.data.contracts;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    getXYChart() {
      // Create chart instance
      let chart = am4core.create(this.$refs.div_chart, am4charts.XYChart);

      // Add data
      chart.data = this.chart_data;

      // Styles
//      chart.padding(30, 0, 10, 0);
      chart.fontSize = 15;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "ticket_id";
//      categoryAxis.title.text = "Tickets, IDs";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.cellStartLocation = 0.1;
      categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = "Tickets Execution Time, WH";

      // Create series
      function createSeries(field, user, name) {
        let series = chart.series.push(new am4charts.ColumnSeries());

        series.dataFields.valueY = field;
        series.dataFields.categoryX = "ticket_id";
        series.dataFields.user = user;

        series.name = name;
        series.stacked = true;

        series.columns.template.tooltipText = "{name}: [bold]{valueY}[/] ({user})";
        series.columns.template.width = am4core.percent(85);

        return series
      }

      // Create averages
      function createAverages(range_value, range_color ) {
        let average = valueAxis.axisRanges.create();

        average.value = range_value;
        average.grid.stroke = am4core.color(range_color);

        average.grid.strokeWidth = 1;
        average.grid.strokeDasharray = '10, 5'
        average.grid.strokeOpacity = 1;
        average.label.inside = true;
//        average.label.text = range_value;
        average.label.fill = average.grid.stroke;
        average.label.verticalCenter = "bottom";

        return average
      }

      var seriesM = createSeries("mngr", "mngr_user", "Managers");
      var seriesS = createSeries("sims", "sims_user", "SimsSupport");
      var seriesT = createSeries("tech", "tech_user", "TechSupport");

      var averageM = createAverages(this.mngr_avrg, "#67b7dc");
      var averageS = createAverages(this.sims_avrg, "#6794dc");
      var averageT = createAverages(this.tech_avrg, "#6771dc");

      var average = createAverages(this.avrg, "#b64ddc");

//      if (seriesM.isHiding || seriesS.isHiding || seriesT.isHiding) {
//        average.hide();
//      }
//      else {
//        average.show();
//      }

//      average.on("visible", function() {
//        if (seriesM.isShowing || seriesS.isShowing || seriesT.isShowing) {
//          average.set("visible", true)
//        }
//      });

      // Merge & showing series
      seriesM.events.on("shown", function() {
        averageM.show();
        if (!seriesS.isHidden && !seriesT.isHidden) {
          average.show();
        }
      });
      seriesM.events.on("hidden", function() {averageM.hide(); average.hide(); });
      seriesS.events.on("shown", function() {
        averageS.show();
        if (!seriesM.isHidden && !seriesT.isHidden) {
          average.show();
        }
      });
      seriesS.events.on("hidden", function() {averageS.hide(); average.hide(); });
      seriesT.events.on("shown", function() {
        averageT.show();
        if (!seriesM.isHidden && !seriesS.isHidden) {
          average.show();
        }
      });
      seriesT.events.on("hidden", function() {averageT.hide(); average.hide(); });

      // Add cursor
      chart.cursor = new am4charts.XYCursor();

      // Add legend
      chart.legend = new am4charts.Legend();

      // Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.marginBottom = 35;

//      chart.exporting.menu = new am4core.ExportMenu();

    },

  },

  computed: {},

  watch: {
    picker: function() {
      console.log('picker:', this.picker);
      this.getXYChartData().then(this.getXYChart);
    },

//    contract_selected: function() {
//      this.getXYChartData().then(this.getXYChart);
//    },

  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

}
</script>

<style scoped>
.dashboardXYChart {
  width: 100%;
  height: 700px;
}
</style>
