<template>
  <div class="permissions">

    <v-card>

      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
          <v-row>
          <v-col xs6>{{ permissions_with_parents }}</v-col>
          <v-divider vertical></v-divider>
          <v-col xs6>
            <v-treeview
              ref="tree"
              
              v-model="permissions_selected"
              :items="permissions_default"

              dense
              transition
              selection-type="independent"

              return-object
              selectable
            ></v-treeview>
          </v-col>
              </v-row>

      </v-card-text>

    </v-card>

  </div>
</template>

<script>
//import json from './permissions_test.json'

export default {
  name: 'Permissions',

  data() {
    return {

        permissions_selected: [],
        permissions_with_parents: [],

        permissions_default: [
          {
              id: '01 00 00',
              name: 'Dashboard',
              children: [
                  {
                      id: '01 01 00',
                      name: 'Servers',
                      path: '/dash_servers',
                      children: []
                  },
                  {
                      id: '01 02 00',
                      name: 'Payments',
                      path: '/dash_payments',
                      children: []
                  },
                  {
                      id: '01 03 00',
                      name: 'Tickets',
                      path: '/dash_tickets',
                      children: []
                  },
              ]
          },
          {
              id: '02 00 00',
              name: 'Main',
              children: [
                  {
                      id: '02 01 00',
                      name: 'Quantity',
                      path: '/quantity',
                      children: [
                          {
                              id: '02 01 01',
                              name: 'slice_create'
                          },
                          {
                              id: '02 01 02',
                              name: 'qty_edit'
                          },
                          {
                              id: '02 01 03',
                              name: 'change_download'
                          },
                          {
                              id: '02 01 04',
                              name: 'slice_download'
                          },
                      ]
                  },
                  {
                      id: '02 02 00',
                      name: 'Summary',
                      path: '/summary',
                      children: [
                          {
                              id: '02 02 01',
                              name: 'summary_refresh'
                          },
                          {
                              id: '02 02 02',
                              name: 'slice_create'
                          },
                          {
                              id: '02 02 03',
                              name: 'summary_download'
                          },
                          {
                              id: '02 02 04',
                              name: 'order_create'
                          },
                          {
                              id: '02 02 05',
                              name: 'write-off_create'
                          },
                          {
                              id: '02 02 06',
                              name: 'slice_compare'
                          },
                      ]
                  },
                  {
                      id: '02 03 00',
                      name: 'Balance',
                      path: '/balances',
                      children: [
                          {
                              id: '02 03 01',
                              name: 'balances_refresh'
                          },
                          {
                              id: '02 03 02',
                              name: 'letter_send'
                          },
                          {
                              id: '02 03 03',
                              name: 'block_unblock'
                          },
                          {
                              id: '02 03 04',
                              name: 'balances_download'
                          },
                      ]
                  },
              ]
          },
          {
              id: '03 00 00',
              name: 'Settings',
    //          children: []
          },
          {
              id: '04 00 00',
              name: 'Tickets',
    //          children: []
          },
          {
              id: '05 00 00',
              name: 'Laboratory',
    //          children: []
          },

      ],
    }
  },

  created() {},

  mounted() {},

  methods: {},

  computed: {},

  watch: {
//      permissions_selected: function() {
//          console.log('permissions_selected:', this.permissions_selected);
//          console.log()
//      },
    permissions_selected() {
      let _selectedNodes = []
      let _treenodes = this.$refs['tree'].nodes

      console.log('_selectedNodes:', _selectedNodes)
      console.log('_treenodes:', _treenodes)

      for (const key in _treenodes) {
        if (Object.hasOwnProperty.call(_treenodes, key)) {
          const node = _treenodes[key];
          if (node.isSelected || node.isIndeterminate) {
              _selectedNodes.push(node.item)
          }
        }
      }

      this.permissions_with_parents = _selectedNodes;
//        this.permissions_with_parents = _treenodes;
    },


  },

}
</script>

<style scoped>

</style>
