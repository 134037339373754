<template>
  <div class="summary_v2">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogCompare" max-width="1080px" >
        <v-container>
          <v-card-title class="primary white--text">
            <span>{{ $t('quantity.dialog.comparison') }}</span>
          </v-card-title>
          <v-card>

            <v-card-text>
              <v-container>

                <div>Billing Plan: {{ comparePlanDesc }}</div>
<!--                <div>Difference: {{ compareDiff }}</div>-->
                <v-divider class="my-3"></v-divider>

                <div v-if="amountDiff === amountDiffCalc" style="color: darkgreen">
                  New order rows: {{ productsStr }}<br>New order amount: {{ amountDiff }}
                </div>
                <div v-else style="color: darkred">
                  New order rows: {{ productsStr }}<br>New order amount: {{ amountDiff }}
                </div>

                <v-divider class="my-3"></v-divider>

                <div v-for="b in compareBills" :key="b.id">
                  {{ b.id }}: {{ b.counts }} = {{ b.total }}
                </div>

                <div v-if="amountDiffCalc === row_select.item.amount_delta" style="color: darkgreen">
                  Difference amount in slices: {{ amountDiffCalc }}
                </div>
                <div v-else style="color: darkred">
                  Difference amount in slices: {{ amountDiffCalc }}
                </div>

                <v-divider class="my-3"></v-divider>

                <div>New:<br/>
                  <div v-for="n in compareNew" :key="n.id">{{ n }}</div>
                </div>
                <v-divider class="my-3"></v-divider>

                <div>Old:<br/>
                  <div v-for="o in compareOld" :key="o.id">{{ o }}</div>
                </div>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
<!--              <v-btn text @click="close">cancel</v-btn>-->
<!--              <v-btn text @click="saveQty(editedQty)" color="green darken-3">save</v-btn>-->
            </v-card-actions>

          </v-card>
        </v-container>
      </v-dialog>
    <v-dialog v-model="dialogOrder" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Order from Workpiece</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <!-- contract, date, time -->
              <v-layout row wrap>
                <v-flex xs6>
                  <v-autocomplete
                    v-model="editedExtra.contract_name"
                    :items="selected"
                    item-text="contract_name"
                    item-value="contract"
                    label="contract"
                    required
                    :rules="nonEmpty"
                    disabled
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="editedExtra.period"
                    label="period order"
                    prepend-icon="mdi-calendar"
                    readonly
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="editedExtra.date"
                    label="date create"
                    prepend-icon="mdi-calendar"
                    readonly
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="editedExtra.time"
                    label="time create"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    disabled
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- product, qty, cost -->
              <v-layout row wrap
                v-for="(extra, index) in editedExtra.products_list"
                :key="`extra-${index}`"
              >
                <v-flex xs7>
                  <v-autocomplete
                    v-model="extra.product_id"
                    :items="products"
                    item-text="name"
                    item-value="id"
                    label="product"
                    required
                    :rules="nonEmpty"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="extra.qty"
                    type="number"
                    label="quantity"
                    required
                    :rules="nonNegative"
                    prepend-icon="mdi-shopping-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="extra.price"
                    type="number"
                    label="price"
                    required
                    :rules="nonNegative"
                    prepend-icon="mdi-hand-coin-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs1 class="align-self-center d-flex justify-center">
                  <v-icon @click="addProd(extra, editedExtra.products_list)">mdi-plus</v-icon>
                  <v-icon
                      v-show="editedExtra.products_list.length > 1"
                      @click="removeProd(index, editedExtra.products_list)"
                    >mdi-delete-outline
                  </v-icon>
                </v-flex>
              </v-layout>
              <!-- total cost -->
              <v-layout row wrap justify-space-around>
                <v-flex xs4>
                  <v-card-text style="font-size: medium">order total cost: {{ totalCostExtra }} uah</v-card-text>
                </v-flex>
              </v-layout>
              <v-divider class="ma-5"></v-divider>
              <!-- check boxes -->
              <v-layout row wrap justify-space-around>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.is_extra" disabled label="is_extra"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.order_uk" disabled label="UK order"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.act_uk" disabled label="UK act"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.order_mktu" disabled label="MKTU order"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.act_mktu" disabled label="MKTU act"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.send_order" label="send order"></v-checkbox>
                </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">cancel</v-btn>
            <v-btn text @click="createOrder()" :disabled="!orderRules" color="green darken-3">create</v-btn>

          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogWriteOff" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Write-Off from Workpiece</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>
              <v-layout row wrap justify-space-around>
                <v-flex xs6>
                  <v-autocomplete
                    v-model="editedWriteOff.contract_name"
                    :items="selected"
                    item-text="contract_name"
                    item-value="contract"
                    label="contract"
                    required
                    :rules="nonEmpty"
                    disabled
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="editedWriteOff.period"
                    label="period"
                    prepend-icon="mdi-calendar"
                    readonly
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="editedWriteOff.date"
                    label="create"
                    prepend-icon="mdi-calendar"
                    readonly
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="editedWriteOff.amount"
                    type="number"
                    label="amount"
                    required
                    :rules="nonNegative"
                    prepend-icon="mdi-hand-coin-outline"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="ma-5"></v-divider>
              <v-layout row wrap>
                <v-textarea
                  v-model="editedWriteOff.comment"
                  :value="comment_text"
                  label="Comment"
                  :rules="minComment"
                  counter
              ></v-textarea>
              </v-layout>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">cancel</v-btn>
            <v-btn text @click="createWriteOff()" :disabled="!writeOffRules" color="green darken-3">create</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-dialog v-model="messageNewOrder" max-width="300px">
      <v-container>
          <v-card-title class="primary white--text">
<!--            <span>{{ $t('quantity.dialog.comparison') }}</span>-->
            <span>New Order</span>
          </v-card-title>
          <v-card>

            <v-card-text>
              <v-container>
                <span>Order #{{ new_order_number }} was created</span>
              </v-container>
            </v-card-text>
          </v-card>
      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="refreshSummaries()"
                  color="primary"
                  depressed
                  :disabled="selected.length === 0"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Summaries Refresh</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  :disabled="selected.length === 0"
                  @click="newSlice()"
                  color="primary"
                  depressed
              >
                <v-icon color="white">mdi-layers-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Slices</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="downloadSummary()"
                  color="primary"
                  depressed
                  :disabled = "selected.length !== 1"
              >
                <v-icon>mdi-folder-table-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Summary Download</span>
        </v-tooltip>

        <v-flex class="d-flex pl-1">
          <v-card outlined rounded min-width="155" class="d-flex justify-space-between pa-2">
            <v-card flat style="position: absolute; color: darkgray" class="text-caption my-n5">
              <pre> Workpieces </pre>
            </v-card>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    @click="newOrder()"
                    color="primary"
                    depressed
                    :disabled="selected.length !== 1"
                  >
                    <v-icon>mdi-book-plus</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Order Create</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    @click="newWriteOff()"
                    color="primary"
                    depressed
                    :disabled="selected.length !== 1"
                  >
                    <v-icon>mdi-book-minus</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Write-Off Create</span>
            </v-tooltip>
          </v-card>
        </v-flex>

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="picker"
                label="Month"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              @input="menu = false"
              type="month"
              min="2021-10"
              :max=current_month
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="method_selected"
            :items="methods"
            item-text="name"
            item-value="id"
            label="Method"
            clearable
            prepend-icon="mdi-bank-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="type_selected"
            :items="types"
            item-text="name"
            item-value="id"
            label="Type"
            clearable
            prepend-icon="mdi-calendar"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="delta_selected"
            :items="deltas"
            item-text="name"
            item-value="value"
            label="Delta"
            clearable
            prepend-icon="mdi-scale-balance"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="selected"
        :headers="headers"

        :items="summary"
        item-key="contract_id"
        sort-by="name"

        :expanded.sync="expanded"
        @item-expanded="getSlices"
        show-expand
        single-expand

        show-select

        :loading="load"
        loading-text="Summary loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.count_orders="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.count_orders }}
              </div>
            </template>
            <span v-html="item.orders_tooltip"></span>
          </v-tooltip>
        </template>

        <template v-slot:item.count_write_offs="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.count_write_offs }}
              </div>
            </template>
            <span v-html="item.write_offs_tooltip"></span>
          </v-tooltip>
        </template>

        <template v-slot:item.count_workpieces="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.count_workpieces }}
              </div>
            </template>
            <span v-html="item.workpieces_tooltip"></span>
          </v-tooltip>
        </template>

        <template v-slot:item.workpiece_is_primary="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.is_primary">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.workpiece_is_primary }}</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>

            <v-container>
              <v-card>
                <v-card-title>
                  <v-icon color="primary">mdi-layers</v-icon>
                  <v-divider class="mx-4" inset vertical></v-divider>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="px-2">
                        <v-btn
                          :disabled="slicesSelected.length !== 2"
                          @click="compareSlices()"
                          color="primary"
                          depressed
                        >
                          <v-icon color="white">mdi-select-compare</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Compare Slices</span>
                  </v-tooltip>

<!--                  <v-tooltip top>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                      <div v-bind="attrs" v-on="on" class="px-2">-->
<!--                        <v-btn-->
<!--                          :disabled="slicesSelected.length !== 1"-->
<!--                          :href="downloadSlice()"-->
<!--                          color="primary"-->
<!--                          depressed-->
<!--                        >-->
<!--                          <v-icon color="white">mdi-table-arrow-down</v-icon>-->
<!--                        </v-btn>-->
<!--                      </div>-->
<!--                    </template>-->
<!--                    <span>Slice Table Download</span>-->
<!--                  </v-tooltip>-->

                </v-card-title>
                <v-divider></v-divider>

                <v-data-table
                  v-model="slicesSelected"
                  show-select

                  :headers="headersSlice"
                  :items="slices"
                  :item-key="item.id"
                  :colspan="headers.length"

                  :options.sync="options"
                  :server-items-length="totalSlices"

                  :loading="loadSlices"
                  loading-text="Slices loading... Please wait"

                  :footer-props="{'items-per-page-options': [5, 10, 25]}"
                ></v-data-table>

              </v-card>
            </v-container>

          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Summary_v2',

    data() {
      return {
        summary: [],
        selected: [],
        current_month: new Date().toISOString().substr(0, 7),

//        workpieces: [],
//        workpiece_selected: [],

        editedExtra: {products_list: [], },
        defaultExtra: {
          contract_id: null,
          contract_name: '',

          date: new Date().toISOString().substr(0, 10),
          time: new Date().toLocaleTimeString('uk').substr(0, 5),
          period: new Date().toISOString().substr(0, 7),

          products_list: [],

          total: 0,

          is_extra: true,
          order_uk: true,
          act_uk: true,
          order_mktu: true,
          send_order: false,

          act_mktu: false,
          is_simple: false,

        },

        editedWriteOff: {comment: '', amount: 0},
        comment_text: '',

        nonEmpty: [v => !!v || "required"],
        nonNegative: [v => parseInt(v)>0 || "required"],
        minComment: [v => v.length >= 42 || 'Min 42 characters'],

        dialogWriteOff: false,
        dialogOrder: false,

        messageNewOrder: false,
        new_order_number: '',

        slices: [],
        totalSlices: 0,
        slicesSelected: [],
        loadSlices: false,

        options: {
          'page': 1,
          'itemsPerPage': 5,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
        },

        expanded: [],
        row_select: {item: {amount_delta: null}},
        row_expand: false,
        row_reopen: false,
        expand_contract_id: null,

        dialogCompare: false,
        compareData: [],
        compareBills: [],
        compareDiff: [],
        comparePlanDesc: [],

        productsStr: '',
        amountDiff: 0,
        amountDiffCalc: 0,

        compareOld: [],
        compareNew: [],

        menu: false,
        picker: new Date().toISOString().substr(0, 7),

        contracts: [],
        contract_selected: null,

        methods: [],
        method_selected: null,

        types: [],
        type_selected: 1,

        deltas: [],
        delta_selected: 1,

        alert: false,
        overlay: false,
        load: false,

        headers: [
          { text: 'contract', value: 'contract_name' },
          { text: 'update', value: 'date_update' },

          { text: 'orders', value: 'count_orders' },
          { text: 'write_offs', value: 'count_write_offs' },
          { text: 'workpieces', value: 'count_workpieces' },

          { text: 'primary', value: 'workpiece_is_primary' },

          { text: 'summary', value: 'amount_summary' },
          { text: 'amount', value: 'amount_orders' },
          { text: 'delta', value: 'amount_delta' },

          { text: 'delta products', value: 'workpiece_product_rows' },
//          { text: 'products', value: 'slice_products_str' },

          { text: 'slices', value: 'data-table-expand', sortable: false },

        ],

      }
    },

    created() {
      this.getData();
    },

    mounted() {},

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'summary_api/summary/',

          params: {
            date: this.picker,
            method_selected: this.method_selected,
            type_selected: this.type_selected,
            delta_selected: this.delta_selected,
            contract_selected: this.contract_selected,

          },
          data: {},
        })
        .then(function (response) {
          self.summary = response.data.summary;

          self.methods = response.data.methods;
          self.types = response.data.types;
          self.deltas = response.data.deltas;
          self.contracts = response.data.contracts;

          self.products = response.data.products;
          self.product_current_id = response.data.product_current_id

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      refreshSummaries() {
        let self = this;
        self.load = true;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'summary_api/summary/',

          params: {
            date: this.picker,
            method_selected: this.method_selected,
            type_selected: this.type_selected,
            delta_selected: this.delta_selected,
            contract_selected: this.contract_selected,

          },
          data: {
            selected_ids: Array.from(this.selected, x => x.contract_id),

            method: 'refresh_summary',
            action: 'refresh'
          },
        })
        .then(function (response) {
          self.summary = response.data.summary;

          self.selected = []
          self.load = false;
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      downloadSummary() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'get',
          url: 'core_api/get_xlsx_summary/',
          responseType: 'blob',

          params: {
            date: this.picker,
            contract_id: this.selected[0].contract_id,

          },
          data: {},
        })
        .then(function (response){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const fileName = 'summary' + self.selected[0].contract_name + self.picker + '.xlsx';

          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          self.selected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },


      getSlices(item) {
        let self = this;

        this.row_select = item;
        let expand_contract_id = this.row_select.item.contract_id

        this.slicesSelected = [];

        self.loadSlice = true;

        this.$http({
          method: 'get',
          url: 'summary_api/slices/',

          params: {
            date: this.picker,
            expand_contract_id: expand_contract_id,

            pagination: this.options,
          },
          data: {},
        })
        .then(function (response){
          self.slices = response.data.slices;
          self.totalSlices = response.data.slices_count;

          self.loadSlice = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      compareSlices(){
        const self = this;

        this.$http({
          method: 'post',
          url: 'core_api/slices_compare/',

          params: {},
          data: {
            slices_selected: Array.from(this.slicesSelected, x => x.id),
            action: 'compare'
          },
        })
        .then(function (response){
          self.compareData = response.data.compare;

          self.compareBills = response.data.bills;
          self.compareDiff = response.data.diff;
          self.comparePlanDesc = response.data.plan_desc;


          self.productsStr = response.data.products_str;
          self.amountDiff = response.data.diff_amount;
          self.amountDiffCalc = response.data.diff_amount_calc;


          self.compareOld = response.data.old;
          self.compareNew = response.data.new;

          self.dialogCompare = true;
        })
        .catch(function (error) {
          console.log(error);
        })

      },

      newSlice() {
        let self = this;
        self.overlay = true;

        let expand_contract_id = this.row_select.item.contract_id;

        this.$http({
          method: 'post',
          url: 'summary_api/summary/',

          params: {
            date: this.picker,
            method_selected: this.method_selected,
            type_selected: this.type_selected,
            delta_selected: this.delta_selected,
            contract_selected: this.contract_selected,

            expand_contract_id: expand_contract_id,
            pagination: this.options,

          },
          data: {
            selected_ids: Array.from(this.selected, x => x.contract_id),

            method: 'create_slices',
            action: 'slice create',
          },
        })
        .then(function (response){
//          self.slices = response.data.slices;
//          self.totalSlices = response.data.slices_count;

          self.summary = response.data.summary;

          // додавання нового зрізу у відкритий слайдер
          self.totalSlices++;
          self.slices.pop();
          self.slices.unshift(response.data.new_slice);

          self.selected = [];

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })

      },

      downloadSlice() {
        let slice_id = Array.from(this.slicesSelected, x => x.id)[0];

        return 'core_api/get_xlsx_slice/'+slice_id
      },


      createOrder() {
        let self = this;

        self.overlay = true;
        self.dialogOrder = false;

        this.$http({
          method: 'post',
          url: 'summary_api/summary/',

          params: {
            date: this.picker,

            method_selected: this.method_selected,
            type_selected: this.type_selected,
            delta_selected: this.delta_selected,
            contract_selected: this.contract_selected,

          },
          data: {
//            selected_id: this.selected[0].id,
            extra_data: this.editedExtra,

            method: 'wp_to_order',
            action: 'order create'
          },
        })
        .then(function (response) {
          self.summary = response.data.summary;
          self.new_order_number = response.data.new_order;

          self.overlay = false;
          self.messageNewOrder = true;

          self.editedExtra = {products_list: [], };
          self.selected = [];
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      createWriteOff() {
        let self = this;

        self.overlay = true;
        self.dialogWriteOff = false;

        this.$http({
          method: 'post',
          url: 'summary_api/summary/',

          params: {
            date: this.picker,

            method_selected: this.method_selected,
            type_selected: this.type_selected,
            delta_selected: this.delta_selected,
            contract_selected: this.contract_selected,

          },
          data: {
//            selected_id: this.selected[0].id,
            write_off_data: this.editedWriteOff,

            method: 'wp_to_write-off',
            action: 'writeoff create'
          },
        })
        .then(function (response) {
          self.summary = response.data.summary;

          self.overlay = false;
          self.editedWriteOff = {comment: '', amount_delta: 0};
          self.selected = [];
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      newOrder() {
        let workpiece = this.selected[0]

        this.editedExtra = JSON.parse(JSON.stringify(this.defaultExtra));

        for (let key in workpiece.workpiece_products_list) {
  //        console.log('product:', workpiece.products_list[key]);
          this.editedExtra.products_list.push({
            product_id: workpiece.workpiece_products_list[key].id,
            name: workpiece.workpiece_products_list[key].name,
            qty: workpiece.workpiece_products_list[key].qty,
            price: workpiece.workpiece_products_list[key].price,
          });
        }
  //      console.log('this.editedExtra.products_list:', this.editedExtra.products_list)

        this.editedExtra.contract_id = workpiece.contract_id;
        this.editedExtra.contract_name = workpiece.contract_name;

        this.editedExtra.period = workpiece.workpiece_date_period
        this.editedExtra.total = workpiece.amount
        this.editedExtra.is_extra = !workpiece.workpiece_is_primary
        this.editedExtra.order_mktu = !workpiece.workpiece_is_simple

        this.dialogOrder = true;

//        console.log('editedExtra:', this.editedExtra);
      },

      newWriteOff() {
        let write_off = this.selected[0]

        this.editedWriteOff.contract_id = write_off.contract_id
        this.editedWriteOff.contract_name = write_off.contract_name

        this.editedWriteOff.workpiece_id = write_off.workpiece_id

        this.editedWriteOff.amount = write_off.amount_delta
        this.editedWriteOff.period = write_off.workpiece_date_period
        this.editedWriteOff.date = this.defaultExtra.date
        this.editedWriteOff.comment = ''

        this.dialogWriteOff = true;

        console.log('editedWriteOff:', this.editedWriteOff);
      },

      addProd(value, prodRow) {
        prodRow.push({ product_id: this.product_current_id, qty: 0, price: 0 });
      },

      removeProd(index, prodRow) {
        prodRow.splice(index, 1);
      },

      close() {
        this.dialogOrder = false;
        this.dialogWriteOff = false;

        this.editedExtra = {products_list: [], };
        this.editedWriteOff = {comment: '', amount_delta: 0};
      },

    },

    computed: {
      totalCostExtra() {
        return this.editedExtra.products_list.reduce((total, cur) => total + parseInt(cur.qty) * parseFloat(cur.price), 0)
      },

      orderRules() {
        return this.editedExtra.contract_id &&
            this.editedExtra.products_list.every(e => parseInt(e.qty) > 0) &&
            this.editedExtra.products_list.every(e => parseFloat(e.price) > 0) &&
            this.totalCostExtra <= this.selected[0].amount_delta
      },

      writeOffRules() {
        return this.editedWriteOff.comment.length > 41 &&
          this.editedWriteOff.amount <= this.selected[0].amount_delta
      },

      headersSlice() {
        return [
          {text: this.$t('quantity.headers_slice.id'), value: 'id'},
          {text: this.$t('quantity.headers_slice.date'), value: 'date'},

          {text: this.$t('quantity.headers_slice.all'), value: 'data.counters.count', sortable: false},
          {text: this.$t('quantity.headers_slice.psd'), value: 'data.counters.paused', sortable: false},

          {text: this.$t('quantity.headers_slice.dut'), value: 'data.counters.duts', sortable: false},
          {text: this.$t('quantity.headers_slice.drt'), value: 'data.counters.drts', sortable: false},
          {text: this.$t('quantity.headers_slice.can'), value: 'data.counters.cans', sortable: false},
          {text: this.$t('quantity.headers_slice.sim'), value: 'data.counters.sims', sortable: false},

          {text: this.$t('quantity.headers_slice.products'), value: 'products_str', sortable: false},
          {text: this.$t('quantity.headers_slice.billing'), value: 'billing_plan', sortable: false},

          {text: this.$t('quantity.headers_slice.amount'), value: 'amount', sortable: false},

        ]
      },

    },

    watch: {
      picker: function() {
          this.selected = [];
          this.getData();
      },
      method_selected: function() {
          this.selected = [];
          this.getData();
      },
      type_selected: function() {
          this.selected = [];
          this.getData();
      },
      delta_selected: function() {
          this.selected = [];
          this.getData();
      },
      contract_selected: function() {
          this.selected = [];
          this.getData();
      },

      expanded() {
//        console.log('expanded', this.expanded);
//        console.log('row_select.item.amount_delta', this.row_select.item.amount_delta);
        if (!this.expanded.length) {
          this.options.page = 1;
          this.options.itemsPerPage = 5;
        }
      },

      options: {
        handler() {
//          console.log('options.page:', this.options.page, this.options.itemsPerPage);
          this.getSlices(this.row_select);
        },
        deep: true,
      },

    },

  }
</script>

<style scoped>

</style>
