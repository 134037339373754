<template>
  <div class="tickets_state_creation">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogNewObject" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New Object(s) Create</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-layout row wrap
                v-for="(obj, index) in create_box"
                :key="`extra-${index}`"
              >

              <v-flex xs1 class="mt-2">{{index+1}}.</v-flex>

              <v-flex xs4 class="pr-2">
                <v-text-field
                  v-model="obj.name"
                  label="name"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs2 class="pr-2">
                <v-text-field
                  v-model="obj.imei"
                  label="imei"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs2 class="pr-2">
                <v-text-field
                  v-model="obj.phone_1"
                  label="phone 1"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs2 class="pr-2">
                <v-text-field
                  v-model="obj.phone_2"
                  label="phone 2"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-flex>

              <v-flex xs1 class="align-self-center d-flex justify-center">
                <v-icon @click="addCreateObj(obj, create_box)">mdi-plus</v-icon>
                <v-icon
                    v-show="create_box.length > 1"
                    @click="removeCreateObj(index, create_box)"
                  >mdi-delete-outline
                </v-icon>
              </v-flex>

            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              text
              @click="addObj()"
              color="green darken-3"
              >confirm</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogCheckout" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New Ticket</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <div v-if="cart.pause.length > 0">
                <v-layout row wrap><span class="font-weight-bold">Pause:</span></v-layout>

                <v-layout row wrap class="pb-1"
                  v-for="(elem, index) in cart.pause"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4>{{ elem.name }}</v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs2>{{ elem.phone_1 }}</v-flex>
                  <v-flex xs2>{{ elem.phone_2 }}</v-flex>

                  <v-flex xs1>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon @click="removeObj(index, cart.pause)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>
              <div v-if="cart.active.length > 0">
                <v-layout row wrap><span class="font-weight-bold">Active:</span></v-layout>

                <v-layout row wrap class="pb-1"
                  v-for="(elem, index) in cart.active"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4>{{ elem.name }}</v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs2>{{ elem.phone_1 }}</v-flex>
                  <v-flex xs2>{{ elem.phone_2 }}</v-flex>

                  <v-flex xs1>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon @click="removeObj(index, cart.active)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>

              <div v-if="cart.external.length > 0">
                <v-layout row wrap><span class="font-weight-bold">External:</span></v-layout>

                <v-layout row wrap class="pb-1"
                  v-for="(elem, index) in cart.external"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4>{{ elem.name }}</v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs2>{{ elem.phone_1 }}</v-flex>
                  <v-flex xs2>{{ elem.phone_2 }}</v-flex>

                  <v-flex xs1>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon @click="removeObj(index, cart.external)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>
              <div v-if="cart.internal.length > 0">
                <v-layout row wrap><span class="font-weight-bold">Internal:</span></v-layout>

                <v-layout row wrap class="pb-1"
                  v-for="(elem, index) in cart.internal"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4>{{ elem.name }}</v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs2>{{ elem.phone_1 }}</v-flex>
                  <v-flex xs2>{{ elem.phone_2 }}</v-flex>

                  <v-flex xs1>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon @click="removeObj(index, cart.internal)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>

              <div v-if="cart.rename.length > 0">
                <v-layout row wrap><span class="font-weight-bold">reName:</span></v-layout>

                <v-layout row wrap align-center class="pb-1"
                  v-for="(elem, index) in cart.rename"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      v-model="elem.name"
                      label="name"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs2>{{ elem.phone_1 }}</v-flex>
                  <v-flex xs2>{{ elem.phone_2 }}</v-flex>

                  <v-flex xs1>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon @click="removeObj(index, cart.rename)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>
              <div v-if="cart.resims.length > 0">
                <v-layout row wrap><span class="font-weight-bold">reSIM(s):</span></v-layout>

                <v-layout row wrap align-center class="pb-1"
                  v-for="(elem, index) in cart.resims"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4>{{ elem.name }}</v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs2 class="pr-2">
                    <v-text-field
                      v-model="elem.phone_1"
                      label="phone 1"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-2">
                    <v-text-field
                      v-model="elem.phone_2"
                      label="phone 2"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs1>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon @click="removeObj(index, cart.resims)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>

              <div v-if="cart.delete.length > 0">
                <v-layout row wrap><span class="font-weight-bold">Delete:</span></v-layout>

                <v-layout row wrap class="pb-1"
                  v-for="(elem, index) in cart.delete"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4>{{ elem.name }}</v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs2>{{ elem.phone_1 }}</v-flex>
                  <v-flex xs2>{{ elem.phone_2 }}</v-flex>

                  <v-flex xs1>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon @click="removeObj(index, cart.delete)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>
              <div v-if="cart.create.length > 0">
                <v-layout row wrap><span class="font-weight-bold">Create:</span></v-layout>

                <v-layout row wrap align-center class="pb-1"
                  v-for="(elem, index) in cart.create"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4 class="pr-2">
                    <v-text-field
                      v-model="elem.name"
                      label="name"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-2">
                    <v-text-field
                      v-model="elem.imei"
                      label="imei"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs2 class="pr-2">
                    <v-text-field
                      v-model="elem.phone_1"
                      label="phone 1"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pr-2">
                    <v-text-field
                      v-model="elem.phone_2"
                      label="phone 2"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs1>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon @click="removeObj(index, cart.create)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>

              <v-layout row wrap>

<!-- request email choose -->
                <v-flex xs4>
                <v-autocomplete
                    v-model="email_selected"
                    :items="emails"
                    item-text="name"
                    item-value="name"
                    label="allowed email"
                    prepend-icon="mdi-email-outline"
                    clearable
                    required
                  ></v-autocomplete>
              </v-flex>
<!-- set email (phone) -->
                <v-flex xs4>
                <v-text-field
                    v-model="unknown_email"
                    label="unknow contact"
                    prepend-icon="mdi-account-question-outline"
                    :disabled="email_selected !== null"
                ></v-text-field>
              </v-flex>
<!-- reason file -->

              </v-layout>

              <v-layout row wrap>
                <v-flex xs8>
                  <v-file-input
                    v-model="reason_file"
                    placeholder="Upload your documents"
                    label="Reason document"
                    prepend-icon="mdi-paperclip"

                  >
<!--                    :rules="rulesRequired"-->
                    <template v-slot:selection="{ text }">
                      <v-chip label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
              </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              text
              @click="saveCart"
              color="green darken-3"
              :disabled="!reason_file || (!email_selected && !unknown_email) || !cartQty"
              >confirm</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_filter_selected"
            :items="contract_filters"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="ticket_selected"
        :headers="headers"
        :items="contracts_data"

        item-key="contract_id"
        :expanded.sync="expanded"
        @item-expanded="getObjects"
        show-expand
        single-expand

        :loading="load"
        loading-text="Tickets loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>
            <v-container>

              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>

                  <v-card>
                    <v-card-title>
                      <v-icon color="primary">mdi-calendar-month-outline</v-icon>
                      <v-divider class="mx-4" inset vertical></v-divider>
<!-- Buttons -->
                      <div class="d-flex justify-left align-center">
    <!-- Navigation -->
                        <v-flex class="d-flex">
                          <v-card outlined rounded min-width="155" class="d-flex justify-space-between pa-2">
                            <v-card flat style="position: absolute; color: darkgray" class="text-caption my-n5">
                              <pre> Navigation </pre>
                            </v-card>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <v-badge
                                    :content="objects_selected.filter(elem => elem.paused === false).length"
                                    :value="objects_selected.filter(elem => elem.paused === false).length"
                                    color="primary"
                                    bordered
                                  >
                                    <v-btn
                                      @click="addObj(cart.pause)"
                                      color="primary"
                                      depressed
                                      :disabled = "objects_selected.some(elem => elem.paused === true) || objects_selected.length < 1"
                                    >
                                      <v-icon color="white">mdi-pause</v-icon>
                                    </v-btn>
                                  </v-badge>
                                </div>
                              </template>
                              <span>Pause</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <v-badge
                                    :content="objects_selected.filter(elem => elem.paused === true).length"
                                    :value="objects_selected.filter(elem => elem.paused === true).length"
                                    color="primary"
                                    bordered
                                  >
                                    <v-btn
                                      @click="addObj(cart.active)"
                                      color="primary"
                                      depressed
                                      class="px-2"
                                      :disabled = "objects_selected.some(elem => elem.paused === false) || objects_selected.length < 1"
                                    >
                                      <v-icon color="white">mdi-play</v-icon>
                                    </v-btn>
                                  </v-badge>
                                </div>
                              </template>
                              <span>Activate</span>
                            </v-tooltip>
                          </v-card>
                        </v-flex>
    <!-- Roaming -->
                        <v-flex class="d-flex pl-2">
                          <v-card outlined rounded min-width="155" class="d-flex justify-space-between pa-2">
                            <v-card flat style="position: absolute; color: darkgray" class="text-caption my-n5">
                              <pre> Roaming </pre>
                            </v-card>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <v-badge
                                    :content="objects_selected.filter(elem => elem.sim === false).length"
                                    :value="objects_selected.filter(elem => elem.sim === false).length"
                                    color="primary"
                                    bordered
                                  >
                                    <v-btn
                                      @click="addObj(cart.external)"
                                      color="primary"
                                      depressed
                                      :disabled = "objects_selected.some(elem => elem.sim === true) || objects_selected.length < 1"
                                    >
                                      <v-icon color="white">mdi-sim-outline</v-icon>
                                    </v-btn>
                                  </v-badge>
                                </div>
                              </template>
                              <span>External</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <v-badge
                                    :content="objects_selected.filter(elem => elem.sim === true).length"
                                    :value="objects_selected.filter(elem => elem.sim === true).length"
                                    color="primary"
                                    bordered
                                  >
                                    <v-btn
                                      @click="addObj(cart.internal)"
                                      color="primary"
                                      depressed
                                      class="px-2"
                                      :disabled = "objects_selected.some(elem => elem.sim === false) || objects_selected.length < 1"
                                    >
                                      <v-icon color="white">mdi-sim-off-outline</v-icon>
                                    </v-btn>
                                  </v-badge>
                                </div>
                              </template>
                              <span>Internal</span>
                            </v-tooltip>
                          </v-card>
                        </v-flex>
    <!-- Edit -->
                        <v-flex class="d-flex pl-2">
                          <v-card outlined rounded min-width="155" class="d-flex justify-space-between pa-2">
                            <v-card flat style="position: absolute; color: darkgray" class="text-caption my-n5">
                              <pre> Edit </pre>
                            </v-card>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <v-badge
                                    :content="objects_selected.length"
                                    :value="objects_selected.length"
                                    color="primary"
                                    bordered
                                  >
                                    <v-btn
                                      :disabled="objects_selected.length < 1"
                                      @click="addObj(cart.rename)"
                                      color="primary"
                                      depressed
                                    >
                                      <v-icon color="white">mdi-pencil-outline</v-icon>
                                    </v-btn>
                                  </v-badge>
                                </div>
                              </template>
                              <span>reName</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <v-badge
                                    :content="objects_selected.length"
                                    :value="objects_selected.length"
                                    color="primary"
                                    bordered
                                  >
                                    <v-btn
                                      :disabled="objects_selected.length < 1"
                                      @click="addObj(cart.resims)"
                                      color="primary"
                                      depressed
                                    >
                                      <v-icon color="white">mdi-sim-alert-outline</v-icon>
                                    </v-btn>
                                  </v-badge>
                                </div>
                              </template>
                              <span>reSIM(s)</span>
                            </v-tooltip>
                          </v-card>
                        </v-flex>
    <!-- Delete -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="pl-2">
                              <v-badge
                                :content="objects_selected.length"
                                :value="objects_selected.length"
                                color="primary"
                                bordered
                              >
                                <v-btn
                                  :disabled="objects_selected.length < 1"
                                  @click="addObj(cart.delete)"
                                  color="primary"
                                  depressed
                                >
                                  <v-icon color="white">mdi-close</v-icon>
                                </v-btn>
                              </v-badge>
                            </div>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
    <!-- Create -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="pl-2">
<!--                              <v-badge-->
<!--                                :content="objects_selected.length"-->
<!--                                :value="objects_selected.length"-->
<!--                                color="primary"-->
<!--                                bordered-->
<!--                              >-->

<!--                              @click="addObj(cart.delete)"-->

                                <v-btn
                                  @click="dialogNewObject=true"
                                  color="primary"
                                  depressed
                                >
                                  <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
<!--                              </v-badge>-->
                            </div>
                          </template>
                          <span>Create</span>
                        </v-tooltip>

                      </div>
                      <v-spacer></v-spacer>
<!-- Cart, Filter -->
                      <div class="d-flex justify-right align-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-5">
                              <v-badge
                                :content="cartQty"
                                :value="cartQty"
                                color="primary"
                                bordered
                              >
                                <v-btn
                                  @click="checkoutCart()"
                                  color="primary"
                                  depressed
                                  :disabled="cartQty < 1"
                                >
                                <v-icon color="white">mdi-cart-outline</v-icon>

                              </v-btn>
                            </v-badge>
                          </div>
                        </template>
                        <span>Cart</span>
                      </v-tooltip>
                        <v-text-field
                          v-model="filter"
                          label="Filter"
                          dense
                          outlined
                          hide-details
                        ></v-text-field>
                      </div>
                    </v-card-title>
                    <v-divider></v-divider>

<!--                        :options.sync="options"-->
<!--                        :server-items-length="totalSlices"-->
                    <v-data-table
                      v-model="objects_selected"

                      :headers="headersObjects"
                      :items="objects"
                      :item-key="item.id"
                      :colspan="headers.length"

                      show-select

                      :search="filter"

                      :loading="loadObjects"
                      loading-text="Objects loading... Please wait"

                      :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                    >

                      <template v-slot:item.paused="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-icon v-if="item.paused">mdi-check</v-icon>
                              <v-icon v-else>mdi-window-close</v-icon>
                            </div>
                          </template>
                          <span>{{ item.paused }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.dut="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-icon v-if="item.dut">mdi-check</v-icon>
                              <v-icon v-else>mdi-window-close</v-icon>
                            </div>
                          </template>
                          <span>{{ item.dut }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.drt="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-icon v-if="item.drt">mdi-check</v-icon>
                              <v-icon v-else>mdi-window-close</v-icon>
                            </div>
                          </template>
                          <span>{{ item.drt }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.can="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-icon v-if="item.can">mdi-check</v-icon>
                              <v-icon v-else>mdi-window-close</v-icon>
                            </div>
                          </template>
                          <span>{{ item.can }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.sim="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-icon v-if="item.sim">mdi-check</v-icon>
                              <v-icon v-else>mdi-window-close</v-icon>
                            </div>
                          </template>
                          <span>{{ item.sim }}</span>
                        </v-tooltip>
                      </template>

                    </v-data-table>
                  </v-card>

                </v-card-text>
              </v-card>

            </v-container>
          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Objects',

  data() {
    return {
      overlay: false,
      load: false,

      loadObjects: false,
      loadTickets: false,

//      dialogAdd: false,
      dialogNewObject: false,
      dialogCheckout: false,
//      dialogService: false,
//      dialogMontage: false,

//      ticket_types: [],
//      ticket_type_selected: {id: 1, name: 'Stage'},

      contracts_data: [],
      ticket_selected: [],

      contract_filters: [],
      contract_filter_selected: [],

      expanded: [],
      filter: '',

      objects: [],
      objects_selected: [],

      emails: [],
      email_selected: null,
      unknown_email: null,

//      tab: null,
//      tabs: [
//        { id: 1, name: 'Objects', icon: 'mdi-calendar-month-outline' },
//        { id: 2, name: 'Tickets', icon: 'mdi-calendar-clock-outline'},
//      ],

//      menu: false,
//      picker: new Date().toISOString().substr(0, 7),
//      current_month: new Date().toISOString().substr(0, 7),

      headers: [
        { text: 'id', value: 'contract_id' },
        { text: 'contract', value: 'contract_name' },

        { text: 'manager', value: 'manager_name' },

        { text: 'tickets', value: 'tickets_total' },
        { text: 'complete', value: 'tickets_complete' },
        { text: 'canceled', value: 'tickets_canceled' },

        { text: 'objs', value: 'data-table-expand', sortable: false },
      ],
      headersObjects: [
        { text: 'id', value: 'id' },
        { text: 'name', value: 'name' },
        { text: 'imei', value: 'imei' },

        { text: 'psd', value: 'paused' },
        { text: 'dut', value: 'dut' },
        { text: 'drt', value: 'drt' },
        { text: 'can', value: 'can' },
        { text: 'sim', value: 'sim' },

        { text: 'phone 1', value: 'phone_1' },
        { text: 'phone 2', value: 'phone_2' },

        { text: 'cost', value: 'cost' },

        { text: 'last data', value: 'last' },
      ],

      cart: {pause: [], active: [], delete: [], create: [], external: [], internal: [], rename: [], resims: [], },
//      cart_service: {},
//      cart_montage: {},

      create_box: [{
        id: '*' + Math.floor(Math.random()*1000),
//        id: '',

        name: '',
        imei: '',

        phone_1: '',
        phone_2: '',

        last: '',

      }, ],  // об'єкти на створення в діалозі dialogNewObject

      reason_file: null,
      ticket_id: null,

    }
  },

  created() {
      this.getData();

//      if (localStorage.getItem('cart')) {
//        this.cart = JSON.parse(localStorage.cart);
//      }

    },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/contracts/',

        params: {
          contract_filter_selected: this.contract_filter_selected,

        },
        data: {},
      })
      .then(function (response) {
        self.contracts_data = response.data.contracts_data;
        self.contract_filters = response.data.contract_filters;

        self.ticket_types = response.data.ticket_types;
        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    getObjects(item) {
      console.log('item:', item);

      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/objects/',

        params: {
          contract_expand_id: item.item.contract_id,

        },
        data: {},
      })
      .then(function (response) {
        self.objects = response.data.objects;
        self.emails = response.data.emails;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveCart() {
      let self = this;
      self.load = true;

      self.overlay = true;
      self.dialogCheckout = false;

      this.$http({
        method: 'post',
        url: 'ticket_api/contracts/',

        params: {
          contract_id: this.contract_selected,

        },
        data: {
          cart: this.cart,

          allowed_email: this.email_selected,
          unknown_email: this.unknown_email,

          selected_contract_id: this.expanded[0].contract_id,

          method: 'create_ticket',
          action: 'ticket create',

        },
      })
      .then(function (response) {
        self.ticket_id = response.data.new_ticket_id;
        console.log('new_ticket_id from server:', response.data.new_ticket_id)

        self.cart = {pause: [], active: [], external: [], internal: [], create: [], delete: [], rename: [], resims: [], }

        self.saveFile();

        self.contracts_data = response.data.contracts_data;
        self.contract_filters = response.data.contract_filters;

        self.dialogCheckout = false;
        self.overlay = false;
        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveFile() {
      console.log('ticket_id in saveFile:', this.ticket_id)

      let self = this;
      self.load = true;
      self.overlay = true;

      let formData = new FormData();

      formData.append('ticket_id', this.ticket_id);
      formData.append('ticket_type', 'stage');

      formData.append('file', this.reason_file, this.reason_file.name);
      formData.append('file_name', this.reason_file.name);

//      formData.append('method', 'save_file');
//      formData.append('action', 'file save');

      this.$http({
        method: 'post',
        url: 'ticket_api/save_reason_file/',

        params: {
          contract_id: this.contract_selected,

        },
        data: formData,
      })
      .then(function (response) {
//        self.tickets = response.data.tickets;
//        self.contracts = response.data.contracts;
        console.log('file save success:', response.data.success)

        self.sendManagerEmail();
//        self.dialogCheckout = false;
//
//        self.load = false;
//        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    sendManagerEmail() {
      console.log('ticket_id in sendManagerEmail:', this.ticket_id)

//      let self = this;
//      self.load = true;
//      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/send_manager_email/',

        params: {},
        data: {
          ticket_id: this.ticket_id,
        },
      })
      .then(function (response) {
        console.log('send email success:', response)

//        self.dialogCheckout = false;
//
//        self.load = false;
//        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    // методи додавання/видалення в кошик нового Завдання
    addObj(objAction) {
      console.log('objAction:', objAction);

      let self = this;
      var props = [
          'id', 'imei', 'name',
          'paused', 'dut', 'drt', 'can', 'sim',
          'phone_1', 'phone_2',
          'cost', 'creator', 'last'];

      var result = self.objects_selected.filter(function(o1){
          return !objAction.some(function(o2){
              return o1.id === o2.id;
          });
      }).map(function(o){
          return props.reduce(function(newo, name){
              newo[name] = o[name];
              return newo;
          }, {});
      });

//      console.log('result:', result)
//      https://stackoverflow.com/questions/32965688/


      console.log('result:', result);

      if (!objAction) {
        this.cart.create.push.apply(this.cart.create, this.create_box)
      } else {
        objAction.push.apply(objAction, result)
      }


//      if (action === 'pause') {
//        this.cart.pause.push.apply(this.cart.pause, this.objects_selected)
//      } else if (action === 'active') {
//        this.cart.active.push.apply(this.cart.active, this.objects_selected)
//      } else if (action === 'delete') {
//        this.cart.delete.push.apply(this.cart.delete, this.objects_selected)
//      } else if (action === 'external') {
//        this.cart.external.push.apply(this.cart.external, this.objects_selected)
//      } else if (action === 'internal') {
//        this.cart.internal.push.apply(this.cart.internal, this.objects_selected)
//
////      } else if (action === 'rename') {
////        this.cart.rename.push.apply(this.cart.rename, this.objects_selected)
//
//      }

      this.objects_selected = [];
      this.create_box = [{
          id: '*' + Math.floor(Math.random()*1000),
//          id: '',

          name: '',
          imei: '',

          phone_1: '',
          phone_2: '',

          last: '',

        },
      ];
      this.dialogNewObject = false;

      console.log('cart:', this.cart);

    },
    removeObj(index, objAction) {
      objAction.splice(index, 1);
    },

    // методи додавання/видалення об'єктів на створення
    addCreateObj(value, prodRow) {
        prodRow.push({
          id: '*' + Math.floor(Math.random()*1000),
//          id: '',

          name: '',
          imei: '',

          phone_1: '',
          phone_2: '',

          last: '',

        });
      },
    removeCreateObj(index, prodRow) {
      prodRow.splice(index, 1);
    },

    checkoutCart() {
      this.dialogCheckout = true;
    },

    close() {
//      this.dialogAdd = false;
      this.dialogCheckout = false;
      this.dialogNewObject = false;

      this.create_box = [{
          id: '',

          name: '',
          imei: '',

          phone_1: '',
          phone_2: '',

          last: '',

        }];

    },
  },

  computed: {
//    cartEmpty() {
//      let isEmpty = true;
//      for(var key in this.cart){
//        var elem = this.cart[key];
//        if (elem.length > 0) {
//          isEmpty = false
//        }
//      }
//      return isEmpty
//    },

    cartQty() {
      let qty = 0;
      for (var key in this.cart) {
        var elem = this.cart[key];
        qty += elem.length;
      }
      return qty
    },
  },

  watch: {
    expanded() {
//      console.log('expanded', this.expanded);
      this.objects_selected = [];

      this.reason_file = null;

      this.email_selected = null;
      this.unknown_email = null;

      this.cart = {pause: [], active: [], create: [], delete: [], external: [], internal: [], rename: [], resims: [], }

    },

    contract_filter_selected: function() {
      this.getData();
    },

    email_selected: function () {
//      console.log('emails_selected:', this.emails_selected);
//      console.log(this.cartQty);

      if (this.email_selected !== 0) {
        this.unknown_email = null
      }
    },

  },

}
</script>

<style scoped>

</style>
