<template>
  <div class="repair_devices">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogDevice" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Edit Device Data</span>
        </v-card-title>
        <v-card>

          <v-card-text>
          <v-container>
            <v-layout row wrap>

              <v-flex xs6>
                <v-text-field
                  label="Serial"
                  prepend-icon="mdi-checkbox-blank-outline"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-autocomplete
                  v-model="model_selected"
                  :items="models"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="Model"
                  prepend-icon="mdi-checkbox-blank-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>

            </v-layout>

            <v-divider class="ma-7" inset></v-divider>

            <v-layout row wrap align="center">

              <v-flex xs5>
                <v-autocomplete
                  v-model="contractor_selected"
                  :items="contractors"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="Contractor"
                  prepend-icon="mdi-checkbox-blank-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>

              <v-flex xs3>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="picker"
                      label="Buy"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="picker"
                    @input="menu = false"

                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs3>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="picker"
                      label="Sell"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="picker"
                    @input="menu = false"

                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs1>
                <v-checkbox
                  v-model="is_our"
                  label="our">
                </v-checkbox>
              </v-flex>

            </v-layout>

            <v-divider class="ma-7" inset></v-divider>

            <v-layout row wrap>

              <v-flex xs6>
                <v-autocomplete
                  v-model="contractor_selected"
                  :items="contractors"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="prev device"
                  prepend-icon="mdi-checkbox-blank-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>

              <v-flex xs6>
                <v-autocomplete
                  v-model="contractor_selected"
                  :items="contractors"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="next device"
                  prepend-icon="mdi-checkbox-blank-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>

            </v-layout>

          </v-container>
        </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">cancel</v-btn>
          <v-btn
              text
              @click="saveDevice()"
              color="green darken-3"
          >save</v-btn>
        </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="dialogDevice=true"
                color="primary"
                :disabled="selected.length !== 1"
              >
                <v-icon color="white">mdi-pencil-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Edit Device</span>
        </v-tooltip>

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="devices_selected"
            :items="filter_datas.is_ours"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="is_our"
            prepend-icon="mdi-code-block-braces"
            return-object
          ></v-autocomplete>
        </v-flex>


      </v-card-title>

      <v-divider></v-divider>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="devices"

        show-select
        single-select

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalDevices"

        :loading="load"
        loading-text="Repair Devices loading... Please wait"

        mobile-breakpoint="0"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>


      </v-data-table>

    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Devices',

  data() {
    return {
      overlay: false,
      load: false,

      selected: [],
      devices: [],

      devices_selected: [],

      filter_datas: {},

      dialogDevice: false,

//      menu: false,
//      picker: new Date().toISOString().substr(0, 10),
//
//      rules: [v => 13 <= v.length || 'Min 13 characters'],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },

      sortBy: '-id',
      sortDesc: false,

      totalDevices: 0,

      menu: false,
      picker: new Date().toISOString().substr(0, 10),

      headers: [
        { text: 'id', value: 'id' },

        { text: 'serial', value: 'serial', sortable: false },
        { text: 'device', value: 'name', sortable: false },

        { text: 'is_ours', value: 'is_ours', sortable: false },

        { text: 'purch', value: 'purchase_date', sortable: false},
        { text: 'sale', value: 'sale_date', sortable: false},

        { text: 'prev', value: 'prev', sortable: false},
        { text: 'next', value: 'next', sortable: false},

      ],

    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_devices/',

        params: {
          pagination: this.options,

        },
        data: {},
      })
      .then(function (response) {
        self.devices = response.data.devices;
        self.totalDevices = response.data.devices_count;


        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveDevice() {},

    close() {
      this.dialogDevice = false;


//      this.objects_selected = [];
//      this.cart = {service: [], };
    },

  },

  computed: {},

  watch: {
    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

  },


}
</script>

<style scoped>

</style>
