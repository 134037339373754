<template>
  <div class="staff">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogStaff" max-width="600px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>{{ formStaffTitle }}</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <!-- name -->
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedStaff.first_name"
                    :rules="[rules.required]"
                    label="first name"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedStaff.middle_name"
                    label="middle name"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedStaff.last_name"
                    :rules="[rules.required]"
                    label="last name"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="my-6"></v-divider>

              <!-- phone, email -->
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedStaff.phone"
                    v-mask="'+3# (###) ### ## ##'"
                    :rules="[rules.required, rules.number]"
                    label="phone"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedStaff.email"
                    :rules="[rules.required, rules.email]"
                    label="e-mail"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="my-6"></v-divider>

              <!-- role(s) -->
              <v-layout row wrap>
                <v-flex xs2>
                  <v-checkbox
                    v-model="editedStaff.is_admin"
                    label="admin"
                    required
                  ></v-checkbox>
                </v-flex>
                <v-flex xs3>
                  <v-checkbox
                    v-model="editedStaff.is_manager"
                    label="manager"
                    required
                  ></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox
                    v-model="editedStaff.is_support"
                    label="tech"
                    required
                  ></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox
                    v-model="editedStaff.is_sim_support"
                    label="sim"
                    required
                  ></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox
                    v-model="editedStaff.is_counter"
                    label="counter"
                    required
                  ></v-checkbox>
                </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn text @click="saveStaff(editedStaff)" :disabled="!dialogRules" color="green darken-3">save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                @click="newStaff()"
                color="primary"
                depressed
              >
                <v-icon>mdi-account-plus-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Staff</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <!-- Filters -->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="is_admin_selected"
            :items="is_admin_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="admin"
            prepend-icon="mdi-account-cog-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="is_manager_selected"
            :items="is_manager_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="manager"
            prepend-icon="mdi-account-tie-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="is_counter_selected"
            :items="is_counter_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="counter"
            prepend-icon="mdi-account-edit-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="is_support_selected"
            :items="is_support_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="tech supp"
            prepend-icon="mdi-account-wrench-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="is_sim_support_selected"
            :items="is_sim_support_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="sim supp"
            prepend-icon="mdi-account-tag-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="staff"
        sort-by="name"

        :loading="load"
        loading-text="Staff loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.phone="{ item }">
          <span style="white-space: nowrap">{{ item.phone | VMask('+## (###) ### ## ##') }}</span>
        </template>

        <template v-slot:item.is_manager="{ item }">
          <v-icon v-if="item.is_manager">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.is_support="{ item }">
          <v-icon v-if="item.is_support">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.is_sim_support="{ item }">
          <v-icon v-if="item.is_sim_support">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.is_admin="{ item }">
          <v-icon v-if="item.is_admin">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.is_counter="{ item }">
          <v-icon v-if="item.is_counter">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon @click="editStaff(item)" class="mr-2">mdi-pencil</v-icon>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  import Vue from 'vue';
//  import VueMask from 'v-mask';
//  Vue.use(VueMask);

  export default {
    name: 'Staff',

    data() {
      return {
        staff: [],

        load: false,
        overlay: false,

        is_admin_values: [],
        is_manager_values: [],
        is_support_values: [],
        is_sim_support_values: [],
        is_counter_values: [],

        is_admin_selected: [],
        is_manager_selected: [],
        is_support_selected: [],
        is_sim_support_selected: [],
        is_counter_selected: [],

        dialogStaff: false,
        editedStaff: {},
        defaultStaff: {phone: '+38 ('},
        editedStaffIndex: -1,

        headers: [
            { text: 'id', value: 'id' },

            { text: 'first name', value: 'first_name' },
            { text: 'middle name', value: 'middle_name' },
            { text: 'last name', value: 'last_name' },

            { text: 'phone', value: 'phone' },
            { text: 'email', value: 'email' },
            { text: 'workload', value: 'contracts_len' },

            { text: 'admin', value: 'is_admin' },
            { text: 'manager', value: 'is_manager' },
            { text: 'counter', value: 'is_counter' },
            { text: 'tech_s', value: 'is_support' },
            { text: 'sims_s', value: 'is_sim_support' },

            { text: 'edit', value: 'action', sortable: false },

        ],

        rules: {
          required: value => !!value || 'required!',
          number: value => value.length >= 19 || 'short number!',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'invalid email!'
          },
        },

      }
    },

    created() {
      this.getData();

    },

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'core_api/staff/',

          params: {
            is_admin: this.is_admin_selected,
            is_manager: this.is_manager_selected,
            is_support: this.is_support_selected,
            is_sim_support: this.is_sim_support_selected,
            is_counter: this.is_counter_selected,

          },
          data: {},
        })
        .then(function (response) {
          self.staff = response.data.staff;

          self.is_admin_values = response.data.filters.is_admin;
          self.is_manager_values = response.data.filters.is_manager;
          self.is_support_values = response.data.filters.is_support;
          self.is_sim_support_values = response.data.filters.is_sim_support;
          self.is_counter_values = response.data.filters.is_counter;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

//      getColor (is_owner) {
//        if (is_owner) return 'red'
//        else return 'green'
//      },

      newStaff() {
        Vue.set(this, 'editedStaff', Object.assign({}, this.defaultStaff));
        this.dialogStaff = true;
      },

      editStaff(item) {
        this.editedStaffIndex = this.staff.indexOf(item);
        this.editedStaff = Object.assign({}, item);
        console.log('item:', item);
        this.dialogStaff = true;
      },

      saveStaff(item) {
        const self = this;

        self.dialogStaff = false;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'core_api/staff/',

          params: {
            is_admin: this.is_admin_selected,
            is_manager: this.is_manager_selected,
            is_support: this.is_support_selected,
            is_sim_support: this.is_sim_support_selected,
            is_counter: this.is_counter_selected,

          },
          data: {
            item: item,

            action: item.id ? 'edit' : 'create',
            method: 'action_staff',

          },
        })
            .then(function (response) {
              self.staff = response.data.staff;

              self.overlay = false;
            })
            .catch(function (error) {
              console.log(error);
            })
      },

      close() {
        this.dialogStaff = false;
        this.editedStaffIndex = -1;
      },

    },
    computed: {
      formStaffTitle() {
        return this.editedStaffIndex === -1 ? 'New Person' : 'Edit Person'
      },

      dialogRules() {
        return this.editedStaff.first_name && this.editedStaff.last_name &&
            this.editedStaff.phone && this.editedStaff.email
      },
    },

    watch: {
      is_admin_selected: function() {
        this.getData();
      },
      is_manager_selected: function() {
        this.getData();
      },
      is_support_selected: function() {
        this.getData();
      },
      is_sim_support_selected: function() {
        this.getData();
      },
      is_counter_selected: function() {
        this.getData();
      },

    },

  }
</script>

<style scoped>

</style>
