<template>
  <div class="ticket_state">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogComment" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Add Comment</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>
              <v-layout row wrap>

                <v-flex xs12>
                  <v-textarea
                    v-model="editedComment.text"
                    label="Comment text"
                    counter
                    :rules="rules"
                    required
                  ></v-textarea>
                </v-flex>

              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">cancel</v-btn>
          <v-btn
              text
              @click="saveComment()"
              :disabled="editedComment.text.length < 13"
              color="green darken-3"
          >save</v-btn>
        </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-dialog v-model="dialogSimsConfirmation" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Additional reSIM(s) & Confirmarion</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>
              <div>
                <v-layout row wrap align-center class="mt-n5"
                  v-for="(elem, index) in ticketObjsUnique"
                  :key="`elem-${index}`"
                >
<!--                  <v-flex xs1>{{index+1}}.</v-flex>-->

                  <v-flex xs1><v-checkbox v-model="elem.selected"></v-checkbox></v-flex>

                  <v-flex xs3>{{ elem.name }}</v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs3 class="pr-2">
                    <v-text-field
                      v-model="elem.phone_1"
                      label="phone 1"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 class="pr-2">
                    <v-text-field
                      v-model="elem.phone_2"
                      label="phone 2"
                      hide-details="auto"
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>

<!--                  <v-flex xs1>-->
<!--                    <v-tooltip top>-->
<!--                      <template v-slot:activator="{ on, attrs }">-->
<!--                        <div v-bind="attrs" v-on="on">-->
<!--                          <v-icon @click="removeObj(index, ticketObjsUnique)">mdi-cart-remove</v-icon>-->
<!--                        </div>-->
<!--                      </template>-->
<!--                      <span>{{index+1}}. {{ elem.name }}</span>-->
<!--                    </v-tooltip>-->
<!--                  </v-flex>-->

                </v-layout>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
<!--            <v-btn v-if="ticketObjsUnique.length" text @click="ticketObjsUnique=[]" color="orange darken-3">clear</v-btn>-->
            <v-btn text @click="confirmationSims" color="green darken-3">confirm</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogTechChecking" max-width="900px">
      <v-container>

        <v-card-title class="primary white--text">
          <span>Tech Check</span>
        </v-card-title>
        <v-card>

        <v-card-text>
          <v-container>

            <v-layout row wrap
                v-for="(elem, index) in check_objects"
                :key="`elem-${index}`"
              >

              <v-flex xs1>{{ elem.id }}</v-flex>

              <v-flex xs4>{{ elem.name }}</v-flex>
              <v-flex xs2>{{ elem.imei }}</v-flex>

              <v-flex xs2>{{ elem.phone_1 }}</v-flex>
              <v-flex xs2>{{ elem.phone_2 }}</v-flex>

              <v-flex xs1>{{ elem.success }}</v-flex>

            </v-layout>

            <v-divider class="ma-5"></v-divider>

            <v-layout row>
              <v-flex xs11>All conditions are fulfilled correctly</v-flex>
              <v-flex xs1>
                <v-icon v-if="check_tech_success">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </v-flex>
            </v-layout>

          </v-container>
        </v-card-text>

      </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

      <!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-menu
            v-model="menuPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="picker"
                label="Month"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              @input="menuPicker = false"
              type="month"
              min="2023-02"
              :max=current_month
            ></v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="complete_filter_selected"
            :items="complete_filters"
            item-text="name"
            item-value="id"
            label="Complete"
            clearable
            prepend-icon="mdi-check-all"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_filter_selected"
            :items="contract_filters"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="tickets"

        show-expand
        single-expand
        :expanded.sync="expanded"

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalTickets"
        :footer-props="{'items-per-page-options': [10, 25, 100]}"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.pdf="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  icon
                  small

                  class="ma-2"
                  :href="downloadPDF(item)"
                  target="_blank"
                >
                  <v-icon dark>
                    mdi-file-eye
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ item.file_path }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.comment="{ item }">
          <v-badge
            :content="item.comments_count"
            :value="item.comments_count"

            color="primary"
            bordered
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    icon
                    small
                    class="mb-0"
                    @click="addComment(item)"

                    :disabled="
                      (user_role.is_support && !item.sim_confirm) ||
                      ((user_role.is_sim_support || user_role.is_counter) && !item.manager_confirm)
                    "
                  >
                    <v-icon dark>
                      mdi-forum-plus-outline
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Add Comment in #{{ item.id }}</span>
            </v-tooltip>
          </v-badge>
        </template>

        <template v-slot:item.manager_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox v-if="item.manager_confirm === false && !item.complete &&
                                  (item.manager_comments_count > 0 || item.admin_comments_count > 0) &&
                                  (user_role.is_admin || user_role.is_manager)"
                            v-model="item.manager_confirm"
                            @click="confirmation(item, 'manager')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.manager_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.manager_name }} {{ item.manager_date }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.sim_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox v-if="item.manager_confirm === true &&
                                  item.sim_confirm === false &&
                                  (user_role.is_admin || user_role.is_sim_support || user_role.is_counter)"
                            v-model="item.sim_confirm"
                            @click="additionalSims(item)"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.sim_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.sim_name }} {{ item.sim_date }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.tech_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox v-if="item.manager_confirm === true &&
                                  item.sim_confirm === true &&
                                  item.tech_confirm === false &&
                                  (user_role.is_admin || user_role.is_support)"
                            v-model="item.tech_confirm"
                            @click="confirmation(item, 'tech')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.tech_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.tech_name }} {{ item.tech_date }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.complete="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox v-if="item.complete === false && item.manager_confirm === false &&
                                  (user_role.is_admin || user_role.is_manager) &&
                                  (item.manager_comments_count > 0 || item.admin_comments_count > 0)"
                            v-model="item.complete"
                            @click="confirmation(item, 'cancel')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.complete"></v-checkbox>
              </div>
            </template>
            <span>{{ item.complete_name }} {{ item.complete_date }}</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>
            <v-container>
              <v-card>
                <v-card-title>
                  <v-tabs v-model='tab' right height="35">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab v-for="t in tabs" :key="t.id">
                      <v-icon left>{{ t.icon }}</v-icon>
                      {{ t.name }}
                    </v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text>
                  <v-tabs-items v-model="tab">
                    <v-tab-item key="0">
                      <v-card>
                        <v-card-title>
                          <v-icon color="primary">mdi-car-outline</v-icon>
                          <v-divider class="mx-4" inset vertical></v-divider>
                        </v-card-title>
                        <v-divider></v-divider>

                          <v-card v-if="item.details.pause.length > 0">
                            <v-card-title>
                              Pause
                            </v-card-title>
                            <v-card-text>
                              <v-data-table
                                :headers="headersTicket"
                                :items="item.details.pause"
                                :colspan="headers.length"

                                :items-per-page="-1"
                                :hide-default-footer="true"
                              >

                                <template v-slot:item.name="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.name }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.imei="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.imei }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.imei }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_1="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_1 }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.phone_1 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_2="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_2 }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.phone_2 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.last="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.last }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.last }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.done="{ item }">
                                  <v-icon v-if="item.now.paused">mdi-check</v-icon>
                                  <v-icon v-else>mdi-window-close</v-icon>
                                </template>

                              </v-data-table>
                            </v-card-text>
                          </v-card>
                          <v-card v-if="item.details.active.length > 0">
                            <v-card-title>
                              Activate
                            </v-card-title>
                            <v-card-text>
                              <v-data-table
                                :headers="headersTicket"
                                :items="item.details.active"
                                :colspan="headers.length"

                                :items-per-page="-1"
                                :hide-default-footer="true"
                              >

                                <template v-slot:item.name="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.name }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.imei="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.imei }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.imei }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_1="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_1 }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.phone_1 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_2="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_2 }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.phone_2 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.last="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.last }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.last }}</span>
                                  </v-tooltip>
                                </template>

                                <template v-slot:item.done="{ item }">
                                  <v-icon v-if="!item.now.paused">mdi-check</v-icon>
                                  <v-icon v-else>mdi-window-close</v-icon>
                                </template>

                              </v-data-table>
                            </v-card-text>
                          </v-card>

                          <v-card v-if="item.details.external.length > 0">
                            <v-card-title>
                              Activate Roaming
                            </v-card-title>
                            <v-card-text>
                              <v-data-table
                                :headers="headersTicket"
                                :items="item.details.external"
                                :colspan="headers.length"

                                :items-per-page="-1"
                                :hide-default-footer="true"
                              >

                                <template v-slot:item.name="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.name }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.imei="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.imei }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.imei }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_1="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_1 }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.phone_1 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_2="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_2 }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.phone_2 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.last="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.last }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.last }}</span>
                                  </v-tooltip>
                                </template>

                                <template v-slot:item.done="{ item }">
                                  <v-icon v-if="item.now.sim">mdi-check</v-icon>
                                  <v-icon v-else>mdi-window-close</v-icon>
                                </template>

                              </v-data-table>
                            </v-card-text>
                          </v-card>
                          <v-card v-if="item.details.internal.length > 0">
                            <v-card-title>
                              Deactivate Roaming
                            </v-card-title>
                            <v-card-text>
                              <v-data-table
                                :headers="headersTicket"
                                :items="item.details.internal"
                                :colspan="headers.length"

                                :items-per-page="-1"
                                :hide-default-footer="true"
                              >

                                <template v-slot:item.name="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.name }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.imei="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.imei }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.imei }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_1="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_1 }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.phone_1 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_2="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_2 }}</span>
                                      </div>
                                    </template>
                                    <span>{{ item.now.phone_2 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.last="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.last }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.last }}</span>
                                  </v-tooltip>
                                </template>

                                <template v-slot:item.done="{ item }">
                                  <v-icon v-if="!item.now.sim">mdi-check</v-icon>
                                  <v-icon v-else>mdi-window-close</v-icon>
                                </template>

                              </v-data-table>
                            </v-card-text>
                          </v-card>

                          <v-card v-if="item.details.rename.length > 0">
                            <v-card-title>
                              Change Name
                            </v-card-title>
                            <v-card-text>
                              <v-data-table
                                :headers="headersTicket"
                                :items="item.details.rename"
                                :colspan="headers.length"

                                :items-per-page="-1"
                                :hide-default-footer="true"
                              >

                                <template v-slot:item.name="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.name }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.imei="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.imei }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.imei }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_1="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_1 }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.phone_1 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_2="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_2 }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.phone_2 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.last="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.last }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.last }}</span>
                                  </v-tooltip>
                                </template>

                                <template v-slot:item.done="{ item }">
                                  <v-icon v-if="item.name === item.now.name">mdi-check</v-icon>
                                  <v-icon v-else>mdi-window-close</v-icon>
                                </template>

                              </v-data-table>
                            </v-card-text>
                          </v-card>
                          <v-card v-if="item.details.resims.length > 0">
                            <v-card-title>
                              Change SIMs
                            </v-card-title>
                            <v-card-text>
                              <v-data-table
                                :headers="headersTicket"
                                :items="item.details.resims"
                                :colspan="headers.length"

                                :items-per-page="-1"
                                :hide-default-footer="true"
                              >

                                <template v-slot:item.name="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.name }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.imei="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.imei }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.imei }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_1="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_1 }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.phone_1 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_2="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_2 }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.phone_2 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.last="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.last }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.last }}</span>
                                  </v-tooltip>
                                </template>

                                <template v-slot:item.done="{ item }">
                                  <v-icon v-if="item.phone_1 === item.now.phone_1 && item.phone_2 === item.now.phone_2">mdi-check</v-icon>
                                  <v-icon v-else>mdi-window-close</v-icon>
                                </template>

                              </v-data-table>
                            </v-card-text>
                          </v-card>

                          <v-card v-if="item.details.delete.length > 0">
                            <v-card-title>
                              Delete
                            </v-card-title>
                            <v-card-text>
                              <v-data-table
                                :headers="headersTicket"
                                :items="item.details.delete"
                                :colspan="headers.length"

                                :items-per-page="-1"
                                :hide-default-footer="true"
                              >

                                <template v-slot:item.name="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.name }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.imei="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.imei }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.imei }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_1="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_1 }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.phone_1 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_2="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_2 }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.phone_2 }}</span>
                                  </v-tooltip>
                                </template>

                                <template v-slot:item.done="{ item }">
                                  <v-icon v-if="!item.now.id">mdi-check</v-icon>
                                  <v-icon v-else>mdi-window-close</v-icon>
                                </template>

                                <template v-slot:item.last="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.last }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.last }}</span>
                                  </v-tooltip>
                                </template>

                              </v-data-table>
                            </v-card-text>
                          </v-card>
                          <v-card v-if="item.details.create && item.details.create.length > 0">
                            <v-card-title>
                              Create
                            </v-card-title>
                            <v-card-text>
                              <v-data-table
                                :headers="headersTicket"
                                :items="item.details.create"
                                :colspan="headers.length"

                                :items-per-page="-1"
                                :hide-default-footer="true"
                              >

                                <template v-slot:item.name="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.name }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.name }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.imei="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.imei }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.imei }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_1="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_1 }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.phone_1 }}</span>
                                  </v-tooltip>
                                </template>
                                <template v-slot:item.phone_2="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.phone_2 }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.phone_2 }}</span>
                                  </v-tooltip>
                                </template>

<!--                                <v-icon v-if="!item.now.id.startsWith('*')">mdi-check</v-icon>-->
                                <template v-slot:item.done="{ item }">
                                  <v-icon v-if="item.now.id">mdi-check</v-icon>
                                  <v-icon v-else>mdi-window-close</v-icon>
                                </template>

                                <template v-slot:item.last="{ item }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <span>{{ item.last }}</span>
                                      </div>
                                    </template>
                                      <span>{{ item.now.last }}</span>
                                  </v-tooltip>
                                </template>

                              </v-data-table>
                            </v-card-text>
                          </v-card>

                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="1">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" inset vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headersComments"
                        :items="item.comments"
                        :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                        :items-per-page="-1"
                      ></v-data-table>

                    </v-tab-item>
                    <v-tab-item key="2">
                      <v-card-title>
                        <v-icon color="primary">mdi-compare-remove</v-icon>
                        <v-divider class="mx-4" inset vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headersHistory"
                        :items="item.history"
                        :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                        :items-per-page="-1"
                      ></v-data-table>

                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-container>
          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>

export default {
  name: 'Tickets',

  data() {
    return {
      overlay: false,
      load: false,

      tickets: [],

      contract_filters: [],
      contract_filter_selected: null,

      manager_filters: [],
      manager_filter_selected: null,
      tech_filters: [],
      tech_filter_selected: null,
      sim_filters: [],
      sim_filter_selected: null,
      complete_filters: [],
      complete_filter_selected: 0,

      picker: null,
      menuPicker: false,

      expanded: [],
      details: {pause: [], active: [], delete: [], create: [], external: [], internal: [], rename: [], resims: [], },

      check_objects: [],
      check_tech_success: false,

      check_sims: [],
      check_sim_success: false,

      user_role: {is_admin: false, is_manager: false, is_counter: false, is_support: false, is_customer: false},

      dialogComment: false,
      editedComment: {text: ''},
      defaultComment: {text: ''},

      dialogTechChecking: false,
      dialogSimCheck: false,
      dialogSimsConfirmation: false,

      currTicket: null,
      ticketObjsUnique: [],

      options: {
          'page': 1,
          'itemsPerPage': 10,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
      },
      sortBy: '-id',
      sortDesc: false,
      totalTickets: 0,

      rules: [v => 13 <= v.length || 'Min 13 characters'],
      current_month: new Date().toISOString().substr(0, 7),

      tab: null,
      tabs: [
        { id: 1, name: 'Objects', icon: 'mdi-car-outline' },
        { id: 2, name: 'Comments', icon: 'mdi-forum-outline'},
        { id: 3, name: 'History', icon: 'mdi-compare-remove' },
      ],

      headers: [
        { text: 'id', value: 'id', sortable: false },
        { text: 'datetime', value: 'date_create', sortable: false },

        { text: 'contract', value: 'contract_name', sortable: false },
        { text: 'source', value: 'source_info', sortable: false },

        { text: 'reason', value: 'pdf', sortable: false },
        { text: 'comment', value: 'comment', sortable: false },

        { text: 'mngr', value: 'manager_confirm', sortable: false },
        { text: 'sim', value: 'sim_confirm', sortable: false },
        { text: 'tech', value: 'tech_confirm', sortable: false },

        { text: 'complete', value: 'complete', sortable: false },

        { text: 'objs', value: 'data-table-expand', sortable: false },

      ],

      headersTicket: [
        { text: 'id', value: 'id', width: '5%' },

        { text: 'name', value: 'name', width: '30%' },
        { text: 'imei', value: 'imei', width: '15%' },

        { text: 'phone 1', value: 'phone_1', width: '15%' },
        { text: 'phone 2', value: 'phone_2', width: '15%' },

        { text: 'last data', value: 'last', width: '15%' },

        { text: 'done', value: 'done', width: '5%'},

      ],
      headersComments: [
        { text: 'id', value: 'id' },
        { text: 'datetime', value: 'date' },
        { text: 'creator', value: 'user' },
        { text: 'text', value: 'text', width: '50%' },

      ],
      headersHistory: [
        { text: 'id', value: 'id' },
        { text: 'datetime', value: 'date' },
        { text: 'user', value: 'user' },
        { text: 'stage', value: 'role' },
        { text: 'action', value: 'action' },
      ],

    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_stage/',

        params: {
          date: this.picker,
          contract: this.contract_filter_selected,
          complete: this.complete_filter_selected,

          pagination: this.options,

        },
        data: {},
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.contract_filters = response.data.contract_filters;
        self.complete_filters = response.data.complete_filters;

        self.user_role = response.data.user_role;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    confirmation(data, role) {
      let self = this;
      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_stage/',

        params: {
          date: this.picker,
          complete: this.complete_filter_selected,
          contract: this.contract_filter_selected,

          pagination: this.options,

        },
        data: {
          ticket_id: data.id,

          role: role,
          stage: role,

          action: 'ticket confirm',
          method: 'confirm_ticket',
        },
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.check_objects = response.data.check_objects;
        self.check_tech_success = response.data.check_tech_success;

        self.check_sims = response.data.check_sims;
        self.check_sim_success = response.data.check_sim_success;

        self.contract_filters = response.data.contract_filters;
        self.complete_filters = response.data.complete_filters;

        self.user_role = response.data.user_role;

        if (self.check_objects.length > 0) {
          self.dialogTechChecking = true
        }

        self.load = false;
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })

    },

    additionalSims(item) {
      this.currTicket = item.id;
      this.ticketObjsUnique = this.getUniqueObjs(item);

      this.dialogSimsConfirmation = true;
    },

    confirmationSims() {
      let self = this;

      self.load = true;
      self.overlay = true;
      self.dialogSimsConfirmation = false;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_stage/',

        params: {
          date: this.picker,
          complete: this.complete_filter_selected,
          contract: this.contract_filter_selected,

          pagination: this.options,

        },
        data: {
          ticket_id: this.currTicket,
          additional: this.ticketObjsUnique.filter(v => v.selected),

          role: 'sim',
          stage: 'sim',

          action: 'ticket confirm',
          method: 'confirm_ticket',
        },
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.check_objects = response.data.check_objects;
        self.check_tech_success = response.data.check_tech_success;

        self.check_sims = response.data.check_sims;
        self.check_sim_success = response.data.check_sim_success;

        self.contract_filters = response.data.contract_filters;
        self.complete_filters = response.data.complete_filters;

        self.user_role = response.data.user_role;

        if (self.check_objects.length > 0) {
          self.dialogTechChecking = true
        }

        self.load = false;
        self.overlay = false;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    addComment(item) {
      console.log('addComment item:', item);

      let self = this;
      self.load = true;

      console.log('role:', self.user_role);

      self.editedComment.text = '';
      self.editedComment.ticket_id = item.id;

      self.dialogComment = true;
    },

    saveComment() {
      let self = this;

      self.load = true;
      self.overlay = true;
      self.dialogComment = false;

      console.log('editedComment', this.editedComment)

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_stage/',

        params: {
          date: this.picker,
          complete: this.complete_filter_selected,
          contract: this.contract_filter_selected,

          pagination: this.options,

        },
        data: {
          text: this.editedComment.text,
          ticket_id: this.editedComment.ticket_id,

          action: 'comment add',
          method: 'add_comment',
        },
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.contract_filters = response.data.contract_filters;
        self.complete_filters = response.data.complete_filters;

        self.user_role = response.data.user_role;

        self.load = false;
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    downloadPDF(item) {
      return 'core_api/get_pdf_file/'+item.file_path
    },

    close() {
      this.dialogComment = false;
      this.dialogSimsConfirmation = false;

      this.getData();
    },

    removeObj(index, objAction) {
      objAction.splice(index, 1);
    },

    getUniqueObjs(item) {
      //  ?? [] - backwards compatible with old tickets
      let pause = item.details.pause ?? [];
      let active = item.details.active ?? [];
      let external = item.details.external ?? [];
      let internal = item.details.internal ?? [];
      let rename = item.details.rename ?? [];
      let resims = item.details.resims ?? [];
      let dels = item.details.delete ?? [];
//      let create = item.details.create ?? [];

      let cartArray = [];
      cartArray.push(...rename, ...resims, ...dels, ...pause, ...active, ...external, ...internal );

      return [...new Map(cartArray.map(i => [i['id'], i])).values()]
    },

  },

  computed: {},

  watch: {
    picker: function() {
      this.getData();
    },
    complete_filter_selected: function() {
      this.getData();
    },
    contract_filter_selected: function() {
      this.getData();
    },

    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

  },

}
</script>

<style scoped>

</style>
