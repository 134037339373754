var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"servers"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialogReport),callback:function ($$v) {_vm.dialogReport=$$v},expression:"dialogReport"}},[_c('v-container',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',[_vm._v("Monthly Servers Report")])]),_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-date-picker',{attrs:{"type":"month","min":"2021-10","max":_vm.current_month,"multiple":""},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}})],1),_c('v-col',{attrs:{"align":"center"}},[_c('v-flex',{staticClass:"mb-5 font-weight-bold"},[_vm._v("Selected Months")]),_vm._l((_vm.picker_sorted),function(elem,index){return _c('v-layout',{key:("" + index),attrs:{"row":"","wrap":""}},[(index === 0)?_c('v-flex',{staticStyle:{"color":"dimgray"}},[_vm._v(_vm._s(elem))]):_c('v-flex',{staticStyle:{"font-size":"medium"}},[_vm._v(_vm._s(elem))])],1)})],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("cancel")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.picker.length < 2,"color":"green darken-3"},on:{"click":function($event){return _vm.downloadMonthly()}}},[_vm._v("download")])],1)],1)],1)],1),_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v(_vm._s(_vm.$route.meta.icon))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-2"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.source_selected !== null},on:{"click":function($event){return _vm.newMonthlyReport()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-table-arrow-down")])],1)],1)]}}])},[_c('span',[_vm._v("Monthly Report")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-2"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.source_selected !== null},on:{"click":function($event){return _vm.downloadDuplicates()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-content-duplicate")])],1)],1)]}}])},[_c('span',[_vm._v("Duplicates Report")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-2"},'div',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.source_selected === null},on:{"click":function($event){return _vm.refreshTotalSlices()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-refresh")])],1)],1)]}}])},[_c('span',[_vm._v("Refresh Source")])]),_c('v-flex',{staticClass:"d-flex pl-1"},[_c('v-card',{staticClass:"d-flex justify-space-between pa-2",attrs:{"outlined":"","rounded":"","min-width":"155"}},[_c('v-card',{staticClass:"text-caption my-n5",staticStyle:{"position":"absolute","color":"darkgray"},attrs:{"flat":""}},[_c('pre',[_vm._v(" Object Reports ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-1"},'div',attrs,false),on),[_c('v-btn',{attrs:{"disabled":_vm.billing_btn_rules,"color":"primary"},on:{"click":function($event){return _vm.downloadReport('Billing')}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cart-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Billing Objects")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-1"},'div',attrs,false),on),[_c('v-btn',{attrs:{"disabled":_vm.inactive_btn_rules,"color":"primary"},on:{"click":function($event){return _vm.downloadReport('Inactive')}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cart-minus")])],1)],1)]}}])},[_c('span',[_vm._v("Inactive Objects")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"px-1"},'div',attrs,false),on),[_c('v-btn',{attrs:{"disabled":_vm.wild_btn_rules,"color":"primary"},on:{"click":function($event){return _vm.downloadReport('Wild')}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cart-remove")])],1)],1)]}}])},[_c('span',[_vm._v("Wild Objects")])])],1)],1),_c('v-spacer'),_c('v-flex',{attrs:{"xs2":"","lg2":"","xl2":""}},[_c('v-autocomplete',{attrs:{"items":_vm.sources,"item-text":"name","item-value":"id","label":"Source","clearable":"","prepend-icon":"mdi-server-network"},model:{value:(_vm.source_selected),callback:function ($$v) {_vm.source_selected=$$v},expression:"source_selected"}})],1)],1),_c('v-divider'),_c('v-card-text',[_c('div',{ref:"div_sunburst",staticClass:"dashboardSunburstChart"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }