import Vue from 'vue'
import Vuetify from 'vuetify/lib'

//import Vuetify from 'vuetify/lib/framework';
//import VueI18n from 'vue-i18n'

import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify)
//Vue.use(VueI18n)

//const messages = {
//  en: {
//    $vuetify: {
//      dataIterator: {
//        rowsPerPageText: 'Items per page:',
//        pageText: '{0}-{1} of {2}',
//      },
//    },
//  },
//  ua: {
//    $vuetify: {
//      dataIterator: {
//        rowsPerPageText: 'Element per sida:',
//        pageText: '{0}-{1} av {2}',
//      },
//    },
//  },
//}

//const i18n = new VueI18n({
//  locale: 'en', // set locale
//  messages, // set locale messages
//})


export default new Vuetify({
    icons: {
        iconfont: 'mdi', // 'mdiSvg' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },

    theme: {
        themes: {
            light: {
                primary: colors.blueGrey.darken1,
//                secondary: colors.
                accent: colors.blueGrey.darken1,
//                error: colors.
//                info: colors.
//                success: colors.
//                warning: colors.
//                anchor: colors.
            },
            dark: {
                primary: colors.blueGrey.darken1,
                accent: colors.blueGrey.darken1,

            }
        },
    },

//    lang: {
//        t: (key, ...params) => i18n.t(key, params),
//    },

});
