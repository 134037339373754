<template>
  <div class="sims">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogGroupsManage" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Groups Management</span>
        </v-card-title>
        <v-card>
          <v-card-title>

            <v-icon color="primary">mdi-group</v-icon>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="ma-2">
                  <v-btn
                      :disabled="groups_selected.length !== 0"
                      @click="dialogGroupsName = true"
                      color="primary"
                      depressed
                  >
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Add group</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="ma-2">
                  <v-btn
                      :disabled="groups_selected.length !== 1"
                      @click="dialogGroupsName = true"
                      color="primary"
                      depressed
                  >
                    <v-icon color="white">mdi-pencil-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Edit group</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="ma-2">
                  <v-btn
                      :disabled="groups_selected.length === 0"
                      @click="groupEdit('', 'erase_groups')"
                      color="primary"
                      depressed
                  >
                    <v-icon color="white">mdi-eraser</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Erase group(s)</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="ma-2">
                  <v-btn
                      :disabled="groups_selected.length === 0"
                      @click="groupEdit('', 'delete_groups')"
                      color="primary"
                      depressed
                  >
                    <v-icon color="white">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Delete group(s)</span>
            </v-tooltip>

          </v-card-title>

          <v-card-text>
            <v-container>
              <v-data-table
                v-model="groups_selected"
                :headers="headersGroup"
                :items="groups"

                show-select

                :items-per-page="-1"
                :hide-default-footer="true"

                :loading="load"
                loading-text="Groups loading... Please wait"

                class="elevation-1"
              >

                <template slot="progress">
                  <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
                </template>

              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">close</v-btn>
<!--            <v-btn text color="green darken-3">confirm</v-btn>-->

          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogGroupsName" max-width="500px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>{{ editedGroupTitle }}</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-text-field v-model="editedGroup.name" label="group name"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeGroupName()">close</v-btn>
            <v-btn text @click="groupEdit(editedGroup.name, 'group_edit')" color="green darken-3">confirm</v-btn>

          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-dialog v-model="dialogSimsManage" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Sims Management</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container class="d-flex flex-row">
              <v-flex xs9 lg9 xl9>
                <v-autocomplete
                  v-model="sim_group_selected"
                  :items="groups"
                  item-text="name"
                  item-value="id"
                  label="group"
                  clearable
                  prepend-icon="mdi-ungroup"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs1 lg1 xl1>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="ma-2">
                      <v-btn
                          @click="simsEdit('add_to')"
                          :disabled="!sim_group_selected"
                          color="primary"
                          depressed
                      >
                        <v-icon color="white">mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Add to group</span>
              </v-tooltip>
              </v-flex>
              <v-flex xs1 lg1 xl1>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="ma-2">
                      <v-btn
                          @click="simsEdit('remove_from')"
                          :disabled="!sim_group_selected"
                          color="primary"
                          depressed
                      >
                        <v-icon color="white">mdi-minus</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Remove from group</span>
                </v-tooltip>
              </v-flex>
              <v-flex xs1 lg1 xl1>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="ma-2">
                      <v-btn
                          @click="simsEdit('remove_from_all')"
                          :disabled="sim_group_selected !== null"
                          color="primary"
                          depressed
                      >
                        <v-icon color="white">mdi-eraser</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <span>Remove from all groups</span>
                </v-tooltip>
              </v-flex>
            </v-container>
            <v-container class="d-flex flex-row">
              <v-flex xs12 lg12 xl12>
                <span>{{ number_selected.length }} numbers: {{ numbersSelected }}</span>
              </v-flex>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">close</v-btn>
<!--            <v-btn text color="green darken-3">confirm</v-btn>-->

          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-card flat class="d-flex flex-row">
          <v-card outlined rounded min-width="310" class="d-flex justify-space-between pa-2">
            <v-card
              flat
              style="position: absolute; color: darkgray"
              class="text-caption my-n5"
            >
              <pre> Refresh </pre>
            </v-card>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      :disabled="carrier_selected === null"
                      @click="updateBaseDatas()"
                      color="primary"
                      depressed
                    >
                      <v-icon color="white">mdi-cellphone</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>SIMs Data</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
<!--                    :disabled="number_selected.length > 0"-->
                    <v-btn
                      @click="updateObjects()"
                      color="primary"
                      depressed
                    >
                      <v-icon color="white">mdi-car-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Objects Data</span>
              </v-tooltip>
              <v-divider  vertical></v-divider>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      :disabled="number_selected.length < 1"
                      @click="updatePayments()"
                      color="primary"
                      depressed
                    >
                      <v-icon color="white">mdi-hand-coin-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Fees</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      :disabled="number_selected.length < 1"
                      @click="updateRoaming()"
                      color="primary"
                      depressed
                    >
                      <v-icon color="white">mdi-map-marker-radius-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Roaming</span>
              </v-tooltip>

          </v-card>
          <v-card outlined rounded min-width="155" class="d-flex justify-space-between pa-2 ml-2">
            <v-card
              flat
              style="position: absolute; color: darkgray"
              class="text-caption my-n5"
            >
              <pre> Groups </pre>
            </v-card>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      @click="dialogGroupsManage=true"
                      color="primary"
                      depressed
                    >
                      <v-icon color="white">mdi-group</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Groups management</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      :disabled="number_selected.length < 1"
                      @click="dialogSimsManage=true"
                      color="primary"
                      depressed
                    >
                      <v-icon color="white">mdi-ungroup</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>SIMs management</span>
              </v-tooltip>
          </v-card>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="ma-2">
<!--                :disabled="number_selected.length > 0"-->
                <v-btn
                    @click="downloadReport()"
                    color="primary"
                    depressed
                >
                  <v-icon color="white">mdi-table-arrow-down</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Report Download</span>
          </v-tooltip>


        </v-card>
        <v-spacer></v-spacer>

        <!--counter, filter hide-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">

              <v-badge
                :content="number_selected.length"
                :value="number_selected.length"
                bordered
              >
                <v-icon @click="number_selected=[]" color="primary">mdi-cellphone-screenshot</v-icon>
              </v-badge>
            </div>
          </template>
          <span>SIMs deselect ALL</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                  @click="show_filters = !show_filters"
                  color="primary"
                  depressed
                  text
              >
                <v-icon v-if="!show_filters">mdi-filter-outline</v-icon>
                <v-icon v-else>mdi-filter-off-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Filters</span>
        </v-tooltip>

<!--Filters-->
        <v-card v-if="show_filters" flat class="d-flex flex-row-reverse">
          <v-flex xs1 lg1 xl1>
            <v-autocomplete
              v-model="selected_status_selected"
              :items="selected_status_value"
              item-text="name"
              item-value="value"
              label="Select"
              clearable
              prepend-icon="mdi-cellphone-screenshot"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs2 lg2 xl2>
            <v-autocomplete
              v-model="groups_value_selected"
              :items="groups_values"
              item-text="name"
              item-value="id"
              label="Group"
              clearable
              prepend-icon="mdi-ungroup"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs3 lg3 xl3>
            <v-autocomplete
              v-model="contracts_selected"
              :items="contracts"
              item-text="name"
              item-value="name"
              label="Contract by SIMs"
              hide-selected
              clearable
              prepend-icon="mdi-text-box-outline"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs1 lg1 xl1>
            <v-autocomplete
              v-model="roam_selected"
              :items="roam_values"
              item-text="name"
              item-value="value"
              label="Roaming"
              clearable
              prepend-icon="mdi-cellphone-marker"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs1 lg1 xl1>
            <v-autocomplete
              v-model="status_selected"
              :items="status_values"
              item-text="name"
              item-value="value"
              label="Status"
              clearable
              prepend-icon="mdi-cellphone-check"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs3 lg3 xl3>
            <v-autocomplete
              v-model="phone_selected"
              :items="phones"
              item-text="name"
              item-value="value"
              label="Number (icc)"
              clearable
              prepend-icon="mdi-cellphone-text"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs1 lg1 xl1>
            <v-autocomplete
              v-model="carrier_selected"
              :items="carriers"
              item-text="name"
              item-value="id"
              label="Carrier"
              clearable
              prepend-icon="mdi-cellphone"
            ></v-autocomplete>
          </v-flex>
        </v-card>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="number_selected"
        :headers="headers"
        :items="numbers"

        :options.sync="options"
        :server-items-length="totalNumbers"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        show-select

        :expanded.sync="expanded"
        show-expand
        single-expand

        :loading="load"
        loading-text="Numbers loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.carrier="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.carrier === 'Kyivstar'" color="blue accent-2">mdi-cellphone</v-icon>
                <v-icon v-else color="red accent-2" dark>mdi-cellphone</v-icon>
              </div>
            </template>
            <span>{{ item.carrier }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.number="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.number }}
              </div>
            </template>
            <span>{{ item.date_number_update }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.fees__fees="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.fees__fees }}
              </div>
            </template>
            <span>{{ item.date_overpay_update }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.is_roaming="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.is_roaming === true">mdi-check</v-icon>
                <v-icon v-else-if="item.is_roaming === false">mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.date_roaming_update }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.is_delete="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.is_delete">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.is_delete }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.objs__objs_count="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">

                <v-badge
                  v-if="item.objs__objs_count"
                  :content="item.objs__objs_count"
                  :value="item.objs__objs_count"
                  color="primary"
                  bordered
                >
                  <v-icon>mdi-car-outline</v-icon>
                </v-badge>
                <v-icon v-else disabled>mdi-car-outline</v-icon>

              </div>
            </template>
            <span>{{ item.date_objects_update }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.sims_group__count="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">

                <v-badge
                  v-if="item.sims_group__count"
                  :content="item.sims_group__count"
                  :value="item.sims_group__count"
                  color="primary"
                  bordered
                >
                  <v-icon>mdi-ungroup</v-icon>
                </v-badge>
                <v-icon v-else disabled>mdi-ungroup</v-icon>

              </div>
            </template>
            <span v-html="item.group_names"></span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>

            <v-container>
              <v-card>
                <v-card-title>
                  <v-icon color="primary">mdi-car-outline</v-icon>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-card-title>
                <v-divider></v-divider>

                <v-data-table
                  :headers="headersObjects"
                  :items="item.objects"

                  :hide-default-footer="true"
                >

                  <template v-slot:item.paused="{ item }">
<!--                    <v-tooltip top>-->
<!--                      <template v-slot:activator="{ on, attrs }">-->
<!--                        <div v-bind="attrs" v-on="on">-->
                          <v-icon v-if="item.paused">mdi-check</v-icon>
                          <v-icon v-else>mdi-window-close</v-icon>
<!--                        </div>-->
<!--                      </template>-->
<!--                      <span>{{ item.psd }}</span>-->
<!--                    </v-tooltip>-->
                  </template>
                  <template v-slot:item.sim="{ item }">
<!--                    <v-tooltip top>-->
<!--                      <template v-slot:activator="{ on, attrs }">-->
<!--                        <div v-bind="attrs" v-on="on">-->
                          <v-icon v-if="item.sim">mdi-check</v-icon>
                          <v-icon v-else>mdi-window-close</v-icon>
<!--                        </div>-->
<!--                      </template>-->
<!--                      <span>{{ item.sim }}</span>-->
<!--                    </v-tooltip>-->
                  </template>

                </v-data-table>

              </v-card>
            </v-container>

          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>

//import Vue from "vue";

export default {
  name: 'Sims',

  data() {
    return {
      overlay: false,
      load: false,

      numbers: [],
      number_selected: [],

      phones: [],
      phone_selected: [],

      accounts: [],
      account_selected: [],

      carriers: [],
      carrier_selected: null,

      status_values: [],
      status_selected: [],

      roam_values: [],
      roam_selected: [],

      del_values: [],
      del_selected: [],

      contracts: [],
      contracts_selected: [],

      groups_values: [],
      groups_value_selected: [],

      groups: [],
      groups_selected: [],

      sim_group_selected: null,

      selected_status_value: [],
      selected_status_selected: null,

      show_filters: true,

      dialogSimsManage: false,

      dialogGroupsManage: false,
      dialogGroupsName: false,

      editedGroup: {name: '', subaction: null},
//      defaultGroup: {name: '', subaction: null},
//      editedGroupIndex: -1,

      expanded: [],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },
      sortBy: 'id',
      sortDesc: false,
      totalNumbers: 0,

//      menu: false,
//      picker: new Date().toISOString().substr(0, 7),
//      current_month: new Date().toISOString().substr(0, 7),

      headers: [
//        { text: 'id', value: 'id' },

        { text: 'carrier', value: 'carrier' },
        { text: 'account', value: 'account' },
        { text: 'number', value: 'number' },

        { text: 'billing', value: 'billing' },
        { text: 'fees', value: 'fees__fees' },

//        { text: 'name', value: 'name' },
//        { text: 'comment', value: 'comment' },

        { text: 'status', value: 'status' },
        { text: 'roaming', value: 'is_roaming' },

//        { text: 'overpay', value: 'overpay', sortable: false },
//        { text: 'del', value: 'is_delete' },

        { text: 'group', value: 'sims_group__count', sortable: false },
        { text: 'count', value: 'objs__objs_count' },

        { text: 'objs', value: 'data-table-expand', sortable: false },

      ],
      headersObjects: [
//          { text: 'id', value: 'id' },
//          { text: 'source', value: 'source' },

          { text: 'id', value: 'id' },
          { text: 'source', value: 'source' },

          { text: 'contract', value: 'contract' },

          { text: 'name', value: 'name' },
          { text: 'imei', value: 'imei' },
          { text: 'phone 1', value: 'phone_1' },
          { text: 'phone 2', value: 'phone_2' },

          { text: 'paused', value: 'paused' },
          { text: 'roaming', value: 'sim' },

          { text: 'last datas', value: 'last' },
      ],
      headersGroup: [
        { text: 'id', value: 'id' },
        { text: 'name', value: 'name' },
        { text: 'count', value: 'sims_count' },
      ]

    }
  },

  created() {
      this.getData();
    },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'sim_api/sims/',

        params: {
          carrier: this.carrier_selected,
          account: this.account_selected,
          phone: this.phone_selected,

          status: this.status_selected,
          del: this.del_selected,
          roam: this.roam_selected,

          contract: this.contracts_selected,
          group: this.groups_value_selected,

          select: this.selected_status_selected,
          number_ids: Array.from(this.number_selected, x=>x.id),

          pagination: this.options,
          phones_need: this.phones.length <= 0,

        },
        data: {},
      })
      .then(function (response) {
        self.numbers = response.data.numbers_data;
        self.totalNumbers = response.data.numbers_count;

        self.carriers = response.data.carriers;
        self.accounts = response.data.accounts;

        self.status_values = response.data.status_values;
        self.selected_status_value = response.data.selected_status_value;

        self.del_values = response.data.del_values;
        self.roam_values = response.data.roam_values;

        self.groups = response.data.groups;
        self.groups_values = response.data.groups_values;

        self.contracts = response.data.contracts;

        if (self.phones.length <= 0) {
          self.phones = response.data.phones;
        }

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    updateBaseDatas() {
      let self = this;
//      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'sim_api/sims/',

        params: {
          carrier: this.carrier_selected,
          account: this.account_selected,
          phone: this.phone_selected,

          status: this.status_selected,
          del: this.del_selected,
          roam: this.roam_selected,

          contract: this.contracts_selected,
          group: this.groups_value_selected,

          select: this.selected_status_selected,
          number_ids: Array.from(this.number_selected, x=>x.id),

          pagination: this.options,
          phones_need: 'true',

        },
        data: {
          method: 'refresh_datas',
          action: 'datas refresh',

//          number_ids: Array.from(this.number_selected, x => x.id)
        },
      })
      .then(function (response) {
        self.numbers = response.data.numbers_data;
        self.totalNumbers = response.data.numbers_count;

        self.phones = response.data.phones;
        self.carriers = response.data.carriers;
        self.accounts = response.data.accounts;

        self.status_values = response.data.status_values;
        self.del_values = response.data.del_values;
        self.roam_values = response.data.roam_values;

        self.groups = response.data.groups;
        self.groups_values = response.data.groups_values;

        self.contracts = response.data.contracts;

//        self.number_selected = [];
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    updateRoaming() {
      let self = this;
//      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'sim_api/sims/',

        params: {
          carrier: this.carrier_selected,
          account: this.account_selected,
          phone: this.phone_selected,

          status: this.status_selected,
          del: this.del_selected,
          roam: this.roam_selected,

          contract: this.contracts_selected,
          group: this.groups_value_selected,

          select: this.selected_status_selected,
          number_ids: Array.from(this.number_selected, x=>x.id),

          pagination: this.options,

        },
        data: {
          method: 'refresh_roamings',
          action: 'roamings refresh',

          number_ids: Array.from(this.number_selected, x => x.id)
        },
      })
      .then(function (response) {
        self.numbers = response.data.numbers_data;
        self.totalNumbers = response.data.numbers_count;

        if (self.phones.length <= 0) {
          self.phones = response.data.phones;
        }

        self.carriers = response.data.carriers;
        self.accounts = response.data.accounts;

        self.status_values = response.data.status_values;
        self.del_values = response.data.del_values;
        self.roam_values = response.data.roam_values;

        self.groups = response.data.groups;
        self.groups_values = response.data.groups_values;

        self.contracts = response.data.contracts;

//        self.number_selected = [];
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    updatePayments() {
//refresh_fees
        let self = this;
//      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'sim_api/sims/',

        params: {
          carrier: this.carrier_selected,
          account: this.account_selected,
          phone: this.phone_selected,

          status: this.status_selected,
          del: this.del_selected,
          roam: this.roam_selected,

          contract: this.contracts_selected,
          group: this.groups_value_selected,

          select: this.selected_status_selected,
          number_ids: Array.from(this.number_selected, x=>x.id),

          pagination: this.options,

        },
        data: {
          method: 'refresh_fees',
          action: 'fees refresh',

          number_ids: Array.from(this.number_selected, x => x.id)
        },
      })
      .then(function (response) {
        self.numbers = response.data.numbers_data;
        self.totalNumbers = response.data.numbers_count;

        if (self.phones.length <= 0) {
          self.phones = response.data.phones;
        }

        self.carriers = response.data.carriers;
        self.accounts = response.data.accounts;

        self.status_values = response.data.status_values;
        self.del_values = response.data.del_values;
        self.roam_values = response.data.roam_values;

        self.groups = response.data.groups;
        self.groups_values = response.data.groups_values;

        self.contracts = response.data.contracts;

//        self.number_selected = [];
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    updateObjects() {
      let self = this;
//      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'sim_api/sims/',

        params: {
          carrier: this.carrier_selected,
          account: this.account_selected,
          phone: this.phone_selected,

          status: this.status_selected,
          del: this.del_selected,
          roam: this.roam_selected,

          contract: this.contracts_selected,
          group: this.groups_value_selected,

          select: this.selected_status_selected,
          number_ids: Array.from(this.number_selected, x=>x.id),

          pagination: this.options,

        },
        data: {
          method: 'refresh_objs',
          action: 'objs refresh',

//          number_selected: this.number_selected,
          number_ids: Array.from(this.number_selected, x => x.id)
        },
      })
      .then(function (response) {
        self.numbers = response.data.numbers_data;
        self.totalNumbers = response.data.numbers_count;

        if (self.phones.length <= 0) {
          self.phones = response.data.phones;
        }
        
        self.carriers = response.data.carriers;
        self.accounts = response.data.accounts;

        self.status_values = response.data.status_values;
        self.del_values = response.data.del_values;
        self.roam_values = response.data.roam_values;

        self.groups = response.data.groups;
        self.groups_values = response.data.groups_values;

        self.contracts = response.data.contracts;

//        self.number_selected = [];
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    downloadReport() {
      let self = this;
      self.overlay = true;

      let date_now = new Date().toISOString().substr(0, 10)
      let time_now = new Date().toLocaleTimeString('uk').substr(0, 8).replaceAll(':', '-')

      this.$http({
        method: 'get',
        url: 'sim_api/sims_report/',
        responseType: 'blob',

        params: {
          carrier: this.carrier_selected,
          account: this.account_selected,
          phone: this.phone_selected,

          status: this.status_selected,
          del: this.del_selected,
          roam: this.roam_selected,

          contract: this.contracts_selected,
          group: this.groups_value_selected,

          select: this.selected_status_selected,
          number_ids: Array.from(this.number_selected, x=>x.id),

        },
        data: {},
      })
      .then(function (response){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        const partsArray = [
          'report_sims',
//          self.source_selected,
//          sample_name,
          date_now,
          time_now,
        ];
        const fileName = partsArray.join('_')

        link.href = url;
        link.setAttribute('download', fileName+'.xlsx');
        document.body.appendChild(link);
        link.click();

        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    // Groups
//    newGroup() {
//      Vue.set(this, 'editedGroup', Object.assign({}, this.defaultGroup));
//      this.dialogGroup = true;
//    },
//
//    editGroup(item) {
//      this.editedGroupIndex = this.groups.indexOf(item);
//      this.editedGroup = Object.assign({}, item);
//
//      this.dialogGroup = true;
//    },
    groupEdit(name, subaction) {
      let self = this;
      self.overlay = true;

//      console.log('name:', name);
//      console.log('subaction:', subaction);
//      console.log('selected groups:', this.groups_selected);

      this.$http({
        method: 'post',
        url: 'sim_api/sims/',

        params: {
          carrier: this.carrier_selected,
          account: this.account_selected,
          phone: this.phone_selected,

          status: this.status_selected,
          del: this.del_selected,
          roam: this.roam_selected,

          contract: this.contracts_selected,
          group: this.groups_value_selected,

          select: this.selected_status_selected,
          number_ids: Array.from(this.number_selected, x=>x.id),

          pagination: this.options,
          phones_need: this.phones.length <= 0,

        },
        data: {
          method: 'edit_group',
          action: 'group edit',

          group_name: name,
          group_selected: this.groups_selected,

          subaction: subaction,

        },
      })
      .then(function (response) {
        self.numbers = response.data.numbers_data;
        self.totalNumbers = response.data.numbers_count;

        self.carriers = response.data.carriers;
        self.accounts = response.data.accounts;

        self.status_values = response.data.status_values;
        self.del_values = response.data.del_values;
        self.roam_values = response.data.roam_values;

        self.groups = response.data.groups;
        self.groups_values = response.data.groups_values;

        self.contracts = response.data.contracts;

        if (self.phones.length <= 0) {
          self.phones = response.data.phones;
        }

        self.groups_selected = [];
        self.editedGroup.name = '';
        self.dialogGroupsName = false;

        self.overlay = false;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    // SIMs management
    simsEdit(subaction) {
      let self = this;
      self.overlay = true;

//      console.log('subaction:', subaction);
//      console.log('number_selected:', this.number_selected);
//      console.log('sim_group_selected:', this.sim_group_selected);

      this.$http({
        method: 'post',
        url: 'sim_api/sims/',

        params: {
          carrier: this.carrier_selected,
          account: this.account_selected,
          phone: this.phone_selected,

          status: this.status_selected,
          del: this.del_selected,
          roam: this.roam_selected,

          contract: this.contracts_selected,
          group: this.groups_value_selected,

          select: this.selected_status_selected,
          number_ids: Array.from(this.number_selected, x=>x.id),

          pagination: this.options,
          phones_need: this.phones.length <= 0,

        },
        data: {
          method: 'edit_sims',
          action: 'sims edit',

          group_selected: this.sim_group_selected,
          numbers_selected: this.number_selected,

          subaction: subaction,

        },
      })
      .then(function (response) {
        self.numbers = response.data.numbers_data;
        self.totalNumbers = response.data.numbers_count;

        self.carriers = response.data.carriers;
        self.accounts = response.data.accounts;

        self.status_values = response.data.status_values;
        self.del_values = response.data.del_values;
        self.roam_values = response.data.roam_values;

        self.groups = response.data.groups;
        self.groups_values = response.data.groups_values;

        self.contracts = response.data.contracts;

        if (self.phones.length <= 0) {
          self.phones = response.data.phones;
        }

        self.overlay = false;

      })
      .catch(function (error) {
        console.log(error);
      })

    },


    close() {
        this.dialogSimsManage = false;
        this.dialogGroupsManage = false;

//        this.sim_group_selected = null;
      },
    closeGroupName() {
        this.dialogGroupsName = false;
    },

  },

  computed: {
    editedGroupTitle() {
      return this.groups_selected.length === 0 ? 'New Group' : 'Edit Group'
    },

    numbersSelected() {
//      return Array.from(this.number_selected, x=>parseInt(x.number)).toString()
      return Array.from(this.number_selected, x=>parseInt(x.number)).join(', ')
    },

  },

  watch: {
    carrier_selected: function() {
      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },
    account_selected: function() {
      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },
    phone_selected: function() {
      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },

    status_selected: function() {
      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },
    del_selected: function() {
      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },
    roam_selected: function() {
      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },

    contracts_selected: function() {
      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },
    groups_value_selected: function() {
      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },

    selected_status_selected: function() {
//      console.log('number_selected:', this.number_selected);
//      console.log('this.selected_status_selected:', this.selected_status_selected);

      this.options.page = 1;
//      this.number_selected = [];

      this.getData();
    },

    number_selected: function() {
//      console.log('number_selected:', this.number_selected);
//      console.log('this.selected_status_selected:', this.selected_status_selected);

      if (this.selected_status_selected) {
        console.log('ping...');

        this.options.page = 1;
        this.getData();

      }
    },

    options: {
        handler() {
//          this.number_selected = [];
          this.getData();
        },
        deep: true,
      },

  },

}
</script>

<style scoped>

</style>
