<template>
  <div class="qr">

    <v-dialog v-model="dialogScanner" max-width="480px" persistent>
      <v-container>
          <v-card>

            <v-card-title class="primary white--text">
              <span>Scanner</span>
            </v-card-title>
            <v-card-text>
              <StreamBarcodeReader
                v-if="dialogScanner"
                ref="scanner"
                @decode="onDecode"
                @loaded="onLoaded"
                class="mt-5"
              ></StreamBarcodeReader>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeScanner">close</v-btn>
              </v-card-actions>

          </v-card>
      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogGenerator" max-width="480px" persistent>
      <v-container>
          <v-card>

            <v-card-title class="primary white--text">
              <span>Generator</span>
            </v-card-title>
            <v-card-text>

              <v-layout justify-center>
                <v-text-field
                  v-model="qr_text"
                  label="example"
                  prepend-icon="mdi-qrcode-plus"
                  outlined
                  dense
                  class="pt-5"
                ></v-text-field>
              </v-layout>

              <v-layout justify-center>
                <v-card outlined style="border: 7px solid white;">
                  <qr-code
                    :text="this.qr_text"
                    :size="180"
                    error-level="L"
                    color="#0e1621"
                  ></qr-code>
                </v-card>
              </v-layout>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeGenerator">close</v-btn>
            </v-card-actions>

          </v-card>
      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn color="primary" @click="dialogScanner=true">
                <v-icon color="white">mdi-line-scan</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Scanner</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn color="primary" @click="qr_text=qr_sample; dialogGenerator=true">
                <v-icon color="white">mdi-qrcode</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Generator</span>
        </v-tooltip>

        <v-spacer></v-spacer>

<!--Filters-->

<!--        <v-flex xs1 lg1 xl1>-->
<!--          <v-autocomplete-->
<!--            v-model="carrier_selected"-->
<!--            :items="carriers"-->
<!--            item-text="name"-->
<!--            item-value="id"-->
<!--            label="Carrier"-->
<!--            clearable-->
<!--            prepend-icon="mdi-cellphone"-->
<!--          ></v-autocomplete>-->
<!--        </v-flex>-->

      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-layout>
          <span>Scan result: {{ this.qr_text }}</span>
        </v-layout>

        <v-layout>
          <span>Height: {{ windowHeight }}, Width: {{ windowWidth }}</span>
        </v-layout>

        <v-layout>
          <span>Mobile: {{ $vuetify.breakpoint.mobile }}, Name: {{ $vuetify.breakpoint.name }}</span>
        </v-layout>

        <v-layout>
            <v-file-input
              label="File input"
              outlined
              dense
              capture="environment"
              accept="image/*"
            ></v-file-input>
        </v-layout>



      </v-card-text>


    </v-card>

  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader';


export default {
  name: 'QR',

  components: {StreamBarcodeReader},

  data() {
    return {

      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,

      overlay: false,
      load: false,

      qr_text: '',
      qr_sample: '{"key": "value", "key2": "value2", "key3": "value3"}',

      dialogScanner: false,
      dialogGenerator: false,

//      menu: false,
//      picker: new Date().toISOString().substr(0, 7),
//      current_month: new Date().toISOString().substr(0, 7),

//      headers: [
//        { text: 'id', value: 'id' },
//
//        { text: 'number', value: 'id' },
//        { text: 'account', value: 'id' },
//        { text: 'carrier', value: 'id' },
//
//        { text: 'name', value: 'id' },
//        { text: 'comment', value: 'id' },
//
//        { text: 'blocked', value: 'id' },
//
//      ],

    }
  },

  created() {
//      this.getData();
    },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
//    getData() {
//      let self = this;
//      self.load = true;
//
//      this.$http({
//        method: 'get',
//        url: '',
//
//        params: {},
//        data: {},
//      })
//      .then(function (response) {
//        self.numbers = response.data.numbers;
//
//        self.load = false;
//      })
//      .catch(function (error) {
//        console.log(error);
//      })
//    },

    onDecode(text) {
      console.log(`onDecode text from QR code is ${text}`);

      this.qr_text = text;
      this.closeScanner();
    },

//    onResult(text) {
//      console.log(`onResult text from QR code is ${text}`);
//
//      this.qr_text = text;
////      this.closeScanner();
//    },

    onLoaded() {
       console.log(`Ready to start scanning barcodes`);
    },

    closeScanner() {
//      this.$refs.scanner.codeReader.stream.getTracks().forEach(function (track) { track.stop() });
//      this.$refs.scanner.codeReader.reset();
      this.dialogScanner = false;
      console.log(`Scanner of barcodes is unmounted`);
    },

    closeGenerator() {
      this.dialogGenerator = false;

    },

    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    }

  },

  computed: {
//    getHeight() {
//        return window.innerHeight
//    },
//
//    getWidth() {
//        return window.innerWidth
//    },

//    getBreakpoint() {
//      return $vuetify.breakpoint.mobile
//    }

  },

  watch: {},

}
</script>

<style scoped>

</style>
