<template>
  <div class="repairs">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogDevice" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Device</span>
        </v-card-title>
        <v-card>

          <v-card-text>
          <v-container>
            <v-layout row wrap>

              <v-flex xs4>
                <v-text-field
                  label="IMEI"
                  prepend-icon="mdi-checkbox-blank-outline"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  label="Serial"
                  prepend-icon="mdi-checkbox-blank-outline"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  label="QR code"
                  prepend-icon="mdi-checkbox-blank-outline"
                ></v-text-field>
              </v-flex>

            </v-layout>

            <v-divider class="ma-7" inset></v-divider>

            <v-layout row wrap>

              <v-flex xs6>
                <v-autocomplete
                  v-model="contractor_selected"
                  :items="contractors"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="Contractor"
                  prepend-icon="mdi-checkbox-blank-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>

              <v-flex xs3>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="picker"
                      label="Buy"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="picker"
                    @input="menu = false"

                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs3>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="picker"
                      label="Sell"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="picker"
                    @input="menu = false"

                  ></v-date-picker>
                </v-menu>
              </v-flex>

            </v-layout>

            <v-divider class="ma-7" inset></v-divider>

            <v-layout row wrap>

              <v-flex xs3>
                <v-autocomplete
                  v-model="type_selected"
                  :items="types"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="Type"
                  prepend-icon="mdi-checkbox-blank-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>
              <v-flex xs5>
                <v-autocomplete
                  v-model="producer_selected"
                  :items="producers"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="Producer"
                  prepend-icon="mdi-checkbox-blank-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>
              <v-flex xs4>
                <v-autocomplete
                  v-model="model_selected"
                  :items="models"
                  item-text="name"
                  item-value="id"
                  hide-selected
                  clearable
                  label="Model"
                  prepend-icon="mdi-checkbox-blank-outline"
                  return-object
                ></v-autocomplete>
              </v-flex>

            </v-layout>

          </v-container>
        </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">cancel</v-btn>
          <v-btn
              text
              @click="saveDevice()"
              color="green darken-3"
          >save</v-btn>
        </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogTicket" max-width="1100px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <v-layout row wrap>
                <v-flex xs4>
                  <v-text-field
                    v-model="ttn.number"
                    label="ttn"
                    prepend-icon="mdi-checkbox-blank-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="pa-3 ">
                        <v-btn
                          @click="searchTTN()"
                          color="primary"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Get TTN Data</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>

              <v-divider class="ma-7" inset></v-divider>

              <v-layout row wrap>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="contractor_selected"
                    :items="contractors"
                    item-text="name_complete"
                    item-value="id"
                    label="contractor"
                    prepend-icon="mdi-checkbox-blank-outline"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>

<!--              <v-divider class="ma-7" inset></v-divider>-->

              <v-layout row wrap>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.pib"
                    label="pib"
                    prepend-icon="mdi-checkbox-blank-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.phone"
                    label="phone"
                    prepend-icon="mdi-checkbox-blank-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.email"
                    label="e-mail"
                    prepend-icon="mdi-checkbox-blank-outline"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-divider class="ma-7" inset></v-divider>

<!--              <v-layout row wrap>-->

<!--                <v-flex xs4>-->
<!--                  <v-text-field-->
<!--                    label="imei"-->
<!--                    prepend-icon="mdi-checkbox-blank-outline"-->
<!--                  ></v-text-field>-->
<!--                </v-flex>-->
<!--                <v-flex xs4>-->
<!--                  <v-text-field-->
<!--                    label="serial"-->
<!--                    prepend-icon="mdi-checkbox-blank-outline"-->
<!--                  ></v-text-field>-->
<!--                </v-flex>-->
<!--                <v-flex xs4>-->
<!--                  <v-text-field-->
<!--                    label="qr code"-->
<!--                    prepend-icon="mdi-checkbox-blank-outline"-->
<!--                  ></v-text-field>-->
<!--                </v-flex>-->

<!--              </v-layout>-->

<!--              <v-divider class="ma-7" inset></v-divider>-->

              <v-layout row wrap
                v-for="(obj, index) in parcel_box"
                :key="`extra-${index}`"
              >

                <v-flex xs1 class="mt-2">{{index+1}}.</v-flex>

                <v-flex xs3 class="pr-2">
                  <v-autocomplete
                    v-model="obj.object"
                    :items="repair_objects"
                    item-text="serial"
                    item-value="id"
                    label="object"
                    hide-details="auto"
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs3 class="pr-2">
                  <v-text-field
                    v-if="!obj.object"
                    v-model="obj.imei"
                    label="serial new"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :value="obj.object.serial"
                    label="serial"
                    hide-details="auto"
                    outlined

                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 class="pr-2">
                  <v-autocomplete
                    v-if="!obj.object"
                    v-model="obj.prod_id"
                    :items="devices"
                    item-text="name"
                    item-value="id"
                    label="device new"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    :value="obj.object.name"
                    label="device"
                    hide-details="auto"
                    outlined

                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 class="pr-2">
                  <v-file-input
                    v-model="obj.file"
                    label="act(s)"
                    counter
                    multiple
                    show-size
                    truncate-length="15"
                    outlined
                    dense
                  ></v-file-input>
                </v-flex>

                <v-flex xs1 class="align-self-center d-flex justify-center pb-5">
                  <v-icon @click="addDevice(obj, parcel_box)">mdi-plus</v-icon>
                  <v-icon
                      v-show="parcel_box.length > 1"
                      @click="removeDevice(index, parcel_box)"
                    >mdi-delete-outline
                  </v-icon>
                </v-flex>

              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              text
              @click="saveTickets"
              color="green darken-3"
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogTTN" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New TTN</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <v-layout row wrap>
                <v-flex xs4>
                  <v-text-field
                    v-model="ttn.number"
                    label="ttn"
                    prepend-icon="mdi-checkbox-blank-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="pa-3 ">
                        <v-btn
                          @click="searchTTN()"
                          color="primary"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Get TTN Data</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              text
              @click="saveTTN"
              color="green darken-3"
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
        
<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="dialogTicket=true"
                color="primary"
                :disabled="serial_selected === null"
              >
                <v-icon color="white">mdi-calendar-plus-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Repair Ticket</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="dialogTTN=true"
                color="primary"
                :disabled = "selected.length === 0"
              >
                <v-icon color="white">mdi-cube-send</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Add TTN</span>
        </v-tooltip>

<!--        <v-tooltip top>-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <div v-bind="attrs" v-on="on" class="px-2">-->
<!--              <v-btn-->
<!--                @click="dialogDevice=true"-->
<!--                color="primary"-->
<!--                :disabled="serial_selected === null"-->
<!--              >-->
<!--                <v-icon color="white">mdi-card-plus-outline</v-icon>-->
<!--              </v-btn>-->
<!--            </div>-->
<!--          </template>-->
<!--          <span>New Device Card</span>-->
<!--        </v-tooltip>-->
        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contractor_selected"
            :items="filter_datas.contractors"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="contractor"
            prepend-icon="mdi-code-block-braces"
            return-object
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="serial_selected"
            :items="filter_datas.imeis"
            item-text="serial_device"
            item-value="id"
            hide-selected
            clearable
            label="imei (serial)"
            prepend-icon="mdi-code-block-braces"
            return-object
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="device_selected"
            :items="filter_datas.devices"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="device"
            prepend-icon="mdi-code-block-braces"
            return-object
          ></v-autocomplete>
        </v-flex>

      </v-card-title>

      <v-divider></v-divider>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tickets"

        show-select

        show-expand
        single-expand
        :expanded.sync="expanded"

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalTickets"

        :loading="load"
        loading-text="Repair Tickets loading... Please wait"

        mobile-breakpoint="0"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>
            <v-container>
              <v-card>
                <v-card-title>
                  <v-tabs v-model='tab' right height="35">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab v-for="t in tabs" :key="t.id">
                      <v-icon left>{{ t.icon }}</v-icon>
                      {{ t.name }}
                    </v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text>
                  <v-tabs-items v-model="tab">

                    <v-tab-item key="0">
                      <v-card>
                        <v-card-title>
                          <v-icon color="primary">mdi-car-outline</v-icon>
                          <v-divider class="mx-4" vertical></v-divider>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout row wrap d-flex xs12 sm12 md12>
                              <v-card >
                                <v-card-title>
                                  Інформація
                                </v-card-title>
                                <v-card-text>
                                  контактні дані відповідального, причина для ремонту, акт дефектовки
                                </v-card-text>
                              </v-card>
                            </v-layout>

                          </v-container>
                        </v-card-text>

                      </v-card>
                    </v-tab-item>

                    <v-tab-item key="1">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout row wrap d-flex xs12 sm12 md12>
                              <v-card>
                                <v-card-title>
                                  таблиця ТТН-ок
                                </v-card-title>
                                <v-card-text>
                                  в(и)хідні, номер, дата, відправник, отримувач, статус, всртість, платник
                                </v-card-text>
                              </v-card>
                            </v-layout>

                            <v-layout row wrap>
                              <v-chip
                                v-for="t in item.ttns"
                                :key="t"
                                class="ma-2"
                                color="primary"
                              >
                                {{ t }}
                              </v-chip>
                            </v-layout>

                          </v-container>
                        </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="2">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-container fluid grid-list-md>
                          <v-layout column wrap d-flex>
                            <v-card>
                              <v-card-title>
                                Доремонтна діагностика
                              </v-card-title>
                              <v-card-text>
                                хід діагностики, результати діагностика, фото і документи діагностики, висновки діагностики
                                <v-textarea></v-textarea>

                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
<!--                                <v-btn text @click="close">cancel</v-btn>-->
                                <v-btn
                                  text
                                  color="green darken-3"
                                >save</v-btn>
                              </v-card-actions>

                            </v-card>
                          </v-layout>

                          <v-divider inset mx10></v-divider>

                          <v-layout column wrap d-flex>
                            <v-card >
                              <v-card-title>
                                Зміна IMEI
                              </v-card-title>
                              <v-card-text row wrap align="center">
                                новий номер IMEI, гарантійний обмін (Teltonika)
                                <v-flex xs4>
                                  <v-text-field ></v-text-field>
                                </v-flex>
                                <v-flex xs4>
                                  <v-checkbox
                                    label="warranty replacement">
                                  </v-checkbox>
                                </v-flex>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
<!--                                <v-btn text @click="close">cancel</v-btn>-->
                                <v-btn
                                  text
                                  color="green darken-3"
                                >save</v-btn>
                              </v-card-actions>

                            </v-card>
                          </v-layout>

                          <v-divider></v-divider>

                          <v-layout column wrap d-flex>
                            <v-card >
                              <v-card-title>
                                Післяремонтна діагностика
                              </v-card-title>
                              <v-card-text>
                                хід діагностики, результати діагностика, фото і документи діагностики, висновки діагностики
                                <v-textarea></v-textarea>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
<!--                                <v-btn text @click="close">cancel</v-btn>-->
                                <v-btn
                                  text
                                  color="green darken-3"
                                >save</v-btn>
                              </v-card-actions>

                            </v-card>
                          </v-layout>


                        </v-container>
                      </v-card-text>


                    </v-tab-item>

                    <v-tab-item key="3">
                      <v-card-title>
                        <v-icon color="primary">mdi-compare-remove</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headersHistory"
                        :items="item.history"
                        :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                        :items-per-page="-1"

                        dense
                        mobile-breakpoint="0"
                        class="elevation-1"
                      ></v-data-table>

                      <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout row wrap d-flex xs12 sm12 md12>
                              <v-card >
                                <v-card-title>
                                  Тікет виробника
                                </v-card-title>
                                <v-card-text>
                                  розміщення повідомлень від замовника і відповіді виробника, документи
                                </v-card-text>
                              </v-card>
                            </v-layout>

                          </v-container>
                        </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="4">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout row wrap d-flex xs12 sm12 md12>
                              <v-card >
                                <v-card-title>
                                  Історія тікета
                                </v-card-title>
                                <v-card-text>
                                  дата, користувач, дія
                                </v-card-text>
                              </v-card>
                            </v-layout>

                          </v-container>
                        </v-card-text>

                    </v-tab-item>

                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-container>
          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Repair',

  data() {
    return {
      overlay: false,
      load: false,
      loadObjects: false,

      selected: [],
      tickets: [],

//      contracts: [],
//      contract_selected: null,


      filter_datas: [],

      serial_selected: [],
      device_selected: [],
      contractor_selected: [],


      contractors: [],

//      existed_objects: [],
//      existed_object_selected: [],

      types: [],
      type_selected: [],

      producers: [],
      producer_selected: [],

      repair_objects: [],
      devices: [],

      customer: {pib: '', phone: '', email: ''},
      ttn: {number: '', data: {}},

      models: [],
      model_selected: [],

      expanded: [],
//
      dialogTicket: false,
      dialogDevice: false,
      dialogTTN: false,

//
//      dialogComment: false,
//      editedComment: {text: ''},
//
//      comment_files: [],
//      act_files: [],

      menu: false,
      picker: new Date().toISOString().substr(0, 10),

      rules: [v => 13 <= v.length || 'Min 13 characters'],


      parcel_box: [{imei: '', }],


//      cart: {service: [], },
//      filter: '',

      tab: null,
      tabs: [
        { id: 1, name: 'Info', icon: 'mdi-car-outline' },
        { id: 2, name: 'TTN Datas', icon: 'mdi-forum-outline'},
        { id: 3, name: 'Tech Support', icon: 'mdi-compare-remove' },
        { id: 4, name: 'Vend Repair', icon: 'mdi-compare-remove' },
        { id: 5, name: 'TimeLine', icon: 'mdi-compare-remove' },
      ],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },
      sortBy: '-id',
      sortDesc: false,
      totalTickets: 0,

      headers: [
        { text: 'id', value: 'id' },
        { text: 'datetime', value: 'date_create', sortable: false},   // , align: ' d-none d-md-table-cell'

        { text: 'serial', value: 'serial_device', sortable: false },  // , align: 'center'
        { text: 'device', value: 'name_device', sortable: false },  // , align: 'center'

        { text: 'contractor', value: 'contractor', sortable: false},

        { text: 'place', value: 'place', sortable: false},
        { text: 'stage', value: 'stage', sortable: false},

        { text: 'details', value: 'data-table-expand', sortable: false },

      ],

      headersHistory: [],
      headersComments: [],
      headersTicket: [],

    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_repair/',

        params: {
//          contract: this.contract_selected,
//          existed_object: this.existed_object_selected,

          pagination: this.options,
        },
        data: {},
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.filter_datas = response.data.filter_datas;

        self.contractors = response.data.contractors;

        self.repair_objects = response.data.repair_objects;
        self.devices = response.data.devices;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveTickets() {
      let self = this;
      self.load = true;

//      self.overlay = true;
//      self.dialogCheckout = false;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {

          pagination: this.options,

        },
        data: {

          ttn: this.ttn,

          contractor_id: this.contractor_selected,

          customer: this.customer,

          parcel_box: this.parcel_box,

          method: 'create_tickets',
          action: 'tickets create',

        },
      })
      .then(function (response) {
//        self.ticket_id = response.data.new_ticket_id;
//        console.log('new_ticket_id from server:', response)  // , response.data.new_ticket_id)

//        self.cart = {pause: [], active: [], external: [], internal: [], create: [], delete: [], rename: [], resims: [], }
//
//        self.saveFiles();
//
//        self.contracts_data = response.data.contracts_data;
//        self.contract_filters = response.data.contract_filters;
//
//        self.dialogCheckout = false;

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self. dialogTicket = false;

        self.overlay = false;
        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    searchTTN() {},

    saveTTN() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {

          pagination: this.options,

        },
        data: {

          ttn: this.ttn,
          tickets: this.selected,

          method: 'create_ttn',
          action: 'ttn create',

        },
      })
      .then(function (response) {

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.dialogTTN = false;

        self.overlay = false;
        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    // методи додавання/видалення пристроїв
    addDevice(value, prodRow) {
        prodRow.push({
//          id: '*' + Math.floor(Math.random()*1000),
//          id: '',

//          name: '',
          imei: '',

//          phone_1: '',
//          phone_2: '',

//          last: '',

        });
      },

    removeDevice(index, prodRow) {
      prodRow.splice(index, 1);
    },

    close() {
      this.dialogTicket = false;
      this.dialogDevice = false;
      this.dialogTTN = false;

//      this.objects_selected = [];
//      this.cart = {service: [], };
    },

  },

  computed: {
//    cartQty() {
//        return this.cart.service.length
//    },

  },

  watch: {
//    comment_files: function() {
//        console.log('comment_files', this.comment_files)
//    },
//
//    zoho_ticket_selected: function() {
//      console.log('zoho ticket select', this.zoho_ticket_selected);
//      this.unknown_email = this.zoho_ticket_selected.email
//    },
//
//    contract_selected: function() {
//      this.getData();
//    },
//
    parcel_box: function() {
      console.log('parcel_box:', this.parcel_box);
    },

    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

    expanded: function() {
      console.log('expanded:', this.expanded)
    }

  },


}
</script>

<style scoped>

</style>
