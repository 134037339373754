<template>
  <div class="access_denied" style="margin-top: 10em">

    <v-row justify="center">
<!--      <v-img v-if="!user_info || user_info.name === 'anonymous_user'"-->
<!--        src="../assets/VaultBoySet_004.png"-->
<!--        max-width="400px"-->
<!--        max-height="400px"-->
<!--        alt="Whassup"-->
<!--      ></v-img>-->
      <v-img
        src="../assets/VaultBoyNewSet_003.png"
        max-width="624px"
        max-height="454px"
        alt="Billy Boy Access is Denied"
      ></v-img>
    </v-row>

    <v-row class="text-center">
      <v-col class="mb-4">
<!--        <h1 v-if="user_info.name !== 'anonymous_user'" class="display-2 font-weight-bold mb-3">-->
<!--          {{ $t('main.welcome') }}, {{ user_info.first_name }}-->
<!--        </h1>-->
        <h1 class="display-2 font-weight-bold mb-3">
          Access is Denied
        </h1>
        <p class="subheading font-weight-regular">{{ $t('main.denied') }}</p>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: 'AccessDenied',

//  components: {},

  data() {
    return {

//      from: $router.from,

      user_info: {
        name: 'anonymous_user',
        first_name: 'Anonymous',

      }
    }
  },

  created() {
    if (localStorage.getItem('permissions')) {
      this.permissions = JSON.parse(localStorage.permissions);
//      console.log('permissions:', this.permissions)
    }

  },

}
</script>
