<template>
  <div class="plans">

    <v-dialog v-model="dialogPlan" max-width="500px" persistent>
        <v-container>

          <v-card-title class="primary white--text">
            <span>{{ formPlanTitle }}</span>
          </v-card-title>
          <v-card>

            <v-card-text>
              <v-container>
                <v-layout row wrap>

                  <!-- name, description -->
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedPlan.name"
                      label="name"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedPlan.description"
                      label="description"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-flex>

                  <!-- costs -->
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedPlan.psd"
                      label="paused"
                      type="number"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedPlan.nav"
                      label="active"
                      type="number"
                      :rules="[rules.required]"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedPlan.dut"
                      label="dut sensor"
                      type="number"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedPlan.drt"
                      label="drt sensor"
                      type="number"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedPlan.can"
                      label="can sensor"
                      type="number"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="editedPlan.sim"
                      label="external sim"
                      type="number"
                      required
                    ></v-text-field>
                  </v-flex>

                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close">cancel</v-btn>
              <v-btn
                  text
                  @click="savePlan(editedPlan)"
                  :disabled="!editedPlan.name || !editedPlan.description || !editedPlan.psd || !editedPlan.nav"
                  color="green darken-3"
              >save</v-btn>
            </v-card-actions>

          </v-card>

        </v-container>
      </v-dialog>

    <v-card>

      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                @click="newPlan()"
                color="primary"
                depressed
              >
                <v-icon>mdi-plus-circle-multiple-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Billing Plan</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <!-- Filters -->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="psd_selected"
            :items="psd_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="psd"
            prepend-icon="mdi-alpha-p-circle-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="nav_selected"
            :items="nav_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="nav"
            prepend-icon="mdi-alpha-n-circle-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="dut_selected"
            :items="dut_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="dut"
            prepend-icon="mdi-alpha-u-circle-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="drt_selected"
            :items="drt_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="drt"
            prepend-icon="mdi-alpha-r-circle-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="can_selected"
            :items="can_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="can"
            prepend-icon="mdi-alpha-c-circle-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="sim_selected"
            :items="sim_values"
            item-text="name"
            item-value="value"
            hide-selected
            clearable
            label="sim"
            prepend-icon="mdi-alpha-s-circle-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="plans"
        sort-by="description"

        :loading="load"
        loading-text="Plans loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'Billings',

    data() {
      return {
        plans: [],

        load: false,

        dialogPlan: false,
        editedPlan: {name: ''},
        defaultPlan: {name: ''},
        editedPlanIndex: -1,

        psd_values: [],
        nav_values: [],
        dut_values: [],
        drt_values: [],
        can_values: [],
        sim_values: [],

        psd_selected: [],
        nav_selected: [],
        dut_selected: [],
        drt_selected: [],
        can_selected: [],
        sim_selected: [],

        headers: [
          { text: 'id', value: 'id' },

          { text: 'name', value: 'name' },
          { text: 'description', value: 'description' },

          { text: 'psd', value: 'psd' },
          { text: 'nav', value: 'nav' },

          { text: 'dut', value: 'dut' },
          { text: 'drt', value: 'drt' },
          { text: 'can', value: 'can' },
          { text: 'sim', value: 'sim' },

          { text: 'workload', value: 'contracts_count' },
          { text: 'objects', value: 'objects_count' },

//          { text: 'edit', value: 'action' },
        ],

        rules: {
          required: value => !!value || 'required!',
//          number: value => value.length >= 19 || 'short number!',
//          email: value => {
//            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//            return pattern.test(value) || 'invalid email!'
        },

      }
    },

    created() {
      this.getData();
    },

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'plan_api/plans/',

          params: {
            psd: this.psd_selected,
            nav: this.nav_selected,
            dut: this.dut_selected,
            drt: this.drt_selected,
            can: this.can_selected,
            sim: this.sim_selected,

          },
          data: {},

        })
        .then(function (response){
          self.plans = response.data.plans;

          self.psd_values = response.data.costs_unique.psd;
          self.nav_values = response.data.costs_unique.nav;
          self.dut_values = response.data.costs_unique.dut;
          self.drt_values = response.data.costs_unique.drt;
          self.can_values = response.data.costs_unique.can;
          self.sim_values = response.data.costs_unique.sim;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      newPlan() {
        Vue.set(this, 'editedPlan', Object.assign({}, this.defaultPlan));
        this.dialogPlan = true;
      },

//      editPlan(item) {
//        this.editedPlanIndex = this.plans.indexOf(item);
//        this.editedPlan = Object.assign({}, item);
//
//        this.dialogPlan = true;
//      },

      savePlan(item) {
        const self = this;

        this.$http({
          method: 'post',
          url: 'plan_api/plans/',

          params: {},
          data: {
            id: item.id,
            name: item.name,
            description: item.description,

            psd: item.psd,
            nav: item.nav,

            dut: item.dut,
            drt: item.drt,
            can: item.can,
            sim: item.sim,

            action: item.id ? 'edit' : 'create',
          },

        })
        .then(function (response) {
          self.plans = response.data.plans;

          self.dialogPlan = false;
          self.alert('changes saved');

        })
        .catch(function (error) {
          console.log(error);

        })
      },

      close() {
        this.dialogPlan = false;
        this.editedPlanIndex = -1;

      },

    },

    computed: {
      formPlanTitle() {
        return this.editedPlanIndex === -1 ? 'New Billing Plan' : 'Edit Billing Plan'
      },

      formIsValid() {
        return (
          this.editedPlan.name &&
          this.editedPlan.base &&
          this.editedPlan.active &&
          this.editedPlan.sim &&
          this.editedPlan.dut
        )
      },

    },

    watch: {
      psd_selected: function() {
        this.getData();
      },
      nav_selected: function() {
        this.getData();
      },
      dut_selected: function() {
        this.getData();
      },
      drt_selected: function() {
        this.getData();
      },
      can_selected: function() {
        this.getData();
      },
      sim_selected: function() {
        this.getData();
      },

    },

  }
</script>

<style scoped>

</style>
