<template>
  <div class="services">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogObjects" max-width="90%" persistent>
<!--      <v-container>-->
        <v-card>

          <v-card-title class="primary white--text">
            <span>Objects</span>
          </v-card-title>
          <v-card-text>

            <v-card-title>
              <v-icon color="primary">mdi-calendar-month-outline</v-icon>
              <v-divider class="mx-4" inset vertical></v-divider>
<!-- Button -->
              <div class="d-flex justify-left align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="pl-2">
                      <v-badge
                        :content="objects_selected.length"
                        :value="objects_selected.length"
                        color="primary"
                        bordered
                      >
                        <v-btn
                          :disabled="objects_selected.length < 1"
                          @click="addObj(cart.service)"
                          color="primary"
                          depressed
                        >
                          <v-icon color="white">mdi-cart-plus</v-icon>
                        </v-btn>
                      </v-badge>
                    </div>
                  </template>
                  <span>Add Object(s)</span>
                </v-tooltip>
              </div>
              <v-spacer></v-spacer>
<!-- Cart, Filter -->
              <div class="d-flex justify-right align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="px-5">
                      <v-badge
                        :content="cartQty"
                        :value="cartQty"
                        color="primary"
                        bordered
                      >
                        <v-btn
                          @click="checkoutCart()"
                          color="primary"
                          depressed
                          :disabled="cartQty < 1"
                        >
                        <v-icon color="white">mdi-cart-outline</v-icon>

                      </v-btn>
                    </v-badge>
                  </div>
                </template>
                <span>Cart</span>
              </v-tooltip>
                <v-text-field
                  v-model="filter"
                  label="Filter"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </div>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              v-model="objects_selected"
              :headers="headersObjects"
              :items="objects"

              show-select
              :search="filter"

              :loading="loadObjects"
              loading-text="Objects loading... Please wait"

              :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
            >

              <template v-slot:item.paused="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon v-if="item.paused">mdi-check</v-icon>
                      <v-icon v-else>mdi-window-close</v-icon>
                    </div>
                  </template>
                  <span>{{ item.paused }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.dut="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon v-if="item.dut">mdi-check</v-icon>
                      <v-icon v-else>mdi-window-close</v-icon>
                    </div>
                  </template>
                  <span>{{ item.dut }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.drt="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon v-if="item.drt">mdi-check</v-icon>
                      <v-icon v-else>mdi-window-close</v-icon>
                    </div>
                  </template>
                  <span>{{ item.drt }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.can="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon v-if="item.can">mdi-check</v-icon>
                      <v-icon v-else>mdi-window-close</v-icon>
                    </div>
                  </template>
                  <span>{{ item.can }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.sim="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon v-if="item.sim">mdi-check</v-icon>
                      <v-icon v-else>mdi-window-close</v-icon>
                    </div>
                  </template>
                  <span>{{ item.sim }}</span>
                </v-tooltip>
              </template>

            </v-data-table>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
<!--              <v-btn text @click="dialogCheckout=true" color="green darken-3">save</v-btn>-->
          </v-card-actions>

        </v-card>
<!--      </v-container>-->
    </v-dialog>
    <v-dialog v-model="dialogCheckout" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New Service Ticket</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <div v-if="cart.service.length > 0">
<!--                <v-layout row wrap><span class="font-weight-bold">Pause:</span></v-layout>-->

                <v-layout row wrap class="pb-1"
                  v-for="(elem, index) in cart.service"
                  :key="`elem-${index}`"
                >
                  <v-flex xs1>{{index+1}}.</v-flex>

                  <v-flex xs4>{{ elem.name }}</v-flex>
                  <v-flex xs2>{{ elem.imei }}</v-flex>

                  <v-flex xs2>{{ elem.phone_1 }}</v-flex>
                  <v-flex xs2>{{ elem.phone_2 }}</v-flex>

<!--                  <v-flex xs4>-->
<!--                    <v-text-field-->
<!--                      v-model="elem.init_comment"-->
<!--                      outlined-->
<!--                      dense-->
<!--                      hide-details-->
<!--                    ></v-text-field>-->
<!--                  </v-flex>-->

                  <v-flex xs1 align-self-center>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon class="ml-7" @click="removeObj(index, cart.service)">mdi-cart-remove</v-icon>
                        </div>
                      </template>
                      <span>{{index+1}}. {{ elem.name }}</span>
                    </v-tooltip>
                  </v-flex>

                </v-layout>
                <v-divider class="ma-5"></v-divider>
              </div>

              <v-layout row wrap>

<!-- request email choose -->
              <v-flex xs4>
                <v-autocomplete
                    v-model="email_selected"
                    :items="emails"
                    item-text="name"
                    item-value="name"
                    label="allowed email"
                    prepend-icon="mdi-email-outline"
                    clearable
                    required
                  ></v-autocomplete>
              </v-flex>
<!-- set email (phone) -->
              <v-flex xs4>
                <v-text-field
                    v-model="unknown_email"
                    label="unknow contact"
                    prepend-icon="mdi-account-question-outline"
                    :disabled="email_selected !== null"
                ></v-text-field>
              </v-flex>
<!-- reason file -->

              </v-layout>

              <v-layout row wrap>

<!--                <v-flex xs4>-->
<!--                  <v-file-input-->
<!--                    v-model="reason_file"-->
<!--                    placeholder="Upload your documents"-->
<!--                    label="Reason document"-->
<!--                    prepend-icon="mdi-paperclip"-->
<!--                  >-->
<!--&lt;!&ndash;                    :rules="rulesRequired"&ndash;&gt;-->
<!--                    <template v-slot:selection="{ text }">-->
<!--                      <v-chip label color="primary">-->
<!--                        {{ text }}-->
<!--                      </v-chip>-->
<!--                    </template>-->
<!--                  </v-file-input>-->
<!--                </v-flex>-->
                <v-flex xs8>
                  <v-autocomplete
                    v-model="zoho_ticket_selected"
                    :items="zoho_tickets"
                    item-text="view_text"
                    item-value="id"
                    label="zoho ticket"
                    prepend-icon="mdi-ticket-confirmation-outline"
                    return-object
                  ></v-autocomplete>
              </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCheckout">cancel</v-btn>
            <v-btn
              text
              @click="saveCart"
              color="green darken-3"
              :disabled="!zoho_ticket_selected || (!email_selected && !unknown_email) || !cartQty"
              >confirm</v-btn>
          </v-card-actions>

        </v-card>
      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogComment" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Add Comment</span>
        </v-card-title>
        <v-card>

        <v-card-text>
          <v-container>
            <v-layout row wrap>

              <v-flex xs12>
                <v-textarea
                  v-model="editedComment.text"
                  label="Comment text"
                  counter
                  :rules="rules"
                  required
                ></v-textarea>
              </v-flex>

            </v-layout>

            <v-layout row wrap>
              <v-flex xs12>
                <v-file-input
                  v-model="comment_files"
                  placeholder="upload your files (with 'ctrl' for multiple)"
                  label="Comment file(s)"
                  prepend-icon="mdi-paperclip"

                  counter
                  multiple
                  show-size
                  truncate-length="21"
                  clearable
                >
<!--                    :rules="rulesRequired"-->
                  <template v-slot:selection="{ text }">
                    <v-chip label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">cancel</v-btn>
          <v-btn
              text
              @click="saveComment()"
              :disabled="editedComment.text.length < 13"
              color="green darken-3"
          >save</v-btn>
        </v-card-actions>

      </v-card>

    </v-container>
    </v-dialog>
    <v-dialog v-model="dialogAct" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Add Act to Object</span>
        </v-card-title>
        <v-card>

        <v-card-text>
          <v-container>
<!--            <v-layout row wrap>-->

<!--              <v-flex xs12>-->
<!--                <v-textarea-->
<!--                  v-model="editedComment.text"-->
<!--                  label="Comment text"-->
<!--                  counter-->
<!--                  :rules="rules"-->
<!--                  required-->
<!--                ></v-textarea>-->
<!--              </v-flex>-->

<!--            </v-layout>-->
            <v-layout row wrap>
              <v-flex xs12>
                <v-file-input
                  v-model="act_files"
                  placeholder="Upload your files (with 'ctrl' for multiple)"
                  label="Act file(s)"
                  prepend-icon="mdi-paperclip"

                  counter
                  multiple
                  show-size
                  truncate-length="21"
                  clearable

                  capture="environment"
                  accept="image/*"
                >
<!--                    :rules="rulesRequired"-->
                  <template v-slot:selection="{ text }">
                    <v-chip label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="saveAct()" color="green darken-3">save</v-btn>
          <v-btn text @click="close">cancel</v-btn>
        </v-card-actions>

      </v-card>

    </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="dialogObjects=true; getObjects(contract_selected)"
                color="primary"
                :disabled="contract_selected === null"
              >
                <v-icon color="white">mdi-calendar-plus-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Service Ticket</span>
        </v-tooltip>
        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="existed_object_selected"
            :items="existed_objects"
            item-text="view_name"
            item-value="id"
            hide-selected
            clearable
            label="Object"
            prepend-icon="mdi-car-outline"
            return-object
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>

<!--        <v-flex xs2 lg2 xl2>-->
<!--          <v-text-field-->
<!--            v-model="search_text"-->
<!--            label="Search text"-->
<!--            single-line-->
<!--            dense-->
<!--            outlined-->
<!--            hide-details-->
<!--            class="px-2"-->
<!--          ></v-text-field>-->
<!--        </v-flex>-->

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="tickets"

        show-expand
        single-expand
        :expanded.sync="expanded"

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalTickets"

        :loading="load"
        loading-text="Service Tickets loading... Please wait"

        dense
        mobile-breakpoint="0"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

<!--        <template v-slot:item.date_create="{ item }">-->
<!--          <v-tooltip top>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <div v-bind="attrs" v-on="on">-->
<!--                <v-btn-->
<!--                  icon-->
<!--                  small-->

<!--                  class="ma-2"-->
<!--                  :href="downloadPDF(item)"-->
<!--                  target="_blank"-->
<!--                >-->
<!--                  <v-icon dark>-->
<!--                    mdi-file-eye-->
<!--                  </v-icon>-->
<!--                </v-btn>-->
<!--              </div>-->
<!--            </template>-->
<!--            <span>{{ item.date_create }}</span>-->
<!--          </v-tooltip>-->
<!--        </template>-->
        <template v-slot:item.zoho_file="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  icon
                  small

                  class="ma-2"
                  :href="downloadPDF(item)"
                  target="_blank"
                >
                  <v-icon dark>
                    mdi-file-eye
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ item.reason_file_path }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.comment="{ item }">
          <v-badge
            :content="item.comments_count"
            :value="item.comments_count"

            color="primary"
            bordered
          >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  icon
                  small
                  class="mb-0"
                  @click="addComment(item, 'ticket')"
                >
                  <v-icon dark>mdi-forum-plus-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Add Comment to Ticket #{{ item.id }}</span>
          </v-tooltip>
          </v-badge>
        </template>

        <template v-slot:item.prod_order="{ item }">

<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <div v-bind="attrs" v-on="on">-->
                <v-autocomplete
                  v-if="!item.prod_order"
                  v-model="item.production_order_selected"
                  :items="production_orders"
                  item-text="number"
                  item-value="id"
                  clearable
                  hide-details
                  return-object
                  class="pb-5"
                ></v-autocomplete>
                <span v-else>{{ item.prod_order }}</span>

<!--              </div>-->
<!--            </template>-->

        </template>

        <template v-slot:item.service_chief_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-if="item.service_chief_confirm === false && item.production_order_selected"
                  v-model="item.service_chief_confirm"
                  @click="ticketConfirmation(item, 'service_chief')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.service_chief_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.service_chief_name }} {{ item.service_chief_date }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.manager_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
<!--                  v-if="item.manager_confirm === false && !item.complete &&-->
<!--                                  (item.manager_comments_count > 0 || item.admin_comments_count > 0) &&-->
<!--                                  (user_role.is_admin || user_role.is_manager)"-->
                <v-checkbox
                  v-if="item.manager_confirm === false"
                  v-model="item.manager_confirm"
                  @click="ticketConfirmation(item, 'manager')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.manager_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.manager_name }} {{ item.manager_date }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.manager_chief_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-if="item.manager_chief_confirm === false"
                  v-model="item.manager_chief_confirm"
                  @click="ticketConfirmation(item, 'manager_chief')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.manager_chief_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.manager_chief_name }} {{ item.manager_chief_date }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.tech_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-if="item.tech_confirm === false"
                  v-model="item.tech_confirm"
                  @click="ticketConfirmation(item, 'tech')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.tech_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.tech_name }} {{ item.tech_date }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.tech_chief_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-if="item.tech_chief_confirm === false"
                  v-model="item.tech_chief_confirm"
                  @click="ticketConfirmation(item, 'tech_chief')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.tech_chief_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.tech_chief_name }} {{ item.tech_chief_date }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.commercial_chief_confirm="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-if="item.commercial_chief_confirm === false"
                  v-model="item.commercial_chief_confirm"
                  @click="ticketConfirmation(item, 'commercial_chief')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.commercial_chief_confirm"></v-checkbox>
              </div>
            </template>
            <span>{{ item.commercial_chief_name }} {{ item.commercial_chief_date }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.complete="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-checkbox
                  v-if="item.complete === false"
                  v-model="item.complete"
                  @click="ticketConfirmation(item, 'complete')"
                ></v-checkbox>
                <v-checkbox v-else disabled v-model="item.complete"></v-checkbox>
              </div>
            </template>
            <span>{{ item.complete_name }} {{ item.complete_date }}</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>
            <v-container>
              <v-card>
                <v-card-title>
                  <v-tabs v-model='tab' right height="35">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab v-for="t in tabs" :key="t.id">
                      <v-icon left>{{ t.icon }}</v-icon>
                      {{ t.name }}
                    </v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text>
                  <v-tabs-items v-model="tab">

                    <v-tab-item key="0">
                      <v-card>
                        <v-card-title>
                          <v-icon color="primary">mdi-car-outline</v-icon>
                          <v-divider class="mx-4" vertical></v-divider>
                        </v-card-title>
                        <v-divider></v-divider>

<!--                        <v-card>-->
<!--                          <v-card-title>-->
<!--                            Service-->
<!--                          </v-card-title>-->
<!--                          <v-card-text>-->
                            <v-data-table
                              :headers="headersTicket"
                              :items="item.details"
                              :hide-default-footer="true"

                              dense
                              mobile-breakpoint="0"
                              class="elevation-1"
                            >

                              <template v-slot:item.comment="{ item }">
                                  <v-badge
                                    :content="item.comments_count"
                                    :value="item.comments_count"

                                    color="primary"
                                    bordered
                                  >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                      <v-btn
                                        icon
                                        small
                                        class="mb-0"
                                        @click="addComment(item, 'object')"
                                      >
                                        <v-icon dark>mdi-forum-plus-outline</v-icon>
                                      </v-btn>
                                    </div>
                                  </template>
                                  <span>Add Comment in #{{ item.id }}</span>
                                </v-tooltip>
                                  </v-badge>
                              </template>
                              <template v-slot:item.acts="{ item }">

<!--                                <v-badge-->
<!--                                    :content="item.comments_count"-->
<!--                                    :value="item.comments_count"-->

<!--                                    color="primary"-->
<!--                                    bordered-->
<!--                                  >-->

                                <div class="d-flex justify-start">
                                  <div v-for="f_path in item.act_paths" :key="f_path">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          v-on="on"

                                          target="_blank"
                                          :href="downloadFile(f_path)"

                                          icon
                                          small
                                        >
                                          <v-icon dark>mdi-file-eye</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ f_path }}</span>
                                    </v-tooltip>
                                  </div>

                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <v-btn
                                          v-if="!item.act_confirm"
                                          icon
                                          small
                                          class="mb-0"
                                          @click="addAct(item)"
                                          :disabled="!item.work_confirm"
                                        >
                                          <v-icon v-if="item.acts.length !== 0" dark>mdi-file-edit-outline</v-icon>
                                          <v-icon v-else dark>mdi-file-plus-outline</v-icon>
                                        </v-btn>
                                      </div>
                                    </template>
                                    <span v-if="item.acts.length===0">Add Act(s) in #{{ item.id }}</span>
                                    <span v-else>Change Act(s) in #{{ item.id }}</span>
                                  </v-tooltip>
                                </div>

<!--                                  </v-badge>-->
                              </template>

                              <template v-slot:item.work_confirm="{ item }">
<!--                                <v-tooltip top>-->
<!--                                  <template v-slot:activator="{ on, attrs }">-->
<!--                                    <div v-bind="attrs" v-on="on">-->
                                      <v-simple-checkbox
                                        v-model="item.work_confirm"
                                        :disabled="item.work_confirm"
                                        @click="objectConfirmation(item, 'work')"
                                      ></v-simple-checkbox>
<!--                                      <v-checkbox v-else disabled v-model="item.work_confirm"></v-checkbox>-->

<!--                                    </div>-->
<!--                                  </template>-->
<!--                                  <span>{{ item.tech_name }} {{ item.tech_date }}</span>-->
<!--                                </v-tooltip>-->

                              </template>
                              <template v-slot:item.act_confirm="{ item }">
<!--                                <v-tooltip top>-->
<!--                                  <template v-slot:activator="{ on, attrs }">-->
<!--                                    <div v-bind="attrs" v-on="on">-->
                                      <v-simple-checkbox
                                        :disabled="item.act_confirm || item.acts.length === 0"
                                        v-model="item.act_confirm"
                                        @click="objectConfirmation(item, 'act')"
                                      ></v-simple-checkbox>
<!--                                      <v-checkbox v-else disabled v-model="item.act_confirm"></v-checkbox>-->

<!--                                    </div>-->
<!--                                  </template>-->
<!--                                  <span>{{ item.tech_name }} {{ item.tech_date }}</span>-->
<!--                                </v-tooltip>-->
                              </template>

                            </v-data-table>
<!--                          </v-card-text>-->
<!--                        </v-card>-->

                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="1">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headersComments"
                        :items="item.comments"
                        :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                        :items-per-page="-1"

                        dense
                        mobile-breakpoint="0"
                        class="elevation-1"
                      >

                        <template v-slot:item.files="{ item }">
                          <div class="d-flex justify-start">
                            <div class="" v-for="f_path in item.files" :key="f_path">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-bind="attrs"
                                      v-on="on"

                                      target="_blank"
                                      :href="downloadFile(f_path)"

                                      icon
                                      small
                                    >
                                      <v-icon dark>mdi-file-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ f_path }}</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </template>

                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item key="2">
                      <v-card-title>
                        <v-icon color="primary">mdi-compare-remove</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headersHistory"
                        :items="item.history"
                        :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                        :items-per-page="-1"

                        dense
                        mobile-breakpoint="0"
                        class="elevation-1"
                      ></v-data-table>
                    </v-tab-item>

                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-container>
          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Service',

  data() {
    return {
      overlay: false,
      load: false,
      loadObjects: false,

      tickets: [],

      contracts: [],
      contract_selected: null,

      zoho_tickets: [],
      zoho_ticket_selected: null,

      production_orders: [],
      production_order_selected: null,

      objects: [],
      objects_selected: [],

      existed_objects: [],
      existed_object_selected: [],

      reason_file: null,
      ticket_id: null,

      emails: [],
      email_selected: null,
      unknown_email: null,

      expanded: [],

      dialogObjects: false,
      dialogCheckout: false,

      dialogComment: false,
      editedComment: {text: ''},

      dialogAct: false,
      editedAct: {},

      comment_files: [],
      act_files: [],

      rules: [v => 13 <= v.length || 'Min 13 characters'],

      cart: {service: [], },
      filter: '',

      tab: null,
      tabs: [
        { id: 1, name: 'Objects', icon: 'mdi-car-outline' },
        { id: 2, name: 'Comments', icon: 'mdi-forum-outline'},
        { id: 3, name: 'History', icon: 'mdi-compare-remove' },
      ],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },
      sortBy: '-id',
      sortDesc: false,
      totalTickets: 0,

      headers: [
        { text: 'id', value: 'id' },
        { text: 'datetime', value: 'date_create', sortable: false, align: ' d-none d-md-table-cell' },

        { text: 'contract', value: 'contract_name', sortable: false },  // , align: 'center'
//        { text: 'source', value: 'source_info', sortable: false },

        { text: 'ZOHO_file', value: 'zoho_file', sortable: false, align: ' d-none d-md-table-cell' },
        { text: 'UK_prod_order', value: 'prod_order', width: '10%', sortable: false, align: ' d-none d-md-table-cell' },
        { text: 'UK_file', value: 'uk_file', sortable: false, align: ' d-none d-md-table-cell' },

        { text: 'srvc_ch', value: 'service_chief_confirm', sortable: false, align: ' d-none d-md-table-cell' },

        { text: 'comment(s)', value: 'comment', sortable: false },

        { text: 'mngr', value: 'manager_confirm', sortable: false, align: ' d-none d-md-table-cell' },
        { text: 'mngr_ch', value: 'manager_chief_confirm', sortable: false, align: ' d-none d-md-table-cell' },

        { text: 'tech_ch', value: 'tech_chief_confirm', sortable: false, align: ' d-none d-md-table-cell' },
        { text: 'comm_ch', value: 'commercial_chief_confirm', sortable: false, align: ' d-none d-md-table-cell' },

        { text: 'complete', value: 'complete', sortable: false, align: ' d-none d-md-table-cell' },

        { text: 'objs', value: 'data-table-expand', sortable: false },

      ],
      headersObjects: [
        { text: 'id', value: 'id' },
        { text: 'name', value: 'name' },
        { text: 'imei', value: 'imei' },

        { text: 'psd', value: 'paused' },
        { text: 'dut', value: 'dut' },
        { text: 'drt', value: 'drt' },
        { text: 'can', value: 'can' },
        { text: 'sim', value: 'sim' },

        { text: 'phone 1', value: 'phone_1' },
        { text: 'phone 2', value: 'phone_2' },

        { text: 'cost', value: 'cost' },

        { text: 'last data', value: 'last' },
      ],

      headersTicket: [
        { text: 'id', value: 'id', align: ' d-none d-md-table-cell' },

        { text: 'name', value: 'name' },
        { text: 'imei', value: 'imei' },

        { text: 'comment(s)', value: 'comment', sortable: false },

        { text: 'work conf', value: 'work_confirm', align: ' d-none d-md-table-cell' },

        { text: 'act file(s)', value: 'acts', sortable: false },
        { text: 'act conf', value: 'act_confirm', align: ' d-none d-md-table-cell' },

//        { text: 'phone 1', value: 'phone_1', width: '15%' },
//        { text: 'phone 2', value: 'phone_2', width: '15%' },
//
//        { text: 'last data', value: 'last', width: '15%' },

      ],
      headersComments: [
        { text: 'id', value: 'id' },
        { text: 'datetime', value: 'date' },
        { text: 'creator', value: 'user' },

        { text: 'object name', value: 'obj_name' },

        { text: 'text', value: 'text' },
        { text: 'file(s)', value: 'files' },

      ],
      headersHistory: [
        { text: 'id', value: 'id' },
        { text: 'datetime', value: 'date' },
        { text: 'user', value: 'user' },
        { text: 'stage', value: 'role' },
        { text: 'action', value: 'action' },
      ],
    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_service/',

        params: {
          contract: this.contract_selected,
          existed_object: this.existed_object_selected,

          pagination: this.options,
        },
        data: {},
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.contracts = response.data.contracts;
        self.zoho_tickets = response.data.zoho_tickets;
        self.production_orders = response.data.production_orders;

        self.existed_objects = response.data.existed_objects;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    getObjects() {
      let self = this;
      self.loadObjects = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/objects/',

        params: {contract_expand_id: self.contract_selected, },
        data: {},
      })
      .then(function (response) {
        self.objects = response.data.objects;
        self.emails = response.data.emails;

        self.loadObjects = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveCart() {
      let self = this;
      self.load = true;

      self.overlay = true;
      self.dialogCheckout = false;

      this.$http({
        method: 'post',
        url: 'ticket_api/save_new_ticket/',

        params: {
          contract: this.contract_selected,

          pagination: this.options,
        },
        data: {
          cart: this.cart,

          allowed_email: this.email_selected,
          unknown_email: this.unknown_email,

          zoho_ticket: this.zoho_ticket_selected,

          contract_id: this.contract_selected,

          method: 'create_service',
          action: 'service create',

        },
      })
      .then(function (response) {
        self.ticket_id = response.data.new_ticket_id;
        console.log('new_ticket_id from server:', response.data.new_ticket_id)
//
        self.cart = {service: [], }

//        self.saveFile();
        self.getData();

        self.dialogCheckout = false;
        self.overlay = false;
        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveFile() {
      console.log('ticket_id in saveFile:', this.ticket_id)

      let self = this;
      self.load = true;
      self.overlay = true;

      let formData = new FormData();

      formData.append('ticket_id', this.ticket_id);
      formData.append('ticket_type', 'service');

      formData.append('file', this.reason_file, this.reason_file.name);
      formData.append('file_name', this.reason_file.name);

//      formData.append('method', 'save_service_file');
//      formData.append('action', 'file service save');

      this.$http({
        method: 'post',
        url: 'ticket_api/save_reason_file/',

        params: {
          contract_id: this.contract_selected,

          pagination: this.options,

        },
        data: formData,
      })
      .then(function (response) {
//        self.tickets = response.data.tickets;
//        self.contracts = response.data.contracts;
        console.log('file save success:', response.data.success)

//        self.sendManagerEmail();


//        self.dialogCheckout = false;
//
//        self.load = false;
//        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

//    sendManagerEmail() {
//      console.log('ticket_id in sendManagerEmail:', this.ticket_id)
//
////      let self = this;
////      self.load = true;
////      self.overlay = true;
//
//      this.$http({
//        method: 'post',
//        url: 'ticket_api/send_manager_email/',
//
//        params: {},
//        data: {
//          ticket_id: this.ticket_id,
//        },
//      })
//      .then(function (response) {
//        console.log('send email success:', response)
//
////        self.dialogCheckout = false;
////
////        self.load = false;
////        self.overlay = false;
//      })
//      .catch(function (error) {
//        console.log(error);
//      })
//    },
//

    addObj(objAction) {
      let self = this;
      var props = [
          'id', 'imei', 'name',
          'paused', 'dut', 'drt', 'can', 'sim',
          'phone_1', 'phone_2',
          'cost', 'creator', 'last'];

      var result = self.objects_selected.filter(function(o1){
          return !objAction.some(function(o2){
              return o1.id === o2.id;
          });
      }).map(function(o){
          return props.reduce(function(newo, name){
              newo[name] = o[name];
              return newo;
          }, {});
      });

      objAction.push.apply(objAction, result)

      this.objects_selected = [];
      console.log('cart:', this.cart);

    },

    removeObj(index, objAction) {
      objAction.splice(index, 1);
    },

    checkoutCart() {
      this.dialogObjects = false;
      this.dialogCheckout = true;
    },

    downloadPDF(item) {
//      switch (process.env.NODE_ENV) {
//    //    case 'production': return '/ProductionEnv/'
//        case 'development': return ':8000'
//        default: return ''
//      }

      return 'core_api/get_pdf_file/' + item.file_path
    },

    downloadFile(path) {
      return 'core_api/get_pdf_file/' + path
    },

    ticketConfirmation(item, stage) {
      console.log('item', item);
      console.log('stage', stage);

      let self = this;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_service/',

        params: {
          contract: this.contract_selected,
          existed_object: this.existed_object_selected,

          pagination: this.options,

        },
        data: {
//          obj: item,

          ticket_id: item.id,
//          object_id: item.id,

          stage: stage,
          prod_order: item.production_order_selected,

          action: 'ticket confirm',
          method: 'confirm_ticket',
        },
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.contracts = response.data.contracts;
        self.zoho_tickets = response.data.zoho_tickets;
        self.production_orders = response.data.production_orders;

        self.existed_objects = response.data.existed_objects;

        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    objectConfirmation(item, stage) {
      console.log('item', item);
      console.log('stage', stage);
      console.log('this.expanded[0].id', this.expanded[0].id);

      let self = this;
      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_service/',

        params: {
          contract: this.contract_selected,
          existed_object: this.existed_object_selected,

          pagination: this.options,

        },
        data: {
          obj: item,

          ticket_id: this.expanded[0].id,
          object_id: item.id,

          stage: stage,

          action: 'object confirm',
          method: 'confirm_object',
        },
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.contracts = response.data.contracts;
        self.zoho_tickets = response.data.zoho_tickets;
        self.production_orders = response.data.production_orders;

        self.existed_objects = response.data.existed_objects;

        self.load = false;
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    addComment(item, scope) {
//      console.log('scope:', scope)
//      console.log('objest item id:', item.id);
//      console.log('expanded ticket id:', this.expanded[0].id);

      let self = this;
      self.load = true;

      self.editedComment.text = '';
//      self.editedComment.ticket_id = item.id;
      if (scope === 'ticket') {
        self.editedComment.ticket_id = item.id;
        self.editedComment.object_id = '0';
      }
      else {
        self.editedComment.ticket_id = this.expanded[0].id;
        self.editedComment.object_id = item.id;
      }
//      console.log('ticket_id:', self.editedComment.ticket_id, '; object_id:', self.editedComment.object_id)

      self.dialogComment = true;
    },

    saveComment() {
      let self = this;

      self.load = true;
      self.overlay = true;
      self.dialogComment = false;

      console.log('editedComment:', this.editedComment);
      console.log('comment_files:', this.comment_files);

      let formData = new FormData();

      formData.append('ticket_id', this.editedComment.ticket_id);
      formData.append('object_id', this.editedComment.object_id);

      formData.append('text', this.editedComment.text);


//      formData.append('files', this.comment_files);
      for (let i = 0; i < this.comment_files.length; i++) {
          formData.append('file', this.comment_files[i], this.comment_files[i].name);
      }

      formData.append('method', 'add_comment');
      formData.append('action', 'comment add');

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_service/',

        headers: {'Content-Type': 'multipart/form-data'},
        params: {
          contract: this.contract_selected,

          pagination: this.options,
        },
        data: formData,
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.contracts = response.data.contracts;
        self.zoho_tickets = response.data.zoho_tickets;
        self.production_orders = response.data.production_orders;

//        self.totalTickets = response.data.tickets_count;
//
//        self.contract_filters = response.data.contract_filters;
//        self.complete_filters = response.data.complete_filters;

//        self.manager_filters = response.data.manager_filters;
//        self.tech_filters = response.data.tech_filters;
//        self.sim_filters = response.data.sim_filters;

        self.user_role = response.data.user_role;

        self.load = false;
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    addAct(item) {
      let self = this;

      self.editedAct.ticket_id = this.expanded[0].id;
      self.editedAct.object_id = item.id;

      self.dialogAct = true;
    },

    saveAct() {
      let self = this;

      self.overlay = true;
      self.dialogAct = false;

      console.log('editedAct:', this.editedAct);
      console.log('act_files:', this.act_files);

      let formData = new FormData();

      formData.append('ticket_id', this.editedAct.ticket_id);
      formData.append('object_id', this.editedAct.object_id);

      for (let i = 0; i < this.act_files.length; i++) {
          formData.append('file', this.act_files[i], this.act_files[i].name);
      }

      formData.append('method', 'add_act');
      formData.append('action', 'act add');

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_service/',

        headers: {'Content-Type': 'multipart/form-data'},
        params: {
          contract: this.contract_selected,

          pagination: this.options,
        },
        data: formData,
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.contracts = response.data.contracts;
        self.zoho_tickets = response.data.zoho_tickets;
        self.production_orders = response.data.production_orders;

        self.user_role = response.data.user_role;

        self.load = false;
        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    close() {
      this.dialogObjects = false;
      this.dialogCheckout = false;
      this.dialogComment = false;
      this.dialogAct = false;

      this.objects_selected = [];
      this.cart = {service: [], };
    },

    cancelCheckout() {
      this.dialogObjects = true;
      this.dialogCheckout = false;
    },
  },

  computed: {
    cartQty() {
        return this.cart.service.length
    },

  },

  watch: {
    comment_files: function() {
        console.log('comment_files', this.comment_files)
    },

    zoho_ticket_selected: function() {
      console.log('zoho ticket select', this.zoho_ticket_selected);
      this.unknown_email = this.zoho_ticket_selected.email
    },

    contract_selected: function() {
      this.getData();
    },

    existed_object_selected: function() {
      this.getData();
    },

    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

  },


}
</script>

<style scoped>

</style>
