<template>
  <div class="search">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>

        <v-flex xs1 lg1 xl1>
        <v-autocomplete
          v-model="type_selected"
          :items="search_types"
          item-text="name"
          item-value="id"
          label="Type"
          prepend-icon="mdi-text-search"
          class="px-2"
        ></v-autocomplete>
        </v-flex>

        <v-flex xs3 lg3 xl3>
          <v-text-field
            v-model="text"
            label="Search text"
            single-line
            dense
            outlined
            hide-details
            class="px-2"
          ></v-text-field>
        </v-flex>

        <v-flex xs1 lg1 xl1>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="goSearch()"
                  :disabled="!text || !type_selected"
                  color="primary"
                  depressed
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Let's go Search!</span>
        </v-tooltip>
        </v-flex>

      </v-card-title>

      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="results"

        :loading="load"
        loading-text="Search results loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.psd="{ item }">
          <v-icon v-if="item.psd">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.dut="{ item }">
          <v-icon v-if="item.dut">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.drt="{ item }">
          <v-icon v-if="item.drt">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.can="{ item }">
          <v-icon v-if="item.can">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.sim="{ item }">
          <v-icon v-if="item.sim">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Search',

    data() {
      return {

        search_types: [],
        type_selected: 1,

        text: '',

        results: [],

        overlay: false,
        load: false,

        headers: [

          { text: 'id', value: 'id' },
          { text: 'source', value: 'source' },
          { text: 'contract', value: 'contract' },

          { text: 'creator', value: 'creator' },

          { text: 'name', value: 'name' },
          { text: 'imei', value: 'imei' },
          { text: 'phone 1', value: 'phone_1' },
          { text: 'phone 2', value: 'phone_2' },

          { text: 'psd', value: 'psd' },

//          { text: 'dut', value: 'dut' },
//          { text: 'drt', value: 'drt' },
//          { text: 'can', value: 'can' },
//          { text: 'sim', value: 'sim' },

          { text: 'last', value: 'last' },

//          { text: 'cost', value: '' },

        ],

      }
    },

    created() {
      this.getData();
    },

    mounted() {},

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'search_api/search/',

          params: {},
          data: {},
        })
        .then(function (response) {
          self.search_types = response.data.types;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      goSearch() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'post',
          url: 'search_api/search/',

          params: {},
          data: {
            type: this.type_selected,
            text: this.text,

          },
        })
        .then(function (response) {
          self.results = response.data.results;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },
    },

    computed: {},

    watch: {},

  }
</script>

<style scoped>

</style>
