<template>
  <div class="tools">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card>

      <v-card-title>

        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-flex xs3 lg3 xl3 class="d-flex justify-space-around">
          <v-card outlined rounded min-width="155" class="d-flex justify-space-between pa-2">
            <v-card
              flat
              style="position: absolute; color: darkgray"
              class="text-caption my-n5"
            >
              <pre> UK Orders </pre>
            </v-card>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    :disabled="
                      contractsSelected.length === 0 ||
                      contractsSelected.filter(item => item.order_id === null).length !== contractsSelected.length
                    "
                    @click="createOrders()"
                    color="primary"
                    depressed
                  >
                    <v-icon dark>mdi-book-plus</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Orders Create</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    :disabled="
                      contractsSelected.length === 0 ||
                      contractsSelected.filter(item => item.order_number !== null).length !== contractsSelected.length
                    "
                    @click="sendOrders()"
                    color="primary"
                    depressed
                  >
                    <v-icon dark>mdi-email-fast</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Orders Send (single)</span>
            </v-tooltip>
          </v-card>
          <v-card outlined rounded min-width="155" class="d-flex justify-space-between pa-2">
            <v-card
              flat
              style="position: absolute; color: darkgray"
              class="text-caption my-n5"
            >
              <pre> MKTU Acts </pre>
            </v-card>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    :disabled="
                      contractsSelected.length === 0 || current_year_month < picker ||
                      contractsSelected.filter(item => item.order_number !== null).length !== contractsSelected.length ||
                      contractsSelected.filter(item => item.act_number === null).length !== contractsSelected.length
                    "
                    @click="createActs()"
                    color="primary"
                    depressed
                  >
                    <v-icon dark>mdi-book-plus-outline</v-icon>
                  </v-btn>
                </div>
              </template>
            <span>Acts Create</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    :disabled="
                      contractsSelected.length === 0 ||
                      contractsSelected.filter(item => item.act_number !== null).length !== contractsSelected.length
                    "
                    @click="sendActs()"
                    color="primary"
                    depressed
                  >
                    <v-icon dark>mdi-email-fast-outline</v-icon>
                  </v-btn>
                </div>
              </template>
            <span>Acts Send (single)</span>
            </v-tooltip>
          </v-card>

        </v-flex>
        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="picker"
                label="Month"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              @input="menu = false"
              type="month"
              min="2021-09"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <!--Method, Type, Hoster-->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="method_selected"
            :items="methods"
            item-text="name"
            item-value="id"
            label="Method"
            clearable
            prepend-icon="mdi-bank-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="type_selected"
            :items="types"
            item-text="name"
            item-value="id"
            label="Type"
            clearable
            prepend-icon="mdi-calendar"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="hosters_selected"
            :items="hosters"
            item-text="name"
            item-value="id"
            label="Hoster"
            clearable
            prepend-icon="mdi-laptop"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="order_type_selected"
            :items="order_types"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Mark"
            prepend-icon="mdi-bookmark-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="contractsSelected"
        :headers="headers"
        :items="data_set"
        item-key="row_id"
        show-select

        :loading="load"
        loading-text="Tools loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.is_primary="{ item }">
<!--          <v-tooltip top>-->
<!--            <template v-slot:activator="{ on, attrs }">-->

<!--          v-bind="attrs" v-on="on"-->
              <div>
                <v-icon v-if="item.is_primary === true">mdi-check</v-icon>
                <v-icon v-else-if="item.is_primary === false">mdi-window-close</v-icon>
                <v-icon v-else></v-icon>
              </div>
<!--            </template>-->
<!--            <span>{{ item.is_primary }}</span>-->
<!--          </v-tooltip>-->
        </template>

        <template v-slot:item.contract_name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.contract_name }}
              </div>
            </template>
            <span>{{ item.contract_info }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.equal_info="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="item.is_equal"
                color="green darken-2"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-table-check
              </v-icon>
              <v-icon v-else
                color="red darken-2"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-table-cancel
              </v-icon>
            </template>
            <span>{{ item.equal_info }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.order="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn v-if="item.order_number"
                  icon
                  small

                  class="ma-2"
                  :href="downloadOrder(item)"
                  target="_blank"
                >
                  <v-icon dark>mdi-file-eye</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ item.order_name }}</span>
          </v-tooltip>

        </template>
        <template v-slot:item.order_send="{ item }">
          <v-icon v-if="item.order_number && item.order_send"


          >
            mdi-check
          </v-icon>
          <v-icon v-if="item.order_number && !item.order_send"


          >
            mdi-window-close
          </v-icon>
        </template>

        <template v-slot:item.act="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn v-if="item.act_number"
            icon
            small

            class="ma-2"
            :href="downloadAct(item)"
            target="_blank"
          >
            <v-icon dark>mdi-file-eye</v-icon>
          </v-btn>
              </div>
            </template>
            <span>{{ item.act_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.act_send="{ item }">
          <v-icon v-if="item.act_number && item.act_send">mdi-check</v-icon>
          <v-icon v-if="item.act_number && !item.act_send">mdi-window-close</v-icon>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'OrdersTools',

    data() {
      return {
        data_set: [],

        types: [],
        methods: [],
        hosters: [],

        contracts: [],
        order_types: [],

        type_selected: null,
        method_selected: null,
        hosters_selected: null,

        contract_selected: null,
        order_type_selected: null,

        contractsSelected: [],

        menu: false,
        picker: new Date().toISOString().substr(0, 7),

        overlay: false,
        load: false,

        headers: [
          { text: 'name', value: 'contract_name' },
          { text: 'o_number', value: 'order_number' },
          { text: 'primary', value: 'is_primary' },

          { text: 'o_create', value: 'order_create' },
          { text: 'o_pdf', value: 'order' },
          { text: 'o_send', value: 'order_send' },

          { text: 'a_number', value: 'act_number' },
          { text: 'a_create', value: 'act_create' },
          { text: 'a_pdf', value: 'act' },
          { text: 'a_send', value: 'act_send' },

        ],

      }
    },

    created() {},

    mounted() {
        this.getData();
    },

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'tool_api/tools/',

          params: {
            date: this.picker,
            type: this.type_selected,
            method: this.method_selected,
            hosters: this.hosters_selected,

            contract_id: this.contract_selected,
            order_type: this.order_type_selected,

          },
          data: {},
        })
        .then(function (response){
          self.data_set = response.data.data_set;
          self.types = response.data.types;
          self.methods = response.data.methods;
          self.hosters = response.data.hosters;

          self.contracts = response.data.contracts;
          self.order_types = response.data.order_types;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },


      createOrders() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'tool_api/tools/',

          params: {
            date: this.picker,
            type: this.type_selected,
            method: this.method_selected,
            hosters: this.hosters_selected,

            contract_id: this.contract_selected,
            order_type: this.order_type_selected,

          },
          data: {
            method: 'create_orders',
            action: 'orders create',
//            'selected': this.contractsSelected,
            selected_act_ids: Array.from(this.contractsSelected, x => x.act_id),
            selected_order_ids: Array.from(this.contractsSelected, x => x.order_id),
            selected_contract_ids: Array.from(this.contractsSelected, x => x.contract_id),
          },
        })
        .then(function (response){
          self.data_set = response.data.data_set;
          self.types = response.data.types;
          self.methods = response.data.methods;
          self.hosters = response.data.hosters;

          self.overlay = false;
          self.contractsSelected = [];
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      sendOrders() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'tool_api/tools/',

          params: {
            date: this.picker,
            type: this.type_selected,
            method: this.method_selected,
            hosters: this.hosters_selected,

            contract_id: this.contract_selected,
            order_type: this.order_type_selected,

          },
          data: {
            method: 'send_orders',
            action: 'orders send',
//            'selected': this.contractsSelected,
            selected_act_ids: Array.from(this.contractsSelected, x => x.act_id),
            selected_order_ids: Array.from(this.contractsSelected, x => x.order_id),
            selected_contract_ids: Array.from(this.contractsSelected, x => x.contract_id),
          },
        })
        .then(function (response){
          self.data_set = response.data.data_set;
          self.types = response.data.types;
          self.methods = response.data.methods;
          self.hosters = response.data.hosters;

          self.overlay = false;
          self.contractsSelected = [];
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      downloadOrder(item) {
        return 'core_api/get_pdf_file/orders_pdf/'+item.order_date_create+'/'+item.order_name
      },


      createActs() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'tool_api/tools/',

          params: {
            date: this.picker,
            type: this.type_selected,
            method: this.method_selected,
            hosters: this.hosters_selected,

            contract_id: this.contract_selected,
            order_type: this.order_type_selected,

          },
          data: {
            method: 'create_acts_mktu',
            action: 'acts create',
//            'selected': this.contractsSelected,
            selected_act_ids: Array.from(this.contractsSelected, x => x.act_id),
            selected_order_ids: Array.from(this.contractsSelected, x => x.order_id),
            selected_contract_ids: Array.from(this.contractsSelected, x => x.contract_id),
          },
        })
        .then(function (response){
          self.data_set = response.data.data_set;
          self.types = response.data.types;
          self.methods = response.data.methods;
          self.hosters = response.data.hosters;

          self.overlay = false;
          self.contractsSelected = [];
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      sendActs() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'tool_api/tools/',

          params: {
            date: this.picker,
            type: this.type_selected,
            method: this.method_selected,
            hosters: this.hosters_selected,

            contract_id: this.contract_selected,
            order_type: this.order_type_selected,

          },
          data: {
            method: 'send_acts',
            action: 'acts send',

//            'selected': this.contractsSelected,
            selected_act_ids: Array.from(this.contractsSelected, x => x.act_id),
            selected_order_ids: Array.from(this.contractsSelected, x => x.order_id),
            selected_contract_ids: Array.from(this.contractsSelected, x => x.contract_id),
          },
        })
        .then(function (response){
          self.data_set = response.data.data_set;
          self.types = response.data.types;
          self.methods = response.data.methods;
          self.hosters = response.data.hosters;

          self.overlay = false;
          self.contractsSelected = [];
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      downloadAct(item) {
        return 'core_api/get_pdf_file/acts_pdf/'+item.act_create+'/'+item.act_name
      },

      downloadSummary() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'get',
          url: 'core_api/get_xlsx_summary/',
          responseType: 'blob',

          params: {
            date: this.picker,
            contract_id: this.contractsSelected[0].contract_id,
          },
          data: {},
        })
        .then(function (response){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const fileName = 'summary' + self.contractsSelected[0].contract_name + self.picker + '.xlsx';

          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          self.contractsSelected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

    },

    computed: {
      current_year_month() {
        return new Date().toISOString().substr(0, 7)
      },

    },

    watch: {
      picker: function() {
          this.contractsSelected = [];
          this.getData();
        },

      type_selected: function() {
          this.contractsSelected = [];
          this.getData();
        },
      method_selected: function() {
        this.contractsSelected = [];
        this.getData();
      },
      hosters_selected: function() {
        this.contractsSelected = [];
        this.getData();
      },

      contract_selected: function() {
        this.contractsSelected = [];
        this.getData();
      },
      order_type_selected: function() {
        this.contractsSelected = [];
        this.getData();
      },

      contractsSelected: function() {
//          sconsole.log(this.contractsSelected.length);
//          console.log(this.contractsSelected);

//          console.log('act ids: ', Array.from(this.contractsSelected, x => x.act_id));
//          console.log('order ids: ', Array.from(this.contractsSelected, x => x.order_id));
//          console.log('contract ids: ', Array.from(this.contractsSelected, x => x.contract_id));

//          console.log('create: ', this.contractsSelected.filter(item => item.order_id !== null))
//          console.log('contract ids: ', this.contractsSelected.filter(x => x.contract_id !== null));
//          console.log('order ids: ', this.contractsSelected.filter(x => x.order_id !== null));
//          console.log('act ids: ', this.contractsSelected.filter(x => x.act_id !== null));
        },

    },

  }
</script>

<style scoped>
  /*.v-dialog .v-card__title {*/
  /*  background-color: #2E7D32;*/
  /*}*/
</style>
