<template>
  <div class="home" style="margin-top: 10em">

    <v-row justify="center">
<!--        max-width="400px"-->
<!--        max-height="400px"-->
      <v-img v-if="!user_info || user_info.name === 'anonymous_user'"
        src="../assets/VaultBoySet_004.png"
        :max-width="sizeCalc['img_size']"
        alt="Whassup"
      ></v-img>
      <v-img v-else
        src="../assets/VaultBoySet_028.png"
        :max-width="sizeCalc['img_size']"
        alt="Billy Boy Welcome"
      ></v-img>
    </v-row>

    <v-row class="text-center">
      <v-col class="mb-1">
        <div v-if="user_info.name !== 'anonymous_user'" :class="`${sizeCalc['text_class']} font-weight-bold mb-3`">
          {{ $t('main.welcome') }}, {{ user_info.first_name }}
        </div>

<!--        <div v-else :class="`text-${$vuetify.breakpoint.name}-h3 font-weight-bold mb-3`">-->
        <div v-else class="display-2 font-weight-bold mb-3">
          {{ $t('main.whassup') }}, {{ user_info.first_name }}?
        </div>
        <p class="subheading font-weight-regular">{{ $t('main.sign') }}</p>
      </v-col>
    </v-row>

  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',

//  components: {},

  data() {
    return {
      user_info: {
        name: 'anonymous_user',
        first_name: 'Anonymous',

      }
    }
  },

  created() {
    if (localStorage.getItem('user_info')) {
      this.user_info = JSON.parse(localStorage.user_info);
//      console.log('user_info in home:', this.user_info)
    }

  },

    computed: {
      sizeCalc() {
        let img_size = ''
        let text_class = ''

        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            img_size = '192px';
            text_class = 'headline';
            break;
          case 'sm':
            img_size = '272px';
            text_class = 'display-1';
            break;
          case 'md':
            img_size = '352px';
            text_class = 'display-1';
            break;
          case 'lg':
            img_size = '432px';
            text_class = 'display-2';
            break;
          case 'xl':
            img_size = '512px';
            text_class = 'display-2';
            break;
        }
        return {img_size: img_size, text_class: text_class}
      },
    }

}
</script>
