<template>
  <div class="expired_contracts">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogExContract" max-width="500px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>{{ formExContractTitle }}</span>
        </v-card-title>

        <v-card>

          <v-card-text>
            <v-container>
              <v-layout row wrap>

                  <v-flex xs12>
                    <v-autocomplete
                      v-model="editedExContract.contract_id"
                      :items="contracts_billy"
                      item-text="name"
                      item-value="id"
                      label="contract"
                      required
                      :disabled="this.editedExContractIndex !== -1"
                      :rules="[rules.required]"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12>
                    <v-autocomplete
                      v-model="editedExContract.mktu_id"
                      :items="raw_contracts_mktu"
                      item-text="name"
                      item-value="id"
                      label="contract_mktu"
                      required
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12>
                    <v-autocomplete
                      v-model="editedExContract.uk_id"
                      :items="raw_contracts_uk"
                      item-text="name"
                      item-value="id"
                      label="contract_uk"
                      required
                      :rules="[rules.required]"
                    ></v-autocomplete>
                  </v-flex>

              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn text @click="saveExContract(editedExContract)" :disabled="!dialogRules" color="green darken-3">save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>

      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                @click="newExContract()"
                color="primary"
                depressed
              >
                <v-icon>mdi-text-box-plus-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Expired Contract</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <!--Filter-->
        <v-flex xs3 lg3 xl3>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts_with_ex"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="ex_contracts"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon @click="editExContract(item)" class="mr-2">mdi-pencil</v-icon>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  import Vue from "vue";

  export default {
    name: 'PreviousRawContracts',

    data() {
      return {
        ex_contracts: [],

        raw_contracts_uk: [],
        raw_contracts_mktu: [],

        contracts_billy: [],
        contracts_with_ex: [],
        contract_selected: [],

//        contracts: [],
//        contract_selected: [],

        load: false,
        overlay: false,

        dialogExContract: false,
        editedExContract: {},
        defaultExContract: {},
        editedExContractIndex: -1,

        headers: [
          { text: 'id', value: 'id' },
          { text: 'contract', value: 'name' },

          { text: 'mktu', value: 'mktu_name' },
          { text: 'uk', value: 'uk_name' },

          { text: 'expired', value: 'date_expired' },

          { text: 'edit', value: 'action', sortable: false },

        ],

        rules: {
          required: value => !!value || 'required!',
//          number: value => value.length >= 19 || 'short number!',
//          email: value => {
//            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//            return pattern.test(value) || 'invalid email!'
//          },
        },

      }
    },

    created() {
      this.getData();
    },

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'core_api/expired_contracts/',

          params: {
            contract: this.contract_selected,
          },
          data: {},
        })
        .then(function (response){
          self.ex_contracts = response.data.ex_contracts;

          self.raw_contracts_uk = response.data.raw_contracts_uk;
          self.raw_contracts_mktu = response.data.raw_contracts_mktu;

          self.contracts_billy = response.data.contracts_billy;
          self.contracts_with_ex = response.data.contracts_with_ex;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      newExContract() {
        Vue.set(this, 'editedExContract', Object.assign({}, this.defaultExContract));
        this.dialogExContract = true;
      },

      editExContract(item) {
        this.editedExContractIndex = this.ex_contracts.indexOf(item);
        this.editedExContract = Object.assign({}, item);
//        console.log('item:', item);
        this.dialogExContract = true;
      },

      saveExContract(item) {
        const self = this;

        self.dialogExContract = false;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'core_api/expired_contracts/',

          params: {
            contract: this.contract_selected,
          },
          data: {
            id: item.id,

            contract_id: item.contract_id,

            uk_raw_id: item.uk_id,
            mktu_raw_id: item.mktu_id,

            action: item.id ? 'edit' : 'create',
            method: 'action_excontract'

          },
        })
        .then(function (response) {
          self.ex_contracts = response.data.ex_contracts;

          self.editedExContract = {};
          self.editedExContractIndex = -1;

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      close() {
        this.dialogExContract = false;
        this.editedExContractIndex = -1;
      },

    },

    computed: {
      formExContractTitle() {
        return this.editedExContractIndex === -1 ? 'New ExContract' : 'Edit ExContract'
      },

      dialogRules() {
        return this.editedExContract.contract_id && this.editedExContract.uk_id
      },

    },

    watch: {
      contract_selected: function() {
//        this.options.page = 1;
        this.getData();
      },

    },

  }
</script>

<style scoped>

</style>
