<template>
  <div class="orders">

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogExtra" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New Order</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <!-- contract, date, time -->
              <v-layout row wrap>
                <v-flex xs6>
                  <v-autocomplete
                    v-model="editedExtra.contract_id"
                    :items="contracts"
                    item-text="name"
                    item-value="id"
                    label="contract"
                    required
                    :rules="nonEmpty"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs2>
                  <v-menu
                    v-model="menuPeriodExtra"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedExtra.period"
                        label="period order"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        disabled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedExtra.period"
                      @input="menuPeriodExtra = false"
                      type="month"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs2>
                  <v-menu
                    v-model="menuPickerExtra"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedExtra.date"
                        label="date create"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        disabled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedExtra.date"
                      @input="menuPickerExtra = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs2>
                  <v-menu
                    v-model="menuTimeExtra"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedExtra.time"
                        label="time create"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        disabled
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="editedExtra.time"
                      @input="menuTimeExtra = false"
                      format="24hr"
                      landscape
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <!-- product, qty, cost -->
              <v-layout row wrap
                v-for="(extra, index) in editedExtra.products_list"
                :key="`extra-${index}`"
              >
                <v-flex xs7>
                  <v-autocomplete
                    v-model="extra.product_id"
                    :items="products"
                    item-text="name"
                    item-value="id"
                    label="product"
                    required
                    :rules="nonEmpty"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="extra.qty"
                    type="number"
                    label="quantity"
                    required
                    :rules="nonNegative"
                    prepend-icon="mdi-shopping-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2>
                  <v-text-field
                    v-model="extra.price"
                    type="number"
                    label="price"
                    required
                    :rules="nonNegative"
                    prepend-icon="mdi-hand-coin-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs1 class="align-self-center d-flex justify-center">
                  <v-icon @click="addProd(extra, editedExtra.products_list)">mdi-plus</v-icon>
                  <v-icon
                      v-show="editedExtra.products_list.length > 1"
                      @click="removeProd(index, editedExtra.products_list)"
                    >mdi-delete-outline
                  </v-icon>
                </v-flex>
              </v-layout>
              <!-- total cost -->
              <v-layout row wrap justify-space-around>
                <v-flex xs4>
                  <v-card-text style="font-size: medium">order total cost: {{ totalCostExtra }} uah</v-card-text>
                </v-flex>
              </v-layout>
              <v-divider class="ma-5"></v-divider>
              <!-- check boxes -->
              <v-layout row wrap justify-space-around>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.is_extra" label="is_extra"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.order_uk" disabled label="UK order"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.act_uk" disabled label="UK act"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.order_mktu" disabled label="MKTU order"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.act_mktu" disabled label="MKTU act"></v-checkbox>
                </v-flex>
                <v-flex xs2>
                  <v-checkbox v-model="editedExtra.send_order" label="send order"></v-checkbox>
                </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()">cancel</v-btn>
            <v-btn text @click="extraOrderCreate(editedExtra)" :disabled="!allRules" color="green darken-3">create</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="refreshOrders()"
                  color="primary"
                  depressed
                  :disabled = "orderSelected.length < 1 || orderSelected.some(elem => elem.number === '')"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Orders Refresh</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="sendOrders()"
                  color="primary"
                  depressed
                  :disabled = "unique_orders_len !== 1 || orderSelected.some(elem => elem.number === '')"
              >
                <v-icon>mdi-email-fast-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Orders Send (batch)</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="newExtraOrder()"
                  color="primary"
                  depressed
              >
                <v-icon>mdi-book-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Order</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="downloadReport()"
                  color="primary"
                  depressed
              >
                <v-icon>mdi-table-arrow-down</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Report Download</span>
        </v-tooltip>

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-menu
            v-model="menuPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="picker"
                label="Month"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              @input="menuPicker = false"
              type="month"
              min="2021-01"
            ></v-date-picker>
          </v-menu>
        </v-flex>

        <!--Method, Type, Hoster-->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="p_method_selected"
            :items="p_methods"
            item-text="name"
            item-value="id"
            label="Method"
            clearable
            prepend-icon="mdi-bank-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="p_type_selected"
            :items="p_types"
            item-text="name"
            item-value="id"
            label="Type"
            clearable
            prepend-icon="mdi-calendar"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="hosters_selected"
            :items="hosters"
            item-text="name"
            item-value="id"
            label="Hoster"
            clearable
            prepend-icon="mdi-laptop"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="block_selected"
            :items="blocks"
            item-text="name"
            item-value="id"
            hide-selected
            label="Block"
            clearable
            prepend-icon="mdi-pause"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="type_selected"
            :items="types"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Mark"
            prepend-icon="mdi-bookmark-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
              v-model="paid_selected"
              :items="paids"
              item-text="name"
              item-value="id"
              hide-selected
              clearable
              label="Paid"
              prepend-icon="mdi-scale-balance"
            ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="orderSelected"
        :headers="headers"
        :items="orders"
        show-select

        :options.sync="options"
        :server-items-length="totalOrders"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        :loading="load"
        loading-text="Orders loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.is_primary="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.is_primary">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.is_primary }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.mktu_uuid="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="text-truncate"
                style="max-width: 90px;"
              >
                {{ item.mktu_uuid }}
              </div>
            </template>
            <span>{{ item.mktu_uuid }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.uk_uuid="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="text-truncate"
                style="max-width: 90px;"
              >
                {{ item.uk_uuid }}
              </div>
            </template>
            <span>{{ item.uk_uuid }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.pdf="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn v-if="item.number"
                  icon
                  small

                  class="ma-2"
                  :href="downloadPDF(item)"
                  target="_blank"
                >
                  <v-icon dark>
                    mdi-file-eye
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ item.file_name }}</span>
          </v-tooltip>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Orders',

    data() {
      return {
        orders: [],

        overlay: false,
        load: false,

        contracts: [],
        contract_selected: null,

        orderSelected: [],

        types: [],
        type_selected: null,

        paids: [],
        paid_selected: null,

        p_types: [],
        p_methods: [],
        hosters: [],

        p_type_selected: null,
        p_method_selected: null,
        hosters_selected: null,

        blocks: [],
        block_selected: null,

        products: [],
        product_current_id: 0,

        picker: null,
        pickerExtraPeriod: null,

        menuPicker: false,

        menuPickerExtra: false,
        menuPeriodExtra: false,
        menuTimeExtra: false,

        dialogExtra: false,

        editedExtra: {products_list: [], },
        defaultExtra: {
          contract_id: null,

          date: new Date().toISOString().substr(0, 10),
          time: new Date().toLocaleTimeString('uk').substr(0, 5),
          period: new Date().toISOString().substr(0, 7),

          products_list: [],

          is_extra: true,
          order_uk: true,
          act_uk: true,
          order_mktu: true,
          send_order: true,

        },

        nonEmpty: [v => !!v || "required"],
        nonNegative: [v => parseInt(v)>0 || "required"],

        options: {
          'page': 1,
          'itemsPerPage': 10,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
        },
        sortBy: '-date_create',
        sortDesc: false,
        totalOrders: 0,

        headers: [
          { text: 'id', value: 'id' },

          { text: 'create', value: 'date_create' },
          { text: 'period', value: 'date_period' },
          { text: 'contract', value: 'contract' },

          { text: 'number', value: 'number' },
          { text: 'primary', value: 'is_primary' },

          { text: 'uk_uuid', value: 'uk_uuid' },
          { text: 'mktu_uuid', value: 'mktu_uuid' },

          { text: 'uk_pdf', value: 'pdf', sortable: false },

          { text: 'rows', value: 'product_rows', sortable: false },

//          { text: 'amount', value: 'total_cost', sortable: false },
          { text: 'amount', value: 'amount' },
          { text: 'payment', value: 'paid' },

//          { text: 'action', value: 'action', sortable: false },

        ],

      }
    },

    created() {},

    mounted() {
      this.getData();
    },

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'order_api/orders/',

          params: {
            date: this.picker,

            contract: this.contract_selected,
            type: this.type_selected,
            paid: this.paid_selected,

            p_type: this.p_type_selected,
            p_method: this.p_method_selected,
            hosters: this.hosters_selected,
            block: this.block_selected,

            pagination: this.options,
          },
          data: {},
        })
        .then(function (response){
          self.orders = response.data.orders;
          self.totalOrders = response.data.orders_count;

          self.contracts = response.data.contracts;
          self.products = response.data.products;
          self.types = response.data.types;
          self.paids = response.data.paids;
          self.blocks = response.data.blocks;

          self.p_types = response.data.p_types;
          self.p_methods = response.data.p_methods;
          self.hosters = response.data.hosters;

          self.product_current_id = response.data.product_current_id

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      refreshOrders() {
        let self = this;

        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'order_api/orders/',

          params: {
            date: this.picker,

            contract: this.contract_selected,
            type: this.type_selected,
            paid: this.paid_selected,

            p_type: this.p_type_selected,
            p_method: this.p_method_selected,
            hosters: this.hosters_selected,
            block: this.block_selected,

            pagination: this.options,
          },
          data: {
            method: 'refresh_orders',
            action: 'orders refresh',

            ids: Array.from(this.orderSelected, x => x.id),
          }
        })
        .then(function (response) {
          self.orders = response.data.orders;
          self.orderSelected = [];

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      sendOrders() {
        let self = this;

        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'order_api/orders/',

          params: {
            date: this.picker,

            contract: this.contract_selected,
            type: this.type_selected,
            paid: this.paid_selected,

            p_type: this.p_type_selected,
            p_method: this.p_method_selected,
            hosters: this.hosters_selected,
            block: this.block_selected,

            pagination: this.options,
          },
          data: {
            method: 'send_orders',
            action: 'orders send',

            ids: Array.from(this.orderSelected, x => x.id),

          }
        })
        .then(function (response) {
          self.orders = response.data.orders;
          self.orderSelected = [];

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      extraOrderCreate() {
        console.log('editedExtra:', this.editedExtra)

        let self = this;

        self.overlay = true;
        self.dialogExtra = false;

        this.$http({
          method: 'post',
          url: 'order_api/orders/',

          params: {
            date: this.picker,

            contract: this.contract_selected,
            type: this.type_selected,
            paid: this.paid_selected,

            p_type: this.p_type_selected,
            p_method: this.p_method_selected,
            hosters: this.hosters_selected,
            block: this.block_selected,

            pagination: this.options,
          },
          data: {
            extra_data: this.editedExtra,

            method: 'create_extra',
            action: 'extra create',
          }
        })
        .then(function (response) {
          self.orders = response.data.orders;
          self.totalOrders = response.data.orders_count;

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      newExtraOrder() {
        this.editedExtra = JSON.parse(JSON.stringify(this.defaultExtra));

        if (this.picker) {
          this.editedExtra.period = this.picker
        } else {
          this.editedExtra.period = new Date().toISOString().substr(0, 7)
        }
        this.editedExtra.contract_id = this.contract_selected;
        this.editedExtra.products_list.push({ product_id: this.product_current_id, qty: 0, price: 0 });

        this.dialogExtra = true;
      },

      addProd(value, prodRow) {
        prodRow.push({ product_id: this.product_current_id, qty: 0, price: 0 });
      },

      removeProd(index, prodRow) {
        prodRow.splice(index, 1);
      },

      close() {
        this.dialogExtra = false;
      },

      downloadPDF(item) {
        return 'core_api/get_pdf_file/orders_pdf/'+item.date_create+'/'+item.file_name
      },

      downloadReport() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'get',
          url: 'order_api/report/',
          responseType: 'blob',

          params: {
            period: this.picker,

            contract_id: this.contract_selected,
            mark: this.type_selected,
            paid: this.paid_selected,

            p_type: this.p_type_selected,
            p_method: this.p_method_selected,
            hosters: this.hosters_selected,
            block: this.block_selected,

            pagination: this.options,
          },
          data: {},
        })
        .then(function (response){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const fileName = 'report_orders' + '' + '.xlsx';

          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          self.contractsSelected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

    },

    computed: {
      totalCostExtra() {
        return this.editedExtra.products_list.reduce((total, cur) => total + parseInt(cur.qty) * parseInt(cur.price), 0);
      },

      unique_orders_len() {
        return new Map(this.orderSelected.map((item) => {return [item.contract, item]})).size
      },

      allRules() {
//        return Object.keys(this.editedExtra).every(field => this.editedExtra[field].valid);
        return this.editedExtra.contract_id &&
            this.editedExtra.products_list.every(e => parseInt(e.qty) > 0) &&
            this.editedExtra.products_list.every(e => parseInt(e.price) > 0)
      }

    },

    watch: {
      picker: function() {
        this.options.page = 1;
        this.orderSelected = [];
        this.getData();
      },

//      editedExtra: function() {
//        this.getData();
//      },

      p_type_selected: function() {
          this.options.page = 1;
          this.orderSelected = [];
          this.getData();
        },
      p_method_selected: function() {
        this.options.page = 1;
        this.orderSelected = [];
        this.getData();
      },
      hosters_selected: function() {
        this.options.page = 1;
        this.orderSelected = [];
        this.getData();
      },
      block_selected: function() {
        this.options.page = 1;
        this.orderSelected = [];
        this.getData();
      },

      contract_selected: function() {
        this.options.page = 1;
        this.orderSelected = [];
        this.getData();
      },

      type_selected: function() {
        this.options.page = 1;
        this.orderSelected = [];
        this.getData();
      },

      paid_selected: function() {
        this.options.page = 1;
        this.orderSelected = [];
        this.getData();
      },

      options: {
        handler() {
          this.orderSelected = [];
          this.getData();
        },
        deep: true,
      },

//      orderSelected: function() {
//        console.log('orderSelected:', this.orderSelected);
//        console.log('orderSelected:', Array.from(this.orderSelected, x => x.id));
//
//        let unique_orderSelected_len = new Map(this.orderSelected.map((item) => {return [item.contract, item]})).size;
//        console.log('test_uniqueObjArray_map', unique_orderSelected_len)
//      }

//      editedExtra: function() {
//        console.log('editedExtra:', this.editedExtra);
//        console.log('valid:', Object.keys(this.editedExtra).every(field => this.editedExtra[field]))
//      },

    },

  }
</script>

<style scoped>
  /*.v-dialog .v-card__title {*/
  /*  background-color: #2E7D32;*/
  /*  color: white;*/
  /*}*/
</style>
