<template>
  <div class="contracts">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogContract" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>{{ formContractTitle }}</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>
              <v-layout row wrap>
                  <!-- contract name, company -->
                  <v-flex xs6>
                    <v-text-field
                      v-model="editedContract.name"
                      label="contract name"
                      :rules="[rules.required]"
                      required
                      :disabled = "this.editedContractIndex !== -1"
                      prepend-icon="mdi-text-box-outline"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.company_id"
                      :items="companies"
                      item-text="name"
                      item-value="id"
                      label="company"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-store"
                    ></v-autocomplete>
                  </v-flex>
                  <!-- uk, mktu contracts -->
                  <v-flex xs12>
                    <v-autocomplete
                      v-model="editedContract.mktu_contract_id"
                      :items="contracts_mktu"
                      item-text="name"
                      item-value="id"
                      label="MKTU contract"

                      prepend-icon="mdi-desktop-tower-monitor"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <v-autocomplete
                      v-model="editedContract.uk_contract_id"
                      :items="contracts_uk"
                      item-text="name"
                      item-value="id"
                      label="UK contract"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-desktop-classic"
                    ></v-autocomplete>
                  </v-flex>
                  <!-- owner, source -->
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.owner_id"
                      :items="owners"
                      item-text="name"
                      item-value="id"
                      label="owner"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-guy-fawkes-mask"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.source_id"
                      :items="sources"
                      item-text="name"
                      item-value="id"
                      label="source"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-server-network"
                    ></v-autocomplete>
                  </v-flex>
                  <!-- method, type -->
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.method_id"
                      :items="methods"
                      item-text="name"
                      item-value="id"
                      label="pay method"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-bank-outline"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.type_id"
                      :items="types"
                      item-text="name"
                      item-value="id"
                      label="pay type"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-location-enter"
                    ></v-autocomplete>
                  </v-flex>
                  <!-- billing plan, manager -->
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.plan_id"
                      :items="plans"
                      item-text="name"
                      item-value="id"
                      label="billing plan"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-circle-multiple-outline"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.manager_id"
                      :items="managers"
                      item-text="name"
                      item-value="id"
                      label="manager"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-account-tie-outline"
                    ></v-autocomplete>
                  </v-flex>
                  <!-- contacts -->
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.contact_id"
                      :items="contacts"
                      item-text="name"
                      item-value="id"
                      label="counter contact"
                      :rules="[rules.required]"
                      required
                      prepend-icon="mdi-card-account-mail-outline"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs6>
                    <v-autocomplete
                      v-model="editedContract.contact_charge_ids"
                      :items="contacts"
                      item-text="name"
                      item-value="id"
                      label="charge contact"
                      multiple
                      clearable
                      prepend-icon="mdi-card-account-mail"
                    ></v-autocomplete>
                  </v-flex>
                  <!-- requirements, hoster -->
                  <v-flex xs3>
                    <v-checkbox
                      v-model="editedContract.is_hoster"
                      label="is hoster"
                      required
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs3>
                    <v-checkbox
                      v-model="editedContract.monitoring_required"
                      label="monitoring required"
                      required
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs3>
                    <v-checkbox
                      v-model="editedContract.billing_required"
                      label="billing required"
                      required
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs3>
                    <v-checkbox
                      v-model="editedContract.sending_required"
                      label="sending required"
                      required
                    ></v-checkbox>
                  </v-flex>

              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
                text
                @click="saveContract(editedContract)"
                :disabled="!editedContract.name || !editedContract.company_id || !editedContract.uk_contract_id ||
                           !editedContract.owner_id || !editedContract.source_id ||
                           !editedContract.method_id || !editedContract.type_id ||
                           !editedContract.plan_id || !editedContract.manager_id || !editedContract.contact_id"
                color="green darken-3"
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>

      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                @click="newContract()"
                color="primary"
                depressed
              >
                <v-icon>mdi-text-box-plus-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Contract</span>
        </v-tooltip>
<!--        <v-tooltip top>-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <div v-bind="attrs" v-on="on" class="px-2">-->
<!--              <v-btn-->
<!--                @click="storesRefresh()"-->
<!--                color="primary"-->
<!--                depressed-->
<!--              >-->
<!--                <v-icon>mdi-database-sync-outline</v-icon>-->
<!--              </v-btn>-->
<!--            </div>-->
<!--          </template>-->
<!--          <span>Stores Refresh</span>-->
<!--        </v-tooltip>-->

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="method_selected"
            :items="methods"
            item-text="name"
            item-value="id"
            label="Method"
            clearable
            prepend-icon="mdi-bank-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="type_selected"
            :items="types"
            item-text="name"
            item-value="id"
            label="Type"
            clearable
            prepend-icon="mdi-calendar"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="hosters_selected"
            :items="hosters"
            item-text="name"
            item-value="id"
            label="Hoster"
            clearable
            prepend-icon="mdi-laptop"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="source_selected"
            :items="sources"
            item-text="name"
            item-value="id"
            label="Source"
            clearable
            prepend-icon="mdi-server-network"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs3 lg3 xl3>
          <v-autocomplete
            v-model="company_selected"
            :items="companies"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Company"
            prepend-icon="mdi-store"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="contracts"

        :options.sync="options"
        :server-items-length="totalContracts"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        :loading="load"
        loading-text="Contracts loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                  class="text-truncate"
                  style="max-width: 130px;"
                  v-bind="attrs"
                  v-on="on"
              >
                {{ item.name }}
              </div>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.source="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.source === 'Fort Old'" color="blue lighten-1">mdi-server-network</v-icon>
                <v-icon v-else-if="item.source === 'Fort New'" color="blue darken-1">mdi-server-network</v-icon>

                <v-icon v-else-if="item.source === 'Wialon Pro'" color="green lighten-1">mdi-server-network</v-icon>
                <v-icon v-else-if="item.source === 'Wialon Local'" color="green darken-1">mdi-server-network</v-icon>

                <v-icon v-else-if="item.source === 'Wialon Agro'" color="teal lighten-1">mdi-server-network</v-icon>
                <v-icon v-else-if="item.source === 'Wialon Hosting'" color="teal darken-1">mdi-server-network</v-icon>

                <v-icon v-else color="deep-purple lighten-1" dark>mdi-server-network</v-icon>
              </div>
            </template>
            <span>{{ item.source }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.owner="{ item }">
          <div class="text-truncate" style="max-width: 130px;">
            {{ item.owner }}
          </div>
        </template>

        <template v-slot:item.is_hoster="{ item }">
          <v-icon v-if="item.is_hoster">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.monitoring_required="{ item }">
          <v-icon v-if="item.monitoring_required">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.billing_required="{ item }">
          <v-icon v-if="item.billing_required">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.sending_required="{ item }">
          <v-icon v-if="item.sending_required">mdi-check</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </template>

        <template v-slot:item.manager="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-account-tie-outline</v-icon>
            </template>
            <span>{{ item.manager }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.contact="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-card-account-mail-outline</v-icon>
            </template>
            <span>{{ item.contact }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.contact_charge="{ item }">
          <v-badge
            :content="item.contact_charge_count"
            :value="item.contact_charge_count"

            color="primary"
            bordered
          >
            <v-tooltip v-if="item.contact_charge_count" top>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-card-account-mail</v-icon>
              </template>
              <span v-html="item.contact_charge"></span>
            </v-tooltip>
          </v-badge>
        </template>

        <template v-slot:item.payment_method="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.payment_method === 'bank'">mdi-bank-outline</v-icon>
                <v-icon v-else>mdi-credit-card-outline</v-icon>
              </div>
            </template>
            <span>{{ item.payment_method }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.payment_type="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.payment_type === 'prepay'">mdi-location-enter</v-icon>
                <v-icon v-else>mdi-location-exit</v-icon>
              </div>
            </template>
            <span>{{ item.payment_type }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon @click="editContract(item)" class="mr-2">mdi-pencil</v-icon>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'Contracts',

    data() {
      return {
        contracts: [],

        contracts_uk: [],
        contracts_mktu: [],

        owners: [],

        types: [],
        methods: [],
        sources: [],
        plans: [],
        hosters: [],

        managers: [],
        contacts: [],

        companies: [],

        method_selected: [],
        type_selected: [],
        hosters_selected: [],

        company_selected: [],
        source_selected: [],

        load: false,
        overlay: false,

        dialogContract: false,
        editedContract: {name: ''},
        defaultContract: {
          name: '',
          is_hoster: false,
          monitoring_required: true,
          billing_required: true,
          sending_required: true,
        },
        editedContractIndex: -1,

        options: {
          'page': 1,
          'itemsPerPage': 10,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
        },
        sortBy: 'name',
        sortDesc: false,
        totalContracts: 0,

        headers: [
          { text: 'id', value: 'id' },

          { text: 'name', value: 'name'},

          { text: 'source', value: 'source'},
          { text: 'owner', value: 'owner'},

          { text: 'method', value: 'payment_method'},
          { text: 'type', value: 'payment_type'},

          { text: 'billing plan', value: 'payment_plan'},

          { text: 'hst', value: 'is_hoster'},

          { text: 'mnt', value: 'monitoring_required'},
          { text: 'bll', value: 'billing_required'},
          { text: 'snd', value: 'sending_required'},

          { text: 'manager', value: 'manager'},
          { text: 'count', value: 'contact'},
          { text: 'charge', value: 'contact_charge'},

          { text: 'edit', value: 'action', sortable: false },

        ],

        rules: {
          required: value => !!value || 'required!',
//          number: value => value.length >= 19 || 'short number!',
//          email: value => {
//            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//            return pattern.test(value) || 'invalid email!'
        },

      }
    },

    created() {
      this.getData();
    },

    mounted() {},

    methods: {
      getData() {
        let self = this;
        this.load = true;

        this.$http({
          method: 'get',
          url: 'contract_api/contracts/',

          params: {
            type: this.type_selected,
            method: this.method_selected,
            source: this.source_selected,
            hoster: this.hosters_selected,
            company: this.company_selected,

            pagination: this.options,
          },
          data: {},

        })
        .then(function (response){
          self.contracts = response.data.contracts;
          self.totalContracts = response.data.contracts_count;

          self.contracts_uk = response.data.data.contracts_uk;
          self.contracts_mktu = response.data.data.contracts_mktu;

          self.owners = response.data.data.owners;
          self.sources = response.data.data.sources;

          self.methods = response.data.data.methods;
          self.types = response.data.data.types;
          self.plans = response.data.data.plans;
          self.hosters = response.data.data.hosters;

          self.managers = response.data.data.managers;
          self.contacts = response.data.data.contacts;

          self.companies = response.data.data.companies;

          self.load = false;

        })
        .catch(function (error) {
          console.log(error);

        })
      },

      newContract() {
        Vue.set(this, 'editedContract', Object.assign({}, this.defaultContract));
        this.dialogContract = true;
      },

      editContract(item) {
        this.editedContractIndex = this.contracts.indexOf(item);
        this.editedContract = Object.assign({}, item);

        this.dialogContract = true;
      },

      saveContract(item) {
        const self = this;

        self.dialogContract = false;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'contract_api/contracts/',

          params: {
            type: this.type_selected,
            method: this.method_selected,
            source: this.source_selected,
            hoster: this.hosters_selected,
            company: this.company_selected,

            pagination: this.options,
          },
          data: {
            id: item.id,
            name: item.name,
            company_id: item.company_id,

            uk_raw_contract_id: item.uk_contract_id,
            mktu_raw_contract_id: item.mktu_contract_id,

            owner_id: item.owner_id,
            source_id: item.source_id,

            method: item.method_id,
            type: item.type_id,
            plan: item.plan_id,

            is_hoster: item.is_hoster,
            monitoring_required: item.monitoring_required,
            billing_required: item.billing_required,
            sending_required: item.sending_required,

            manager_id: item.manager_id,

            contact_id: item.contact_id,
            contact_charge_ids: item.contact_charge_ids,

            action: item.id ? 'edit' : 'create',

          },
        })
        .then(function (response) {
          self.contracts = response.data.contracts;
          self.totalContracts = response.data.contracts_count;

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      storesRefresh() {
        const self = this;

        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'company_api/companies/',

          params: {
            type: this.type_selected,
            method: this.method_selected,
            source: this.source_selected,
            hoster: this.hosters_selected,
            company: this.company_selected,

            pagination: this.options,
          },
          data: {
            action: 'stores refresh',
            method: 'refresh_stores',
          },

        })
        .then(function (response) {
          self.contracts = response.data.contracts;
          self.totalContracts = response.data.contracts_count;

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      close() {
        this.dialogContract = false;
        this.editedContractIndex = -1;
      },

    },

    computed: {
      formContractTitle() {
        return this.editedContractIndex === -1 ? 'New Contract' : 'Edit Contract'
      },

    },

    watch: {
      type_selected: function() {
        this.options.page = 1;
        this.getData();
      },

      method_selected: function() {
        this.options.page = 1;
        this.getData();
      },

      source_selected: function() {
        this.options.page = 1;
        this.getData();
      },

      hosters_selected: function() {
        this.options.page = 1;
        this.getData();
      },

      company_selected: function() {
        this.options.page = 1;
        this.getData();
      },

      options: {
        handler () {
          this.getData();
        },
        deep: true,
      },

    },

  }
</script>

<style scoped>

</style>
