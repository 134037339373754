<template>
  <v-app>

  </v-app>
</template>

<script>
export default {
  name: 'Logout',

  data() {
    return {
      anonymous: {
        'name': 'anonymous_user',
        'first_name': 'Anonymous',
        'last_name': 'User',

        'role': {
          'is_admin': false,
          'is_counter': false,
          'is_customer': false,
          'is_manager': false,
          'is_support': false,

        },

        'avatar': 'anonym.png',
      },

      auth: false,
      user_role: {},
      permissions: {},
    }
  },

  mounted() {
    this.$http({
      method: 'post',
      url: 'core_api/user_logout/',

      params: {},
      data: {},
    })
    .then(response => {
      console.log('status:', response.status);

      localStorage.setItem('auth', this.auth);
      localStorage.setItem('user_info', JSON.stringify(this.anonymous));

      localStorage.setItem('user_role', JSON.stringify(this.user_role));
      localStorage.setItem('permissions', JSON.stringify(this.permissions));

      this.$emit('updateUserInfo');
      this.$router.push({name: 'Login'}).catch(() => {});

    })
    .catch(function (error) {
      console.log(error)
    })
  },

  methods: {},

}
</script>

<style scoped>

</style>