<template>
  <div class="login">

    <v-container>
      <v-card width="400px" class="mx-auto mt-5">

        <v-card-title class="primary white--text">
          <span>Login</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" v-model="valid" >
            <v-text-field
              v-model="credentials.username"
              label="username"
              :rules="rules.username"
              prepend-icon="mdi-account-circle"
              required
              @focus="server_response=''"
            />
            <v-text-field
              v-model="credentials.password"
              label="password"
              :rules="rules.password"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              prepend-icon="mdi-lock"
              append-icon="mdi-eye-off"
              required
              @focus="server_response=''"
            />
            <div class="text-caption text-center" style="color: #ff5454">{{ server_response }}</div>

          </v-form>
        </v-card-text>
        <v-spacer></v-spacer>

        <v-card-actions class="justify-end">
          <v-btn
            :disabled="!valid || !credentials.username || !credentials.password"
            @click="login(credentials)"
            text
            color="green darken-3"
          >Login</v-btn>
        </v-card-actions>

      </v-card>
    </v-container>

  </div>
</template>

<script>

export default {
  name: 'Login',

  data() {
    return {
      credentials: {username: null, password: undefined},
      showPassword: false,
      valid: false,

      server_response: '',

      rules: {
        username: [
          v => !!v || "Name is required",
        ],
        password: [
          v => !!v || "Password is required",
        ],

//        email: [
//          v => !!v || "Email is required",
//          v => /.+@.+/.test(v) || 'Invalid Email address',
//        ],

      },

    }
  },

  created() {
    this.$emit('updateUserInfo');
//    console.log('credentials', this.credentials.username, this.credentials.password)
  },

  methods: {
    login(credentials) {
      this.$http({
        method: 'post',
        url: 'core_api/user_login/',

        params: {},
        data: {
          name: credentials.username,
          password: credentials.password,
        },
      })
      .then(response => {
        if (response.data.success){
          localStorage.setItem('auth', response.data.auth);

          localStorage.setItem('user_info', JSON.stringify(response.data.user_info));
          localStorage.setItem('user_role', JSON.stringify(response.data.user_role));
          localStorage.setItem('permissions', JSON.stringify(response.data.permissions));

          this.$emit('updateUserInfo');

          this.$router.replace(localStorage.getItem('redirectPath') || '/');
        } else {
          this.server_response = 'looks like username or password is incorrect';
        }

      })
      .catch(function(error) {
        console.log(error);
      })
    },
  },

  computed: {},

  watch: {},

}
</script>

<style scoped>

</style>
