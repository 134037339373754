<template>
  <div class="balances">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogApplicants" max-width="500px" persistent>
        <v-container>

          <v-card-title :style="{'background': color, 'color': textColor}">
            <span class="headline">{{ dialogTitle }} {{ applicantName }}</span>
          </v-card-title>

          <v-card>
            <v-card-text>
              Source: {{ ban_root }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text>
              Users: {{ ban_users }}
            </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn v-if="ban_users.length > 0" text @click="banApplicants()" :style="{'color': color}">confirm</v-btn>
          </v-card-actions>

          </v-card>

        </v-container>
      </v-dialog>

    <v-card>

      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn v-if="contractsSelected.length > 0" @click="refreshBalance()" color="primary" depressed>
                <v-icon color="white">mdi-refresh</v-icon>
              </v-btn>
              <v-btn v-else color="primary" disabled>
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
            </div>
          </template>
          <span>Refresh Balances</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  v-if="
                  contractsSelected.length &&
//                  contractsSelected.filter(item => item.is_ban).length === 0 &&
                  contractsSelected.filter(item => item.result > 0).length === contractsSelected.length
                  "
                  @click="sendDebt()"
                  color="primary"
                  depressed
              >
                <v-icon color="white">mdi-email-fast-outline</v-icon>
              </v-btn>
              <v-btn v-else color="primary" disabled>
          <v-icon color="white">mdi-email-fast-outline</v-icon>
        </v-btn>
            </div>
          </template>
          <span>Send 'Magic Letter'</span>
        </v-tooltip>

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="comparison_selected"
            :items="comparisons"
            item-text="name"
            item-value="value"
            label="Result"
            clearable
            prepend-icon="mdi-scale-balance"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="method_selected"
            :items="methods"
            item-text="name"
            item-value="id"
            label="Method"
            clearable
            prepend-icon="mdi-bank-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="type_selected"
            :items="types"
            item-text="name"
            item-value="id"
            label="Type"
            clearable
            prepend-icon="mdi-calendar"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="hosters_selected"
            :items="hosters"
            item-text="name"
            item-value="id"
            label="Hoster"
            clearable
            prepend-icon="mdi-laptop"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="block_selected"
            :items="blocks"
            item-text="name"
            item-value="value"
            label="Block"
            clearable
            prepend-icon="mdi-pause"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="source_selected"
            :items="sources"
            item-text="name"
            item-value="id"
            label="Source"
            clearable
            prepend-icon="mdi-server-network"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="contractsSelected"
        show-select

        :headers="headers"
        :items="balances"

        :options.sync="options"
        :server-items-length="totalBalances"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        multi-sort

        :expanded.sync="expanded"
        :single-expand="true"
        show-expand

        :loading="load"
        loading-text="Balances loading... Please wait"

        :footer-props="{'items-per-page-options': [10, 25, 100, 250, 500]}"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.contract__source="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.contract__source === 'Fort Old'" color="blue lighten-1">mdi-server-network</v-icon>
                <v-icon v-else-if="item.contract__source === 'Fort New'" color="blue darken-1">mdi-server-network</v-icon>

                <v-icon v-else-if="item.contract__source === 'Wialon Pro'" color="green lighten-1">mdi-server-network</v-icon>
                <v-icon v-else-if="item.contract__source === 'Wialon Local'" color="green darken-1">mdi-server-network</v-icon>

                <v-icon v-else-if="item.contract__source === 'Wialon Agro'" color="teal lighten-1">mdi-server-network</v-icon>
                <v-icon v-else-if="item.contract__source === 'Wialon Hosting'" color="teal darken-1">mdi-server-network</v-icon>

                <v-icon v-else color="deep-purple lighten-1">mdi-server-network</v-icon>
              </div>
            </template>
            <span>{{ item.contract__source }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.contract__payment_method="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.contract__payment_method === 'bank'">mdi-bank-outline</v-icon>
                <v-icon v-else>mdi-credit-card-outline</v-icon>
              </div>
            </template>
            <span>{{ item.contract__payment_method }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.contract__payment_type="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.contract__payment_type === 'prepay'">mdi-location-enter</v-icon>
                <v-icon v-else>mdi-location-exit</v-icon>
              </div>
            </template>
            <span>{{ item.contract__payment_type }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.contract__is_hoster="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.contract__is_hoster">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.contract__is_hoster }}</span>
          </v-tooltip>
        </template>

<!--        <template v-slot:item.balance__result="{ item }">-->
<!--          <span v-if="item.balance__result < 0" style="color: darkgreen">{{ item.balance__result }}</span>-->
<!--          <span v-else-if="item.balance__result > 0" style="color: darkred">{{ item.balance__result }}</span>-->
<!--          <span v-else>{{ item.balance__result }}</span>-->
<!--        </template>-->

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
<!--              v-bind:style="{color: 'darkred'}"   v-bind:style="{color: 'darkgreen'}"    -->
              <v-icon v-if="item.ban_available && item.is_ban" v-bind="attrs" v-on="on" class="mr-2" @click="getApplicants(item)">mdi-pause</v-icon>
              <v-icon v-else-if="item.ban_available" v-bind="attrs" v-on="on" class="mr-2" @click="getApplicants(item)">mdi-play</v-icon>
            </template>
<!--            ban {{ item.name }}: debt={{ item.balance__result }} -->
            debt: {{ item.result }}; blocked since: {{ item.date_ban }}
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">

            <v-container>
              <v-card>
                <v-card-title>
                  <v-icon color="primary">mdi-order-bool-ascending-variant</v-icon>
                  <v-divider class="mx-4" inset vertical></v-divider>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="px-5">
                        <v-btn
                            @click="downloadBalance()"
                            color="primary"
                            depressed
                        >
                          <v-icon color="white">mdi-table-arrow-down</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Balance Table Download</span>
                  </v-tooltip>

                </v-card-title>
                <v-divider></v-divider>

                  <v-data-table
                    :headers="headersDocs"
                    :items="item.docs"
                    :colspan="headers.length"
                    sort-by="contract"
                  >

              <template v-slot:item.type="{ item }">
                <v-icon v-if="item.row_number === 2">mdi-credit-card-refund-outline</v-icon>
                <v-icon v-else-if="item.type">mdi-minus</v-icon>
                <v-icon v-else>mdi-plus</v-icon>
              </template>

            </v-data-table>

                </v-card>
            </v-container>

          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Balances',

    data() {
      return {
        balances: [],
        totalBalances: 0,

        expanded: [],

        types: [],
        methods: [],
        sources: [],
        comparisons: [],
        hosters: [],
        blocks: [],
        contracts: [],
        
        type_selected: null,
        method_selected: null,
        source_selected: null,
        comparison_selected: null,
        hosters_selected: null,
        block_selected: null,
        contract_selected: null,

//        balanceSelected: [],
        contractsSelected: [],

        ban_root: '',
        ban_users: '',

        overlay: false,
        load: true,

        dialogApplicants: false,
        dialogTitle: '',
        applicantName: '',

        applicant: '',
        color: 'green',
        textColor: 'white',

        options: {
          'page': 1,
          'itemsPerPage': 10,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
        },

        sortBy: 'contract__name',
        sortDesc: false,

//        editedBalance: {name: ''},
//        defaultBalance: {name: ''},
//        editedBalanceIndex: -1,

        headers: [
          { text: 'contract name', value: 'contract__name'},

          { text: 'source', value: 'contract__source' },
          { text: 'p_meth', value: 'contract__payment_method' },
          { text: 'p_type', value: 'contract__payment_type' },
          { text: 'hoster', value: 'contract__is_hoster' },

          { text: 'result', value: 'result'},
          { text: 'last reminding', value: 'date_remind', sortable: false },

//          { text: 'last inspection', value: 'date', sortable: false },
//          { text: 'last ordered', value: 'order_date', sortable: false },

          { text: 'actions', value: 'actions', sortable: false },
          { text: 'blocked since', value: 'date_ban', sortable: false },

          { text: 'docs', value: 'data-table-expand', sortable: false },

        ],

        headersDocs: [
          { text: 'type', value: 'type' },
          { text: 'document name', value: 'name' },

          { text: 'date', value: 'date' },
          { text: 'value', value: 'value' },
          { text: 'order', value: 'order_number' },

          { text: 'comment', value: 'comment' },
        ],

      }
    },

    created() {
      this.getBalancesData();
    },

    methods: {
      getBalancesData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'balance_api/balances/',

          params: {
            type: this.type_selected,
            method: this.method_selected,
            source: this.source_selected,
            comparison: this.comparison_selected,
            hosters: this.hosters_selected,
            blocks: this.block_selected,
            contracts: this.contract_selected,

            pagination: this.options,
          },
          data: {},
        })
        .then(function (response){
          self.balances = response.data.balances;
          self.totalBalances = response.data.balances_count;

          self.sources = response.data.sources;
          self.types = response.data.types;
          self.methods = response.data.methods;
          self.comparisons = response.data.comparisons;
          self.hosters = response.data.hosters;
          self.blocks = response.data.blocks;
          self.contracts = response.data.contracts;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      refreshBalance() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'balance_api/balances/',

          params: {
            type: this.type_selected,
            method: this.method_selected,
            source: this.source_selected,
            comparison: this.comparison_selected,
            hosters: this.hosters_selected,
            blocks: this.block_selected,
            contracts: this.contract_selected,

            pagination: this.options,
          },
          data: {
            selected_ids: Array.from(this.contractsSelected, x => x.contract__id),

            method: 'refresh_balance',
            action: 'refresh',
          },

        })
        .then(function (response) {
          self.balances = response.data.balances;
          self.totalBalances = response.data.balances_count;

          self.contractsSelected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      sendDebt() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'balance_api/balances/',

          params:{
            type: this.type_selected,
            method: this.method_selected,
            source: this.source_selected,
            comparison: this.comparison_selected,
            hosters: this.hosters_selected,
            blocks: this.block_selected,
            contracts: this.contract_selected,

            pagination: this.options,
          },
          data: {
            selected_ids: Array.from(this.contractsSelected, x => x.contract__id),

            method: 'send_debt',
            action: 'debt send',

          },
        })
        .then(function (response) {
          self.balances = response.data.balances;

          self.contractsSelected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })

      },

      getApplicants(item) {
        let self = this;
        self.overlay = true;

        self.applicant = item;
        self.applicantName = item.contract__name;

        console.log('self.applicant:', self.applicant);

        self.ban_root = '';
        self.ban_users = '';

        if (item.is_ban) {
          self.dialogTitle = 'UnBan';
          self.color = '#2E7D32';
          self.textColor = 'white'
        } else {
          self.dialogTitle = 'Ban';
          self.color = '#C62828';
          self.textColor = 'black'
        }

        console.log('ban_users B:', self.ban_users)

        this.$http({
          method: 'post',
          url: 'balance_api/ban_applicants/',
          
          params:{
            type: this.type_selected,
            method: this.method_selected,
            source: this.source_selected,
            comparison: this.comparison_selected,
            hosters: this.hosters_selected,
          },
          data: {
            id: item.id,
            name: item.contract,
            contract_id: item.contract__id,

            method: 'get_applicants',
            action: 'applicants'
          }
        })
        .then(function (response) {
          self.ban_root = response.data.ban_root;
          self.ban_users = response.data.ban_users;

          self.overlay = false;
          self.dialogApplicants = true;

          console.log('ban_users A:', self.ban_users);
        })
        .catch(function (error) {
          console.log(error);
        })

      },

      banApplicants() {
        let self = this;
        self.dialogApplicants = false;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'balance_api/balances/',

          params:{
            type: this.type_selected,
            method: this.method_selected,
            source: this.source_selected,
            comparison: this.comparison_selected,
            hosters: this.hosters_selected,
            blocks: this.block_selected,
            contracts: this.contract_selected,

            pagination: this.options,
          },
          data: {
            method: 'ban_applicants',
            action: self.dialogTitle === 'Ban' ? 'ban' : 'unban',

            contract_id: self.applicant.contract__id,
            contract_is_ban: self.applicant.is_ban,

            ban_root: self.ban_root,
            ban_users: self.ban_users,
          }
        })
        .then(function (response) {
            self.balances = response.data.balances;
            self.totalBalances = response.data.balances_count;

            self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })

      },

      close() {
        this.dialogApplicants = false;
      },

//      downloadBalance() {
////        let contract_id = this.expanded[0].id
//
//        return 'core_api/get_xlsx_balance/' + this.expanded[0].id
//      },

      downloadBalance() {
        let self = this;
        self.overlay = true;

        let contract = this.expanded[0]
        let contract_id = contract.id
        let contract_name = contract.contract__name

        this.$http({
          method: 'get',
          url: 'core_api/get_xlsx_balance/',
          responseType: 'blob',

          params: {
//            date: this.picker,
            contract_id: contract_id,

          },
          data: {},
        })
        .then(function (response){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
//          const fileName = 'balance' + self.selected[0].contract_name + self.picker + '.xlsx';
          const fileName = 'balance' + contract_name + '.xlsx';

          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          self.selected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

    },

    computed: {},

    watch: {
      source_selected: function() {
        this.contractsSelected = [];
        this.options.page = 1;

        this.getBalancesData();
      },

      type_selected: function() {
        this.contractsSelected = [];
        this.options.page = 1;

        this.getBalancesData();
      },

      method_selected: function() {
        this.contractsSelected = [];
        this.options.page = 1;

        this.getBalancesData();
      },

      comparison_selected: function() {
        this.contractsSelected = [];
        this.options.page = 1;

        this.getBalancesData();
      },

      hosters_selected: function() {
        this.contractsSelected = [];
        this.options.page = 1;

        this.getBalancesData();
      },

      block_selected: function() {
        this.contractsSelected = [];
        this.options.page = 1;

        this.getBalancesData();
      },

      contract_selected: function() {
        this.contractsSelected = [];
        this.options.page = 1;

        this.getBalancesData();
      },

      contractsSelected: function() {
        console.log('ids: ', Array.from(this.contractsSelected, x => x.contract__id));
//        console.log('contractsSelected', this.contractsSelected);
//        console.log('ban_available', this.contractsSelected.filter(item => item.ban_available).length === this.contractsSelected.length);
//        console.log('debt_sending: ', this.contractsSelected.filter(item => item.result > 0).length === this.contractsSelected.length)
//        console.log('is_ban: ', this.contractsSelected.filter(item => item.is_ban).length)
      },

      applicant: function() {
        console.log('applicant', this.applicant)
      },

      options: {
        handler () {
          this.contractsSelected = [];

          this.getBalancesData();
        },
        deep: true,
      },

    },

  }
</script>

<style scoped>

</style>
