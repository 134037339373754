<template>
  <div class="acts">

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-card>

      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset  vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="refreshActs()"
                  :disabled = "actSelected.length < 1 || actSelected.some(elem => elem.mktu_number === null)"
                  color="primary"
                  depressed
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Acts Refresh</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                  @click="sendActs()"
                  :disabled = "unique_acts_len !== 1 || actSelected.some(elem => elem.mktu_number === null)"
                  color="primary"
                  depressed
              >
                <v-icon>mdi-email-fast-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Acts Send (batch)</span>
        </v-tooltip>

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-menu
            v-model="menuPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="picker"
                label="Month"
                prepend-icon="mdi-calendar"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="picker"
              @input="menuPicker = false"
              type="month"
              min="2021-09"
            ></v-date-picker>
          </v-menu>
        </v-flex>

        <!--Method, Type, Hoster-->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="p_method_selected"
            :items="p_methods"
            item-text="name"
            item-value="id"
            label="Method"
            clearable
            prepend-icon="mdi-bank-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="p_type_selected"
            :items="p_types"
            item-text="name"
            item-value="id"
            label="Type"
            clearable
            prepend-icon="mdi-calendar"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="hosters_selected"
            :items="hosters"
            item-text="name"
            item-value="id"
            label="Hoster"
            clearable
            prepend-icon="mdi-laptop"
          ></v-autocomplete>
        </v-flex>

        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="type_selected"
            :items="types"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Mark"
            prepend-icon="mdi-bookmark-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="original_selected"
            :items="originals"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Original"
            prepend-icon="mdi-book-check-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="actSelected"
        :headers="headers"
        :items="acts"
        show-select

        :options.sync="options"
        :server-items-length="totalActs"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        :loading="load"
        loading-text="Acts loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.order__is_primary="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.order__is_primary">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.order__is_primary }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.uk_uuid="{ item }">
          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">

                  <div class="text-truncate" style="max-width: 100px;">
                    {{ item.uk_uuid }}
                  </div>

                </div>
            </template>
          <span>{{ item.uk_uuid }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.mktu_uuid="{ item }">
          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">

                  <div class="text-truncate" style="max-width: 90px;">
                    {{ item.mktu_uuid }}
                  </div>

                </div>
            </template>
          <span>{{ item.mktu_uuid }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.mktu_pdf="{ item }">
          <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">

                  <v-btn v-if="item.mktu_number"
                    icon
                    small

                    class="ma-2"
                    :href="downloadPDF(item)"
                    target="_blank"
                  >
                    <v-icon>mdi-file-eye</v-icon>
                  </v-btn>

                </div>
              </template>
            <span>{{ item.file_name }}</span>
            </v-tooltip>
        </template>

        <template v-slot:item.mktu_original="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.mktu_number && item.mktu_original">mdi-check</v-icon>
                <v-icon v-else-if="item.mktu_number">mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.mktu_original }}</span>
          </v-tooltip>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Acts',

    data() {
      return {
        acts: [],

        overlay: false,
        load: false,

        actSelected: [],

        contracts: [],
        contract_selected: null,
        types: [],
        type_selected: null,
        originals: [],
        original_selected: null,

        p_types: [],
        p_methods: [],
        hosters: [],
        p_type_selected: null,
        p_method_selected: null,
        hosters_selected: null,

        menuPicker: false,
        picker: null,
//        picker: new Date().toISOString().substr(0, 7),

        options: {
          'page': 1,
          'itemsPerPage': 10,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
        },
        sortBy: '-id',
        sortDesc: false,
        totalActs: 0,

        headers: [
          { text: 'id', value: 'id' },

          { text: 'contract', value: 'order__contract__name' },
          { text: 'o_number', value: 'order__number' },
          { text: 'o_primary', value: 'order__is_primary' },

          { text: 'uk_number', value: 'uk_number' },
          { text: 'uk_uuid', value: 'uk_uuid' },

          { text: 'mktu_date', value: 'mktu_date_create' },
          { text: 'mktu_number', value: 'mktu_number' },
          { text: 'mktu_uuid', value: 'mktu_uuid' },

          { text: 'mktu_pdf', value: 'mktu_pdf', sortable: false },

          { text: 'original', value: 'mktu_original' },

        ],

      }
    },

    created() {},

    mounted() {
      this.getData();
    },

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'act_api/acts/',

          params: {
            date: this.picker,
            contract: this.contract_selected,
            type: this.type_selected,
            original: this.original_selected,

            p_type: this.p_type_selected,
            p_method: this.p_method_selected,
            hosters: this.hosters_selected,

            pagination: this.options,
          },
          data: {},
        })
        .then(function (response) {
          self.acts = response.data.acts;
          self.totalActs = response.data.acts_count;

          self.contracts = response.data.contracts;
          self.types = response.data.types;
          self.originals = response.data.originals;

          self.p_types = response.data.p_types;
          self.p_methods = response.data.p_methods;
          self.hosters = response.data.hosters;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      refreshActs() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'act_api/acts/',

          params: {
            date: this.picker,
            contract: this.contract_selected,
            type: this.type_selected,
            original: this.original_selected,

            p_type: this.p_type_selected,
            p_method: this.p_method_selected,
            hosters: this.hosters_selected,

            pagination: this.options,
          },
          data: {
            method: 'refresh_acts',
            action: 'acts refresh',

            ids: Array.from(this.actSelected, x => x.id),
          }
        })
        .then(function (response) {
          self.acts = response.data.acts;

          self.actSelected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      sendActs() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'act_api/acts/',

          params: {
            date: this.picker,
            contract: this.contract_selected,
            type: this.type_selected,
            original: this.original_selected,

            p_type: this.p_type_selected,
            p_method: this.p_method_selected,
            hosters: this.hosters_selected,

            pagination: this.options,
          },
          data: {
            method: 'send_acts',
            action: 'acts send',

            ids: Array.from(this.actSelected, x => x.id),
          }
        })
        .then(function (response) {
          self.acts = response.data.acts;

          self.actSelected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      downloadPDF(item) {
        return 'core_api/get_pdf_file/acts_pdf/'+item.dir_name+'/'+item.file_name
      },

    },

    computed: {
      unique_acts_len() {
        return new Map(this.actSelected.map((item) => {return [item.order__contract__name, item]})).size
      },
    },

    watch: {
      picker: function() {
        this.options.page = 1;
        this.actSelected = [];
        this.getData();
      },

      p_type_selected: function() {
          this.options.page = 1;
          this.actSelected = [];
          this.getData();
        },
      p_method_selected: function() {
        this.options.page = 1;
        this.actSelected = [];
        this.getData();
      },
      hosters_selected: function() {
        this.options.page = 1;
        this.actSelected = [];
        this.getData();
      },

      contract_selected: function() {
        this.options.page = 1;
        this.actSelected = [];
        this.getData();
      },

      type_selected: function() {
        this.options.page = 1;
        this.actSelected = [];
        this.getData();
      },

      original_selected: function() {
        this.options.page = 1;
        this.actSelected = [];
        this.getData();
      },

      options: {
        handler () {
          this.actSelected = [];
          this.getData();
        },
        deep: true,
      },

    },

  }
</script>

<style scoped>

</style>
