<template>
  <div class="quantity">

    <v-alert
        :value="alert"
        type="success"
        dark
        border="left"
        icon="mdi-home"
        transition="slide-y-transition"
        >
          Quantity objects into contract {{ editedQty.contract_name }} updated:<br>
          All: {{ editedQty.curr_all }};
          Paused: {{ editedQty.curr_psd }};
          DUTs: {{ editedQty.curr_dut }};
          DRTs: {{ editedQty.curr_drt }};
          CANs: {{ editedQty.curr_can }};
          SIMs: {{ editedQty.curr_sim }};
          Text attached: {{ editedQty.reason_text }}<br>
<!--          File attached: {{ editedQty.reason_file }}<br>-->
      </v-alert>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogQty" max-width="500px" persistent>
      <v-container>
        <v-card-title class="primary white--text">
          <span>{{ $t('quantity.dialog.edit') }}</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <v-layout row wrap>
                <!-- all, psd -->
                <v-flex xs6>
                  <v-text-field
                    v-model="editedQty.all"
                    label="total qty"
                    prepend-icon="mdi-align-horizontal-left"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="editedQty.psd"
                    label="paused qty"
                    prepend-icon="mdi-pause"
                  ></v-text-field>
                </v-flex>
                <!-- dut, drt -->
                <v-flex xs6>
                  <v-text-field
                    v-model="editedQty.dut"
                    label="duts qty"
                    prepend-icon="mdi-car-coolant-level"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="editedQty.drt"
                    label="drt qty"
                    prepend-icon="mdi-tanker-truck"
                  ></v-text-field>
                </v-flex>
                <!-- can, sim -->
                <v-flex xs6>
                  <v-text-field
                    v-model="editedQty.can"
                    label="cans qty"
                    prepend-icon="mdi-connection"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="editedQty.sim"
                    label="sims qty"
                    prepend-icon="mdi-sim-outline"
                  ></v-text-field>
                </v-flex>
                <!-- reason, file -->
                <v-flex xs12>
                  <v-textarea
                    v-model="editedQty.reason_text"
                    label="Reason text"
                    solo
                    auto-grow
                    prepend-icon="mdi-comment-text-outline"
                    :rules="rulesRequired"
                    required
                  ></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-file-input
                    v-model="editedQty.reason_file"
                    placeholder="Upload your documents"
                    label="Reason document"
                    prepend-icon="mdi-paperclip"
                    :rules="rulesRequired"
                    required
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                        small
                        label
                        close
                        color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn text @click="saveQty()" color="green darken-3">save</v-btn>
          </v-card-actions>

        </v-card>
      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogCompare" max-width="1080px" >
        <v-container>
          <v-card-title class="primary white--text">
            <span>{{ $t('quantity.dialog.comparison') }}</span>
          </v-card-title>
          <v-card>

            <v-card-text>
              <v-container>

                <div>Billing Plan: {{ comparePlanDesc }}</div>
<!--                <div>Difference: {{ compareDiff }}</div>-->
                <v-divider class="my-3"></v-divider>

                <div>New order rows (β): {{ productsStr }}</div>
                <div>New order amount (β): {{ amountDiff }}</div>

                <v-divider class="my-3"></v-divider>

                <div v-for="b in compareBills" :key="b.id">
                  {{ b.id }}: {{ b.counts }} = {{ b.total }}
                </div>
                <div>Difference amount in slices: {{ amountDiffCalc }}</div>
                <v-divider class="my-3"></v-divider>

                <div>New:<br/>
                  <div v-for="n in compareNew" :key="n.id">{{ n }}</div>
                </div>
                <v-divider class="my-3"></v-divider>

                <div>Old:<br/>
                  <div v-for="o in compareOld" :key="o.id">{{ o }}</div>
                </div>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
<!--              <v-btn text @click="close">cancel</v-btn>-->
<!--              <v-btn text @click="saveQty(editedQty)" color="green darken-3">save</v-btn>-->
            </v-card-actions>

          </v-card>
        </v-container>
      </v-dialog>

    <v-card>

      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn :disabled="!selected.length" @click="newSlice()" color="primary" depressed class="ma-2">
                <v-icon color="white">mdi-layers-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Slice</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="comparison_selected"
            :items="comparisons"
            item-text="name"
            item-value="value"
            :label="labels.equal"
            clearable
            prepend-icon="mdi-compare"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="method_selected"
            :items="methods"
            item-text="name"
            item-value="id"
            :label="labels.method"
            clearable
            prepend-icon="mdi-bank-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="type_selected"
            :items="types"
            item-text="name"
            item-value="id"
            :label="labels.type"
            clearable
            prepend-icon="mdi-calendar"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="hosters_selected"
            :items="hosters"
            item-text="name"
            item-value="id"
            :label="labels.hoster"
            clearable
            prepend-icon="mdi-laptop"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="source_selected"
            :items="sources"
            item-text="name"
            item-value="id"
            label="Source"
            clearable
            prepend-icon="mdi-server-network"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="company_selected"
            :items="companies"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Company"
            prepend-icon="mdi-store"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="selected"
        :headers="headers"

        :items="qty"
        item-key="contract_id"
        sort-by="name"

        show-select
        single-select

        :expanded.sync="expanded"
        @item-expanded="getSlices"
        show-expand
        single-expand

        :loading="load"
        loading-text="Quantity loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.contract_name="{ item }">
          <td v-if="item.is_equal">{{ item.contract_name }}</td>
          <td v-else v-bind:style="{color: 'red'}">{{ item.contract_name }}</td>
        </template>

        <template v-slot:item.payment_type="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.payment_type === 'prepay'">mdi-location-enter</v-icon>
                <v-icon v-else>mdi-location-exit</v-icon>
              </div>
            </template>
            <span>{{ item.payment_type }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.is_hoster="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon v-if="item.is_hoster">mdi-check</v-icon>
                <v-icon v-else>mdi-window-close</v-icon>
              </div>
            </template>
            <span>{{ item.is_hoster }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.all="{ item }">
          <td v-if="item.all_diff > 0" v-bind:style="{color: 'red'}">{{ item.all }}</td>
          <td v-else-if="item.all_diff < 0" v-bind:style="{color: 'green'}">{{ item.all }}</td>
          <td v-else>{{ item.all }}</td>
        </template>

        <template v-slot:item.nav="{ item }">
          <td v-if="item.nav_diff > 0" v-bind:style="{color: 'red'}">{{ item.nav }}</td>
          <td v-else-if="item.nav_diff < 0" v-bind:style="{color: 'green'}">{{ item.nav }}</td>
          <td v-else>{{ item.nav }}</td>
        </template>

        <template v-slot:item.psd="{ item }">
          <td v-if="item.psd_diff > 0" v-bind:style="{color: 'green'}">{{ item.psd }}</td>
          <td v-else-if="item.psd_diff < 0" v-bind:style="{color: 'red'}">{{ item.psd }}</td>
          <td v-else>{{ item.psd }}</td>
        </template>

        <template v-slot:item.dut="{ item }">
          <td v-if="item.dut_diff > 0" v-bind:style="{color: 'red'}">{{ item.dut }}</td>
          <td v-else-if="item.dut_diff < 0" v-bind:style="{color: 'green'}">{{ item.dut }}</td>
          <td v-else>{{ item.dut }}</td>
        </template>

        <template v-slot:item.drt="{ item }">
          <td v-if="item.drt_diff > 0" v-bind:style="{color: 'red'}">{{ item.drt }}</td>
          <td v-else-if="item.drt_diff < 0" v-bind:style="{color: 'green'}">{{ item.drt }}</td>
          <td v-else>{{ item.drt }}</td>
        </template>

        <template v-slot:item.can="{ item }">
          <td v-if="item.can_diff > 0" v-bind:style="{color: 'red'}">{{ item.can }}</td>
          <td v-else-if="item.can_diff < 0" v-bind:style="{color: 'green'}">{{ item.can }}</td>
          <td v-else>{{ item.can }}</td>
        </template>

        <template v-slot:item.sim="{ item }">
          <td v-if="item.sim_diff > 0" v-bind:style="{color: 'red'}">{{ item.sim }}</td>
          <td v-else-if="item.sim_diff < 0" v-bind:style="{color: 'green'}">{{ item.sim }}</td>
          <td v-else>{{ item.sim }}</td>
        </template>

        <template v-slot:item.reason_text="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="text-truncate"
                style="max-width: 90px;"
              >
                {{ item.reason_text }}
              </div>
            </template>
            <span>{{ item.reason_text }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.reason_file="{ item }">
<!--          <v-tooltip top>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
              <v-btn v-if="item.reason_file"
                icon
                small

                class="ma-2"
                :href="openReasonPDF(item)"
                target="_blank"

              >
                <v-icon
                  dark
                >mdi-file-eye
                </v-icon>
              </v-btn>
<!--            </template>-->
<!--            <span>{{ item.reason_file }}</span>-->
<!--          </v-tooltip>-->
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon

            class="mr-2"
            @click="editQty(item)"
          >mdi-pencil</v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>
            <v-container>

              <v-card>
                <v-card-title>
                  <v-tabs v-model='tab' right height="35">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab v-for="item in tabs" :key="item.id">
                      <v-icon left>
                        {{ item.icon }}
                      </v-icon>
                      {{ item.name }}
                    </v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text>
                  <v-tabs-items v-model="tab">
                    <v-tab-item key="0">
                      <v-card>
                        <v-card-title>
                          <v-icon color="primary">mdi-layers</v-icon>
                          <v-divider class="mx-4" inset vertical></v-divider>

                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-btn
                                    :disabled="slicesSelected.length < 1"
                                    @click="pdfFromSlices()"
                                    color="primary"
                                    depressed
                                >
                                  <v-icon color="white">mdi-file-pdf-box</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>Reason PDF Download</span>
                          </v-tooltip>

                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="px-5">
                                <v-btn
                                    :disabled="slicesSelected.length !== 1"
                                    @click="downloadSlice()"
                                    color="primary"
                                    depressed
                                >
                                  <v-icon color="white">mdi-table-arrow-down</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>Slice Table Download</span>
                          </v-tooltip>

<!--                          <v-tooltip top>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <div v-bind="attrs" v-on="on">-->
<!--                                <v-btn-->
<!--                                    :disabled="slicesSelected.length !== 2"-->
<!--                                    @click="compareSlices()"-->
<!--                                    color="primary"-->
<!--                                    depressed-->
<!--                                >-->
<!--                                  <v-icon color="white">mdi-select-compare</v-icon>-->
<!--                                </v-btn>-->
<!--                              </div>-->
<!--                            </template>-->
<!--                            <span>Compare Slices</span>-->
<!--                          </v-tooltip>-->


                        </v-card-title>
                        <v-divider></v-divider>

                        <v-data-table
                          v-model="slicesSelected"

                          :headers="headersSlice"
                          :items="slices"
                          :item-key="item.id"
                          :colspan="headers.length"

                          show-select

                          :options.sync="options"
                          :server-items-length="totalSlices"

                          :loading="loadSlices"
                          loading-text="Slices loading... Please wait"

                          :footer-props="{'items-per-page-options': [5, 10, 25, 100, 250, ]}"
                        ></v-data-table>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="1">
                      <v-card-title>
                        <v-icon color="primary">mdi-compare-remove</v-icon>
                        <v-divider class="mx-4" inset vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headersHistory"
                        :items="item.history"
                        :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                        :items-per-page="5"
                      ></v-data-table>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>

            </v-container>
          </td>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'Qty',

    data() {
      return {
        qty: [],

        selected: [],

        slices: [],
        totalSlices: 0,
        slicesSelected: [],
        loadSlices: false,

        options: {
          'page': 1,
          'itemsPerPage': 5,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
        },

        expanded: [],
        row_select: [],
        row_expand: false,
        row_reopen: false,

        dialogCompare: false,
        compareData: [],
        compareBills: [],
        compareDiff: [],
        comparePlanDesc: [],

        productsStr: '',
        amountDiff: 0,
        amountDiffCalc: 0,

        compareOld: [],
        compareNew: [],

        types: [],
        methods: [],
        comparisons: [],
        hosters: [],
        sources: [],
//        blocks: [],
        companies: [],

        type_selected: 1,
        method_selected: null,
        hosters_selected: null,
        comparison_selected: 2,
        source_selected: null,
        company_selected: null,

        dialogQty: false,
        editedQty: {name: ''}, // , reason_file: {file: null, name: null}
        defaultQty: {name: ''},
        editedQtyIndex: -1,

        date: new Date().toISOString().substr(0, 10),

        tab: null,
        tabs: [
          { id: 1, name: 'Slices', icon: 'mdi-layers' },
          { id: 2, name: 'History', icon: 'mdi-compare-remove'},
        ],

        alert: false,
        overlay: false,
        load: false,

        rulesRequired: [
          v => !!v || 'field is required',
        ],

        headersHistory: [
//          { text: 'id', value: 'id' },
//          { text: 'contract', value: 'contract' },
          { text: 'date', value: 'date' },

          { text: 'all', value: 'all' },
          { text: 'nav', value: 'nav' },
          { text: 'psd', value: 'psd' },

          { text: 'dut', value: 'dut' },
          { text: 'drt', value: 'drt' },
          { text: 'can', value: 'can' },
          { text: 'sim', value: 'sim' },

          { text: 'reason', value: 'reason', width: '40%' },
          { text: 'user', value: 'user' },

        ],
//        headersSlice: [],

      }
    },

    created() {
      this.getQtyData();
    },

//    mounted() {},
    mounted: function () {
      if(alert){
        this.hide_alert();
      }
    },

    methods: {
      getQtyData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'quantity_api/quantity/',

          params: {
            type: this.type_selected,
            method: this.method_selected,
            hosters: this.hosters_selected,
            comparison: this.comparison_selected,
            source: this.source_selected,
            company: this.company_selected,

          },
          data: {},
        })
        .then(function (response) {
          self.qty = response.data.qty;

          self.types = response.data.types;
          self.methods = response.data.methods;
          self.hosters = response.data.hosters;
          self.comparisons = response.data.comparisons;
          self.sources = response.data.sources;
          self.companies = response.data.companies;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      editQty(item) {
        this.editedQtyIndex = this.qty.indexOf(item);

//        this.editedQty = Object.assign({}, item);

        this.editedQty.all = item.new_all;
        this.editedQty.psd = item.new_psd;
        this.editedQty.dut = item.new_dut;
        this.editedQty.drt = item.new_drt;
        this.editedQty.can = item.new_can;
        this.editedQty.sim = item.new_sim;

        this.editedQty.contract_id = item.contract_id;
        this.editedQty.reason_text = item.reason_text;

        this.dialogQty = true;
      },

      saveQty() {
        const self = this;

        let formData = new FormData();

        // files
//        for (let file of item.files) {
//            formData.append('files', file, file.name);
//        }
        // additional data
//        formData.append('test', 'foo bar');

        formData.append('file', self.editedQty.reason_file, self.editedQty.reason_file.name);

        formData.append('all', self.editedQty.all);
        formData.append('psd', self.editedQty.psd);
        formData.append('dut', self.editedQty.dut);
        formData.append('drt', self.editedQty.drt);
        formData.append('can', self.editedQty.can);
        formData.append('sim', self.editedQty.sim);

        formData.append('text', self.editedQty.reason_text);
        formData.append('contract_id', self.editedQty.contract_id);
        formData.append('file_name', self.editedQty.reason_file.name);

        formData.append('method', 'edit_qty');
        formData.append('action', 'qty edit');

        self.dialogQty = false;
        self.alert = true;

        this.$http({
          method: 'post',
          url: 'quantity_api/quantity/',

          params: {
            comparison: this.comparison_selected,
            method: this.method_selected,
            type: this.type_selected,
            hosters: this.hosters_selected,
            company: this.company_selected,

          },
          data: formData,
        })
        .then(function (response) {
          self.qty = response.data.qty;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      getSlices(item) {
        let self = this;

        this.row_select = item;
        let contract_id = this.row_select.item.contract_id

        this.slicesSelected = [];

        self.loadSlice = true;

        this.$http({
          method: 'get',
          url: 'core_api/slices/',

          params: {
            pagination: this.options,
            contract_selected: contract_id
          },
          data: {},
        })
        .then(function (response){
          self.slices = response.data.slices;
          self.totalSlices = response.data.slices_count;

          self.loadSlice = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

//      compareSlices(){
//        const self = this;
//
//        this.$http({
//          method: 'post',
//          url: 'core_api/slices_compare/',
//
//          params: {},
//          data: {
//            slices_selected: Array.from(this.slicesSelected, x => x.id),
//            action: 'compare'
//          },
//        })
//        .then(function (response){
//          self.compareData = response.data.compare;
//
//          self.compareBills = response.data.bills;
//          self.compareDiff = response.data.diff;
//          self.comparePlanDesc = response.data.plan_desc;
//
//
//          self.productsStr = response.data.products_str;
//          self.amountDiff = response.data.diff_amount;
//          self.amountDiffCalc = response.data.diff_amount_calc;
//
//
//          self.compareOld = response.data.old;
//          self.compareNew = response.data.new;
//
//          self.dialogCompare = true;
//        })
//        .catch(function (error) {
//          console.log(error);
//        })
//
//      },

      newSlice() {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'quantity_api/quantity/',

          params: {
            type: this.type_selected,
            method: this.method_selected,
            hosters: this.hosters_selected,
            comparison: this.comparison_selected,
            source: this.source_selected,
            company: this.company_selected,

          },
          data: {
            contracts_selected: Array.from(this.selected, x => x.contract_id),

            method: 'create_slices',
            action: 'slices create',
          },
        })
        .then(function (response){
          self.qty = response.data.qty;

          // додавання нового зрізу у відкритий слайдер TODO виправити, додає у відкритий, а не в свій
          self.totalSlices++;
          self.slices.pop();
          self.slices.unshift(response.data.new_slice);

          self.selected = [];

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })

      },

      close() {
        this.dialogQty = false;
        this.editedQtyIndex = -1;

        this.dialogCompare = false;
      },

      openReasonPDF(item) {
//        console.log('item.reason_file', item.reason_file);
        if (item.reason_file.includes('reason_files')) {
            return 'core_api/get_pdf_file/'+item.reason_file
        } else {
            return 'core_api/get_pdf_file/reason_files/'+item.reason_file
        }

      },

//      downloadSlice() {
//        let slice_id = Array.from(this.slicesSelected, x => x.id)[0];
//        console.log('slice_id:', slice_id);
//
//        return 'core_api/get_xlsx_slice/' + slice_id
//      },

      downloadSlice() {
        let self = this;
        self.overlay = true;

        console.log('this.slicesSelected:', this.slicesSelected)
        let slice_id = Array.from(this.slicesSelected, x => x.id)[0];

        this.$http({
          method: 'get',
          url: 'core_api/get_xlsx_slice/',
          responseType: 'blob',

          params: {
            slice_id: slice_id,

          },
          data: {},
        })
        .then(function (response){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const fileName = 'slice #' + slice_id + '.xlsx';

          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

//          self.selected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      pdfFromSlices() {
        const self = this;

//        self.overlay = true;
//        console.log('this.expanded[0].contract_name:', this.expanded[0].contract_name)

        this.$http({
          method: 'post',
          url: 'quantity_api/get_pfd_slices/',
          responseType: 'blob',

          params: {
            comparison: this.comparison_selected,
            method: this.method_selected,
            type: this.type_selected,
            hosters: this.hosters_selected,
            company: this.company_selected,

          },
          data: {
            slices_selected: Array.from(this.slicesSelected, x => x.id),

            method: 'create_pdf',
            action: 'pdf create'
          },
        })
        .then(function (response){
//          this.slicesSelected = [];

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const fileName = 'reason' + self.expanded[0].contract_name + self.date + '.pdf';

          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          self.slicesSelected = [];
//          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })

      },

      hide_alert() {
//        console.log('Hide')
        // `event` is the native DOM event
        window.setInterval(() => {
          this.alert = false;
//          console.log("hide alert after 3 seconds");
        }, 3000)
      },

    },

    computed: {
      labels() {
        return {
          equal: this.$t('quantity.filters.equal'),
          method: this.$t('quantity.filters.method'),
          type: this.$t('quantity.filters.type'),
          hoster: this.$t('quantity.filters.hoster'),
        }
      },

      headers() {
        return [
          { text: this.$t('quantity.headers.contract'), value: 'contract_name' },
          { text: this.$t('quantity.headers.p_type'), value: 'payment_type' },
          { text: this.$t('quantity.headers.hoster'), value: 'is_hoster' },

          { text: this.$t('quantity.headers.all'), value: 'all' },
          { text: this.$t('quantity.headers.nav'), value: 'nav' },
          { text: this.$t('quantity.headers.psd'), value: 'psd' },

          { text: this.$t('quantity.headers.dut'), value: 'dut' },
          { text: this.$t('quantity.headers.drt'), value: 'drt' },
          { text: this.$t('quantity.headers.can'), value: 'can' },
          { text: this.$t('quantity.headers.sim'), value: 'sim' },

          { text: this.$t('quantity.headers.desc'), value: 'reason_text' },
          { text: this.$t('quantity.headers.file'), value: 'reason_file' },

          { text: this.$t('quantity.headers.edit'), value: 'action' },
          { text: this.$t('quantity.headers.slices'), value: 'data-table-expand', sortable: false },
        ]
      },

      headersSlice() {
        return [
          {text: this.$t('quantity.headers_slice.id'), value: 'id'},
          {text: this.$t('quantity.headers_slice.date'), value: 'date'},

          {text: this.$t('quantity.headers_slice.all'), value: 'data.counters.count', sortable: false},
          {text: this.$t('quantity.headers_slice.psd'), value: 'data.counters.paused', sortable: false},

          {text: this.$t('quantity.headers_slice.dut'), value: 'data.counters.duts', sortable: false},
          {text: this.$t('quantity.headers_slice.drt'), value: 'data.counters.drts', sortable: false},
          {text: this.$t('quantity.headers_slice.can'), value: 'data.counters.cans', sortable: false},
          {text: this.$t('quantity.headers_slice.sim'), value: 'data.counters.sims', sortable: false},

          {text: this.$t('quantity.headers_slice.products'), value: 'products_str', sortable: false},
          {text: this.$t('quantity.headers_slice.amount'), value: 'amount', sortable: false},
        ]
      },

//      formQtyTitle() {
//        return this.editedQtyIndex === -1 ? 'NEW' : 'Update Quantity'
//      },

    },

    watch: {
//      source_selected() {
//        this.balanceSelected = [];
//        this.options.page = 1;
//        this.getBalancesData();
//      },
//      block_selected() {
//        this.balanceSelected = [];
//        this.options.page = 1;
//
//        this.getBalancesData();
//      },

      type_selected() {
//        this.balanceSelected = [];
//        this.options.page = 1;
        this.getQtyData();
      },

      method_selected() {
//        this.balanceSelected = [];
//        this.options.page = 1;
        this.getQtyData();
      },

      comparison_selected() {
//        this.balanceSelected = [];
//        this.options.page = 1;
        this.getQtyData();
      },

      hosters_selected() {
//        this.balanceSelected = [];
//        this.options.page = 1;
        this.getQtyData();
      },

      source_selected() {
        this.getQtyData();
      },

      company_selected() {
//        this.balanceSelected = [];
//        this.options.page = 1;
        this.getQtyData();
      },

      expanded() {
        console.log('expanded', this.expanded);
        if (!this.expanded.length) {
          this.options.page = 1;
          this.options.itemsPerPage = 5;
        }
      },

      options: {
        handler() {
          console.log('options.page:', this.options.page, this.options.itemsPerPage);
          this.getSlices(this.row_select);
        },
        deep: true,
      },

    },

  }
</script>

<style scoped>

</style>
