<template>
  <div class="companies">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogCompany" max-width="500px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>{{ formCompanyTitle }}</span>
        </v-card-title>
        <v-card>

        <v-card-text>
          <v-container>
            <v-layout row wrap>

              <v-flex xs12>
                <v-text-field
                  v-model="editedCompany.name"
                  label="company name microtronic"
                  :rules="[rules.required]"
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-autocomplete
                  v-model="editedCompany.mktu_raw_company_id"
                  :items="companies_mktu"
                  item-text="name"
                  item-value="id"
                  label="companies_mktu"
                  required
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12>
                <v-autocomplete
                  v-model="editedCompany.uk_raw_company_id"
                  :items="companies_uk"
                  item-text="name"
                  item-value="id"
                  label="companies_uk"
                  :rules="[rules.required]"
                  required
                ></v-autocomplete>
              </v-flex>

            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">cancel</v-btn>
          <v-btn
              text
              @click="saveCompany(editedCompany)"
              :disabled="!editedCompany.name || !editedCompany.uk_raw_company_id"
              color="green darken-3"
          >save</v-btn>
        </v-card-actions>

      </v-card>

      </v-container>
    </v-dialog>

    <v-card>

      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="newCompany()"
                color="primary"
                depressed
              >
                <v-icon>mdi-store-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Company</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn
                @click="storesRefresh()"
                color="primary"
                depressed
              >
                <v-icon>mdi-database-sync-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Stores Refresh</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <v-flex xs3 lg3 xl3>
          <v-autocomplete
            v-model="company_selected"
            :items="companies_filter"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Company"
            prepend-icon="mdi-store"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="companies"

        :options.sync="options"
        :server-items-length="totalCompanies"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        :loading="load"
        loading-text="Companies loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon class="mr-2" @click="editCompany(item)">mdi-pencil</v-icon>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    name: 'Companies',

    data() {
      return {
        companies: [],

        companies_uk: [],
        companies_mktu: [],

        companies_filter: [],
        company_selected: [],

        overlay: false,
        load: false,

        dialogCompany: false,
        editedCompany: {name: ''},
        defaultCompany: {name: ''},
        editedCompanyIndex: -1,

        options: {
          'page': 1,
          'itemsPerPage': 10,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
        },
        sortBy: 'name',
        sortDesc: false,
        totalCompanies: 0,

        headers: [
          { text: 'id', value: 'id' },

          { text: 'name', value: 'name' },

          { text: 'uk_name', value: 'uk_name', sortable: false },
          { text: 'uk_code', value: 'uk_code', sortable: false },

          { text: 'mktu_name', value: 'mktu_name', sortable: false },
          { text: 'mktu_code', value: 'mktu_code', sortable: false },

          { text: 'edit', value: 'action', sortable: false },

        ],

        rules: {
          required: value => !!value || 'required!',
//          number: value => value.length >= 19 || 'short number!',
//          email: value => {
//            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//            return pattern.test(value) || 'invalid email!'
        },

      }
    },

    created() {
      this.getData();
    },

    mounted() {},

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'company_api/companies/',

          params: {
            company_selected: this.company_selected,
            pagination: this.options,
          },
          data: {},
        })
        .then(function (response){
          self.companies = response.data.companies;
          self.totalCompanies = response.data.companies_count;

          self.companies_uk = response.data.data.companies_uk;
          self.companies_mktu = response.data.data.companies_mktu;
          self.companies_filter = response.data.data.companies_filter;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      newCompany() {
        Vue.set(this, 'editedCompany', Object.assign({}, this.defaultCompany));
        this.dialogCompany = true;
      },

      editCompany(item) {
        this.editedCompanyIndex = this.companies.indexOf(item);
        this.editedCompany = Object.assign({}, item);

        this.dialogCompany = true;
      },

      saveCompany(item) {
        const self = this;

        this.$http({
          method: 'post',
          url: 'company_api/companies/',

          params: {
            company_selected: this.company_selected,
            pagination: this.options,
          },
          data: {
            id: item.id,
            name: item.name,

            uk_raw_company_id: item.uk_raw_company_id,
            mktu_raw_company_id: item.mktu_raw_company_id,

            action: item.id ? 'edit' : 'create',
            method: 'action_company',
          },

        })
        .then(function (response) {
          self.companies = response.data.companies;
          self.totalCompanies = response.data.companies_count;

          self.dialogCompany = false;
          self.alert('changes saved');
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      storesRefresh() {
        const self = this;

        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'company_api/companies/',

          params: {
            company_selected: this.company_selected,
            pagination: this.options,
          },
          data: {
            action: 'stores refresh',
            method: 'refresh_stores',
          },

        })
        .then(function (response) {
          self.companies = response.data.companies;
          self.totalCompanies = response.data.companies_count;

          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      close() {
        this.dialogCompany = false;
        this.editedCompanyIndex = -1;
      },

    },

    computed: {
      formCompanyTitle() {
        return this.editedCompanyIndex === -1 ? 'New Company' : 'Edit Company'
      },

    },

    watch: {
      company_selected: function() {
        this.options.page = 1;
        this.getData();
      },

      options: {
        handler () {
          this.getData();
        },
        deep: true,
      },

    },

  }
</script>

<style scoped>
  /*.v-dialog .v-card__title {*/
  /*  background-color: #607D8B;*/
  /*  color: white;*/
  /*}*/
</style>
