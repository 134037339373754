<template>
  <div class="servers">

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogReport" max-width="600px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>Monthly Servers Report</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>
              <v-row>

                <v-col>
                  <v-date-picker
                    v-model="picker"
                    type="month"
                    min="2021-10"
                    :max=current_month
                    multiple
                  ></v-date-picker>
                </v-col>
                <v-col align="center">
                  <v-flex class="mb-5 font-weight-bold">Selected Months</v-flex>
                  <v-layout row wrap
                      v-for="(elem, index) in picker_sorted"
                      :key="`${index}`"
                    >
                    <v-flex v-if="index === 0" style="color: dimgray">{{elem}}</v-flex>
                    <v-flex v-else style="font-size: medium">{{elem}}</v-flex>
                  </v-layout>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn text @click="downloadMonthly()" :disabled="picker.length < 2" color="green darken-3">download</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn @click="newMonthlyReport()" color="primary" :disabled="source_selected !== null">
                <v-icon color="white">mdi-table-arrow-down</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Monthly Report</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn @click="downloadDuplicates()" color="primary" :disabled="source_selected !== null">
                <v-icon color="white">mdi-content-duplicate</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Duplicates Report</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="px-2">
              <v-btn @click="refreshTotalSlices()" color="primary" :disabled="source_selected === null">
                <v-icon color="white">mdi-refresh</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Refresh Source</span>
        </v-tooltip>
        <v-flex class="d-flex pl-1">
          <v-card outlined rounded min-width="155" class="d-flex justify-space-between pa-2">
            <v-card flat style="position: absolute; color: darkgray" class="text-caption my-n5">
              <pre> Object Reports </pre>
            </v-card>
<!--              <v-tooltip top>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <div v-bind="attrs" v-on="on" class="px-1">-->
<!--&lt;!&ndash;                    :disabled="all_btn_rules"&ndash;&gt;-->
<!--                    <v-btn-->
<!--                      @click="downloadReport('All')"-->
<!--                      disabled-->
<!--                      color="primary"-->
<!--                    >-->
<!--                      <v-icon color="white">mdi-cart</v-icon>-->
<!--                    </v-btn>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <span>All Objects</span>-->
<!--              </v-tooltip>-->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="px-1">
                    <v-btn
                      @click="downloadReport('Billing')"
                      :disabled="billing_btn_rules"
                      color="primary"
                    >
                      <v-icon color="white">mdi-cart-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Billing Objects</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="px-1">
                    <v-btn
                      @click="downloadReport('Inactive')"
                      :disabled="inactive_btn_rules"
                      color="primary"
                    >
                      <v-icon color="white">mdi-cart-minus</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Inactive Objects</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="px-1">
                    <v-btn
                      @click="downloadReport('Wild')"
                      :disabled="wild_btn_rules"
                      color="primary"
                    >
                      <v-icon color="white">mdi-cart-remove</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>Wild Objects</span>
              </v-tooltip>
          </v-card>
        </v-flex>
        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="source_selected"
            :items="sources"
            item-text="name"
            item-value="id"
            label="Source"
            clearable
            prepend-icon="mdi-server-network"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="dashboardSunburstChart" ref="div_sunburst"></div>
      </v-card-text>

<!--    <div class="dashboardPieChart" ref="div_pie"></div>-->
<!--    <div class="dashboardXYChart" ref="div_chart"></div>-->

    </v-card>

  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins from '@amcharts/amcharts4/plugins/sunburst';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

export default {
  name: 'Servers',

  data() {
    return {
//      pie_chart_data: [],

//      pie_chart_data: [
//        {
//          'name': 'Wialon Agro',
//          'value': 25,
//          'color': '#00897B'
//        }, {
//          'name': 'Wialon Hosting',
//          'value': 999,
//          'color': '#00796B'
//        }, {
//          'name': 'Wialon Local',
//          'value': 406,
//          'color': '#43A047'
//        }, {
//          'name': 'Wialon Pro',
//          'value': 2057,
//          'color': '#66BB6A'
//        }, {
//          'name': 'Fort Old',
//          'value': 3521,
//          'color': '#42A5F5'
//        }, {
//          'name': 'Fort New',
//          'value': 1159,
//          'color': '#1E88E5'
//        },
//        {
//          'name': 'Cicada',
//          'value': 64,
//          'color': '#5E35B1'
////          "color": am4core.color("#5E35B1")
//        },
//
//      ],

//      labelsColor: '',

      sources: [],
      source_selected: null,

      actives: [],
      active_selected: null,

      sample_request: null,

      sunburst_chart_data: [{'children': [{'value': 0}, {'value': 0}, {'value': 0}, ]}, ],

//      date: new Date().toISOString().substr(0, 10),
//      time: new Date().toLocaleTimeString('uk').substr(0, 5),

      overlay: false,
      dialogReport: false,

      picker: [new Date().toISOString().substr(0, 7), ],
      current_month: new Date().toISOString().substr(0, 7),

//      sunburst_chart_data: [
//        {
//          name: "First",
//          children: [
//            { name: "A1", value: 100 },
//            { name: "A2", value: 60 }
//          ],
//          color: '#26A69A'
//        },
//        {
//          name: "Second",
//          children: [
//            { name: "B1", value: 135 },
//            { name: "B2", value: 98 }
//          ]
//        },
//        {
//          name: "Third",
//          children: [
//            {
//              name: "C1",
//              children: [
//                { name: "EE1", value: 130 },
//                { name: "EE2", value: 87 },
//                { name: "EE3", value: 55 }
//              ]
//            },
//            {
//              name: "C2",
//              value: 148
//            },
//            {
//              name: "C3",
//              children: [
//                { name: "CC1", value: 53 },
//                { name: "CC2", value: 30 }
//              ]
//            },
//              { name: "C4", value: 26 }
//          ]
//        },
//        {
//          name: "Fourth",
//          children: [
//            { name: "D1", value: 415 },
//            { name: "D2", value: 148 },
//            { name: "D3", value: 89 }
//          ]
//        },
//        {
//          name: "Fifth",
//          children: [
//            {
//              name: "E1",
//              children: [
//                { name: "EE1", value: 33 },
//                { name: "EE2", value: 40 },
//                { name: "EE3", value: 89 }
//              ]
//            },
//            {
//              name: "E2",
//              value: 148
//            }
//          ]
//        }
//      ],

    }
  },

  created() {
    this.getSunburstData().then(this.getSunburst);
//    this.getData().then(this.getPie);
  },

  mounted() {
//    this.getChart();
//    this.getSunburst();
  },

  methods: {
    getSunburstData() {
      let self = this;

      return this.$http({
        method: 'get',
        url: 'dashboard_api/servers_objects/',

        params: {
          source: this.source_selected,
//          active: this.active_selected,
        },
        data: {},
      })
      .then(function (response){
        self.sunburst_chart_data = response.data.sunburst_chart_data;

        self.sources = response.data.sources;
//        self.actives = response.data.actives;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    getChart() {
      let chart = am4core.create(this.$refs.div_chart, am4charts.XYChart);

      chart.paddingRight = 20;

      let data = [];
      let visits = 10;
      for (let i = 1; i < 366; i++) {
        visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        data.push({ date: new Date(2020, 0, i), name: "name" + i, value: visits });
      }

//      console.log('data: ', data);

      chart.data = data;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";

      series.tooltipText = "{valueY.value}";
      chart.cursor = new am4charts.XYCursor();

      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      chart.scrollbarX = scrollbarX;

      this.chart = chart;
    },

    getPie() {
      let chart = am4core.create(this.$refs.div_pie, am4charts.PieChart);
      chart.data = this.pie_chart_data;

      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'name';
      pieSeries.slices.template.propertyFields.fill = 'color';

//      pieSeries.slices.template.tooltipText = "";
//      pieSeries.labels.template.disabled = true;
//      pieSeries.ticks.template.disabled = true;
//      pieSeries.labels.template.radius = am4core.percent(150);

//      if (this.$vuetify.theme.dark) {
//        pieSeries.labels.template.fill = am4core.color('white');
//      } else {
//        pieSeries.labels.template.fill = am4core.color('black');
//      }
//      pieSeries.labels.template.fill = am4core.color(this.$vuetify.theme.dark ? 'white' : 'black');

      chart.legend = new am4charts.Legend();
      chart.legend.position = 'left'

      this.chart = chart;
    },

    getSunburst() {
      let chart = am4core.create(this.$refs.div_sunburst, am4plugins.Sunburst);
      chart.data = this.sunburst_chart_data;

//      chart.padding(30,30,30,30);
      chart.radius = am4core.percent(95);

//      chart.colors.step = 2;
      chart.fontSize = 15;
      chart.innerRadius = am4core.percent(3);

      // define data fields
      chart.dataFields.name = 'name';
      chart.dataFields.value = 'value';
      chart.dataFields.children = 'children';
      chart.dataFields.color = 'color';

      let level0SeriesTemplate = new am4plugins.SunburstSeries();
      level0SeriesTemplate.hiddenInLegend = false;
      chart.seriesTemplates.setKey('0', level0SeriesTemplate)

      // this makes labels to be hidden if they don't fit
      level0SeriesTemplate.labels.template.truncate = true;
      level0SeriesTemplate.labels.template.hideOversized = true;

      level0SeriesTemplate.labels.template.adapter.add('rotation', function(rotation, target) {
        target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
        target.maxHeight = Math.abs(target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius) / 2 * am4core.math.RADIANS);

        return rotation;
      })

      let level1SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey('1', level1SeriesTemplate)
      level1SeriesTemplate.fillOpacity = 0.75;
      level1SeriesTemplate.hiddenInLegend = true;

      let level2SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey('2', level2SeriesTemplate)
      level2SeriesTemplate.fillOpacity = 0.5;
      level2SeriesTemplate.hiddenInLegend = true;

      chart.legend = new am4charts.Legend();
//      chart.legend.position = 'left'
    },

    downloadReport(sample_name) {
      let self = this;
      self.overlay = true;

      let date_now = new Date().toISOString().substr(0, 10)
      let time_now = new Date().toLocaleTimeString('uk').substr(0, 8).replaceAll(':', '-')

      this.$http({
        method: 'get',
        url: 'dashboard_api/servers_report/',
        responseType: 'blob',

        params: {
          source_id: this.source_selected,
          sample: sample_name,

        },
        data: {},
      })
      .then(function (response){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        const partsArray = [
          'report_source',
          self.source_selected,
          sample_name,
          date_now,
          time_now,
          '.xlsx'

        ];
        const fileName = partsArray.join('_')

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    downloadMonthly() {
      let self = this;
      self.overlay = true;

      let date_now = new Date().toISOString().substr(0, 10)
      let time_now = new Date().toLocaleTimeString('uk').substr(0, 8).replaceAll(':', '-')

      this.$http({
        method: 'get',
        url: 'dashboard_api/deltas_report/',
        responseType: 'blob',

        params: {
          months: this.picker_sorted,

        },
        data: {},
      })
      .then(function (response){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        console.log(url);
        const link = document.createElement('a');

        const partsArray = [
          'report_deltas',
          date_now,
          time_now,
          '.xlsx'

        ];
        const fileName = partsArray.join('_')

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        self.overlay = false;
        self.dialogReport = false;
        self.picker = [new Date().toISOString().substr(0, 7), ];

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    refreshTotalSlices() {
      let self = this;
      self.overlay = true;

      return this.$http({
        method: 'post',
        url: 'dashboard_api/servers_objects/',

        params: {
          source: this.source_selected,
//          active: this.active_selected,

        },
        data: {
          method: 'refresh_slices',
          action: 'slices refresh',

        },
      })
      .then(function (response){
        self.sunburst_chart_data = response.data.sunburst_chart_data;

        self.sources = response.data.sources;
//        self.actives = response.data.actives;

        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    newMonthlyReport() {
      this.overlay = true;
      this.dialogReport = true;
    },

    downloadDuplicates() {
      let self = this;
      self.overlay = true;

      let date_now = new Date().toISOString().substr(0, 10)
      let time_now = new Date().toLocaleTimeString('uk').substr(0, 8).replaceAll(':', '-')

      this.$http({
        method: 'get',
        url: 'dashboard_api/duplicates_report/',
        responseType: 'blob',

        params: {},
        data: {},
      })
      .then(function (response){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        const partsArray = [
          'report_duplicates',
          date_now,
          time_now,
          '.xlsx'

        ];
        const fileName = partsArray.join('_')

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        self.overlay = false;
//        self.dialogReport = false;
//        self.picker = [new Date().toISOString().substr(0, 7), ];

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    close() {
      this.overlay = false;
      this.dialogReport = false;
    },

  },

  computed: {
//    date_now() {
//      return new Date().toISOString().substr(0, 10)
//    },
//
//    time_now() {
//      return new Date().toLocaleTimeString('uk').substr(0, 8).replaceAll(':', '-')
//    },

//    labelsColor() {
//      console.log(this.$vuetify.theme.dark)
//      return this.$vuetify.theme.dark ? 'white' : 'black'
//    },

    all_btn_rules() {
      return !this.source_selected || (
                      this.sunburst_chart_data[0].children[0].value === 0 &&
                      this.sunburst_chart_data[0].children[1].value === 0 &&
                      this.sunburst_chart_data[0].children[2].value === 0)
    },

    billing_btn_rules() {
      return !this.source_selected || this.sunburst_chart_data[0].children[0].value === 0
    },

    inactive_btn_rules() {
      return !this.source_selected || this.sunburst_chart_data[0].children[1].value === 0
    },

    wild_btn_rules() {
      return !this.source_selected || this.sunburst_chart_data[0].children[2].value === 0
    },

    picker_sorted() {
      return [...this.picker].sort()
    },

  },

  watch: {
    active_selected: function() {
      this.getSunburstData().then(this.getSunburst);
    },

    source_selected: function() {
      console.log('source_selected:', this.source_selected)
      this.getSunburstData().then(this.getSunburst);
    },

//    picker: function() {
//      console.log('picker:', this.picker)
//    },

  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

}
</script>

<style scoped>
.dashboardXYChart {
  width: 100%;
  height: 500px;
}
.dashboardPieChart {
  width: 100%;
  height: 500px;
}
.dashboardSunburstChart {
  width: 100%;
  height: 700px;
}
</style>