<template>
  <div class="contacts">

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogContact" max-width="500px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>{{ formContactTitle }}</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <!-- name(s) -->
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedContact.first_name"
                    :rules="[rules.required]"
                    label="first name"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedContact.middle_name"
                    label="middle name"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedContact.last_name"
                    label="last name"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider class="my-6"></v-divider>

              <!-- phone(s) -->
              <v-layout row wrap
                v-for="(phone, index) in this.editedContact.phones_list"
                :key="`phone-${index}`"
              >
                <v-flex xs10>
                  <v-text-field
                    v-model="phone.number"
                    v-mask="'+3# (###) ### ## ##'"
                    :rules="[rules.required, rules.number]"
                    :label="`phone ${index+1}`"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 class="align-self-center d-flex justify-center">
                  <v-icon @click="addPhone(phone, editedContact.phones_list)">mdi-plus</v-icon>
                  <v-icon
                      v-show="editedContact.phones_list.length > 1"
                      @click="remove(index, editedContact.phones_list)"
                    >mdi-delete-outline
                  </v-icon>
                </v-flex>
              </v-layout>
              <v-divider class="my-6" ></v-divider>

              <!-- email(s) -->
              <v-layout row wrap
                v-for="(email, index) in this.editedContact.emails_list"
                :key="`email-${index}`"
              >
                <v-flex xs10>
                  <v-text-field
                    v-model="email.mail"
                    :rules="[rules.required, rules.email]"
                    :label="`e-mail ${index+1}`"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 class="align-self-center d-flex justify-center">
                  <v-icon @click="addEmail(email, editedContact.emails_list)">mdi-plus</v-icon>
                  <v-icon
                      v-show="editedContact.emails_list.length > 1"
                      @click="remove(index, editedContact.emails_list)"
                    >mdi-delete-outline
                  </v-icon>
                </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn text @click="saveContact(editedContact)"
                   :disabled="!dialogRules"
                   color="green darken-3">save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                @click="newContact()"
                color="primary"
                depressed
              >
                <v-icon>mdi-card-plus-outline</v-icon>
              </v-btn>
            </div>
          </template>
          <span>New Contact</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <!--Filter-->
        <v-flex xs3 lg3 xl3>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="contacts"

        :options.sync="options"
        :server-items-length="totalContacts"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{'items-per-page-options': [10, 25, 50]}"

        :loading="load"
        loading-text="Contacts loading... Please wait"

        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>


        <template v-slot:item.contract_main="{ item }">
          <v-badge
            :content="item.contracts_count"
            :value="item.contracts_count"

            color="primary"
            bordered
          >

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.contract_main }}
                </div>
              </template>
              <span class="mb-0" v-html="item.contracts_tooltip"></span>
            </v-tooltip>

          </v-badge>

        </template>
        <template v-slot:item.contract_charge="{ item }">
          <v-badge
            :content="item.contracts_charge_count"
            :value="item.contracts_charge_count"

            color="primary"
            bordered
          >

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.contracts_charge }}
                </div>
              </template>
              <span class="mb-0" v-html="item.contracts_charge_tooltip"></span>
            </v-tooltip>

          </v-badge>
        </template>

        <template v-slot:item.phone_main="{ item }">
          <v-badge
            :content="item.phones_count"
            :value="item.phones_count"
            color="primary"
            bordered
          >

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  style="white-space: nowrap"
                >
                  {{ item.phone_main | VMask('+## (###) ### ## ##') }}
                </div>
              </template>
              <span class="mb-0" v-html="item.phones_tooltip"></span>
            </v-tooltip>

          </v-badge>
        </template>

        <template v-slot:item.email_main="{ item }">
          <v-badge
            :content="item.emails_count"
            :value="item.emails_count"

            color="primary"
            bordered
          >

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.email_main }}
                </div>
              </template>
              <span class="mb-0" v-html="item.emails_tooltip"></span>
            </v-tooltip>

          </v-badge>
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon @click="editContact(item)" class="mr-2">mdi-pencil</v-icon>
        </template>

      </v-data-table>

    </v-card>

  </div>
</template>

<script>
  import Vue from 'vue';
//  import VueMask from 'v-mask';
//  Vue.use(VueMask);

  export default {
    name: 'Contacts',

    data() {
      return {
        contacts: [],

        load: false,
        overlay: false,

        dialogContact: false,
        editedContact: {},
        defaultContact: {phones_list: [{number: '+38 ('},], emails_list: [{email: '@'},], },
        editedContactIndex: -1,

        contracts: [],
        contract_selected: [],

        options: {
          'page': 1,
          'itemsPerPage': 10,
          'sortBy': [],
          'sortDesc': [],
          'groupBy': [],
          'groupDesc': [],
          'mustSort': false,
          'multiSort': false
        },
        sortBy: 'contract',
        sortDesc: false,
        totalContacts: 0,

        headers: [
          { text: 'id', value: 'id' },

          { text: 'first_name', value: 'first_name', },
          { text: 'middle_name', value: 'middle_name'},
          { text: 'last_name', value: 'last_name'},

          { text: 'phone(s)', value: 'phone_main', sortable: false },
          { text: 'email(s)', value: 'email_main', sortable: false },
          { text: 'contract(s) cntr', value: 'contract_main', sortable: false },
          { text: 'contract(s) chrg', value: 'contract_charge', sortable: false },

          { text: 'edit', value: 'action', sortable: false },
        ],

        rules: {
          required: value => !!value || 'required!',
          number: value => value.length >= 19 || 'short number!',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'invalid email!'
          },
        },

      }
    },

    created() {
      this.getData();
    },

    methods: {
      getData() {
        let self = this;
        self.load = true;

        this.$http({
          method: 'get',
          url: 'core_api/contacts/',

          params: {
            contract: this.contract_selected,

            pagination: this.options,

          },
          data: {},
        })
        .then(function (response){
          self.contacts = response.data.contacts;
          self.totalContacts = response.data.contacts_count;

          self.contracts = response.data.contracts;

          self.load = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      newContact() {
        Vue.set(this, 'editedContact', Object.assign({}, this.defaultContact));
        this.dialogContact = true;
      },

      editContact(item) {
        this.editedContactIndex = this.contacts.indexOf(item);
        this.editedContact = Object.assign({}, item);

        this.dialogContact = true;
      },

      saveContact(item) {
        const self = this;

        this.$http({
          method: 'post',
          url: 'core_api/contacts/',

          params: {
            contract: this.contract_selected,

            pagination: this.options,

          },
          data: {
            contact_data: item,

            action: item.id ? 'edit' : 'create',
            method: 'action_contact',

          },
        })
        .then(function (response) {
          self.contacts = response.data.contacts;

          self.dialogContact = false;

          this.editedContact = {};
          self.alert('changes saved');
        })
        .catch(function (error) {
          console.log(error);
        })
      },

      addPhone(value, phoneList) {
        phoneList.push({ number: '' });
      },

      addEmail(value, phoneList) {
        phoneList.push({ mail: '' });
      },

      remove(index, obj) {
        obj.splice(index, 1);
      },

      close() {
        this.dialogContact = false;
        this.editedContactIndex = -1;
      },

    },

    computed: {
      formContactTitle() {
        return this.editedContactIndex === -1 ? 'New Contact' : 'Edit Contact'
      },

      dialogRules() {
        return this.editedContact.first_name && this.editedContact.emails_list &&
            this.editedContact.phones_list.every(item => item.number.length > 18)
      },

    },

    watch: {
      contract_selected: function() {
        this.options.page = 1;
        this.getData();
      },

      options: {
        handler() {
          this.getData();
        },
        deep: true,
      },

    },

  }
</script>

<style scoped>

</style>
