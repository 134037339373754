<template>
  <div class="not_found" style="margin-top: 10em">

    <v-row justify="center">
      <v-img
        src="../assets/VaultBoySet_012.png"
        max-width="400px"
        max-height="400px"
        alt="Not Found Billy Boy"
      ></v-img>
    </v-row>

    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">404 Page Not Found</h1>
        <p class="subheading font-weight-regular">
          Billy is looking for it, you can going to the <a href="/">Home</a> or <a href="/login">Login</a> pages
        </p>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: 'NotFound',

}
</script>

<style scoped>

</style>