<template>
  <div class="repair_accounts">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>


    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>

<!--Buttons-->

        <v-spacer></v-spacer>

<!--Filters-->


      </v-card-title>

      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="accounts"

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalAccounts"

        :loading="load"
        loading-text="Accounts loading... Please wait"

        mobile-breakpoint="0"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>


      </v-data-table>

    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Accounts',

  data() {
    return {
      overlay: false,
      load: false,

      accounts: [],

      dialogAccounts: false,

//      menu: false,
//      picker: new Date().toISOString().substr(0, 10),
//
//      rules: [v => 13 <= v.length || 'Min 13 characters'],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },

      sortBy: '-id',
      sortDesc: false,

      totalAccounts: 0,

      headers: [
        { text: 'id', value: 'id' },
        { text: 'ticket', value: 'ticket_id' },

        { text: 'device', value: 'name' },

        { text: 'old', value: 'old_serial' },
        { text: 'substitute', value: 'substitute_serial' },
        { text: 'new', value: 'new_serial' },

//        { text: 'datetime', value: 'date_create', sortable: false},   // , align: ' d-none d-md-table-cell'
//
//        { text: 'contractor', value: 'contractor', sortable: false},
//
//        { text: 'imei (serial)', value: 'serial_device', sortable: false },  // , align: 'center'
//        { text: 'device', value: 'name_device', sortable: false },  // , align: 'center'
//
//        { text: 'place', value: 'place', sortable: false},
//        { text: 'stage', value: 'stage', sortable: false},

        { text: 'sent', value: 'is_sent', sortable: true },
        { text: 'received', value: 'is_received', sortable: true },
        { text: 'complete', value: 'is_complete', sortable: true },

      ],

    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_accounting/',

        params: {
          pagination: this.options,

        },
        data: {},
      })
      .then(function (response) {
        self.accounts = response.data.accounts;
        self.totalAccounts = response.data.accounts_count;


        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },


    close() {
      this.dialogDevice = false;


//      this.objects_selected = [];
//      this.cart = {service: [], };
    },

  },

  computed: {},

  watch: {
    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

  },


}
</script>

<style scoped>

</style>
