<template>
  <div class="payments">

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="method_selected"
            :items="methods"
            item-text="name"
            item-value="id"
            label="Method"
            clearable
            prepend-icon="mdi-bank-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="type_selected"
            :items="types"
            item-text="name"
            item-value="id"
            label="Type"
            clearable
            prepend-icon="mdi-calendar"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="hoster_selected"
            :items="hosters"
            item-text="name"
            item-value="id"
            label="Host"
            clearable
            prepend-icon="mdi-laptop"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="source_selected"
            :items="sources"
            item-text="name"
            item-value="id"
            label="Source"
            clearable
            prepend-icon="mdi-server-network"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="contract_selected"
            :items="contracts"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Contract"
            prepend-icon="mdi-text-box-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs1 lg1 xl1>
          <v-autocomplete
            v-model="order_type_selected"
            :items="order_types"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="Mark"
            prepend-icon="mdi-bookmark-outline"
          ></v-autocomplete>
        </v-flex>
        
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="dashboardXYChart" ref="div_chart"></div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
/* Imports */
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

export default {
  name: 'Payments',

  data() {
    return {
      menu: false,
      picker: null,

      contracts: [],
      contract_selected: null,
      methods: [],
      method_selected: null,
      types: [],
      type_selected: null,
      order_types: [],
      order_type_selected: null,
      originals: [],
      original_selected: null,
      hosters: [],
      hoster_selected: null,
      sources: [],
      source_selected: null,

      chart_data: [],

    }
  },

  created() {
    this.getXYChartData().then(this.getXYChart);
  },

  methods: {
    getXYChartData() {
      let self = this;

      return this.$http({
        method: 'get',
        url: 'dashboard_api/payments_acts/',

        params: {
          method: this.method_selected,
          type: this.type_selected,
          hoster: this.hoster_selected,
          source: this.source_selected,
          contract: this.contract_selected,
          mark: this.order_type_selected,

        },
        data: {},
      })
      .then(function (response){
        self.chart_data = response.data.chart_data;

        self.hosters = response.data.hosters;
        self.types = response.data.types;
        self.methods = response.data.methods;
        self.sources = response.data.sources;
        self.contracts = response.data.contracts;
        self.order_types = response.data.order_types;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    getXYChart() {
      // Create chart instance
      let chart = am4core.create(this.$refs.div_chart, am4charts.XYChart);

      // Add data
      chart.data = this.chart_data;

      // Styles
//      chart.padding(30, 0, 10, 0);
      chart.fontSize = 15;

      // Create axes
      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
//      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 30;

      let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = 'Payments, K';

      let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.title.text = 'Acts & Orders, Qty';
      valueAxis2.renderer.opposite = true;
      valueAxis2.renderer.grid.template.disabled = true;

//Orders
      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = 'orders';
      series1.dataFields.dateX = 'date';
      series1.yAxis = valueAxis1;
      series1.name = 'Orders Billed, ₴';
      series1.tooltipText = '{name}\n[bold font-size: 15]{valueY}K[/]';
      series1.fill = chart.colors.getIndex(0).lighten(0.5);
      series1.strokeWidth = 0;
      series1.clustered = false;
      series1.toBack();

//Payments
      let series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = 'payments';
      series2.dataFields.dateX = 'date';
      series2.dataFields.dateP = 'pay_percent';
      series2.yAxis = valueAxis1;
      series2.name = 'Orders Paid, ₴';
      series2.tooltipText = '{name}\n[bold font-size: 15]{valueY}K[/] ({dateP}%)';
      series2.fill = chart.colors.getIndex(0);
      series2.strokeWidth = 0;
      series2.clustered = false;
      series2.columns.template.width = am4core.percent(40);

//Acts Sent
      let series3 = chart.series.push(new am4charts.LineSeries());
      series3.dataFields.valueY = 'acts_sent';
      series3.dataFields.dateX = 'date';
      series3.name = 'Acts Sent, Qty';
      series3.strokeWidth = 2;
      series3.tensionX = 0.7;
      series3.yAxis = valueAxis2;
      series3.tooltipText = '{name}\n[bold font-size: 15]{valueY}[/]';
      series3.strokeDasharray = '3,3';

      let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
      bullet3.circle.radius = 3;
      bullet3.circle.strokeWidth = 2;
      bullet3.circle.fill = am4core.color("#fff");

//Acts Received
      let series4 = chart.series.push(new am4charts.LineSeries());
      series4.dataFields.valueY = 'acts_received';
      series4.dataFields.dateX = 'date';
      series4.dataFields.dateP = 'acts_percent';
      series4.name = 'Acts Signed, Qty';
      series4.strokeWidth = 2;
      series4.tensionX = 0.7;
      series4.yAxis = valueAxis2;
      series4.tooltipText = '{name}\n[bold font-size: 15]{valueY}[/] ({dateP}%)';

      let bullet4 = series4.bullets.push(new am4charts.CircleBullet());
      bullet4.circle.radius = 3;
      bullet4.circle.strokeWidth = 2;
      bullet4.circle.fill = am4core.color("#fff");

//Orders Count
      let series5 = chart.series.push(new am4charts.LineSeries());
      series5.dataFields.valueY = 'orders_count';
      series5.dataFields.dateX = 'date';
      series5.name = 'Orders Billed, Qty';
      series5.strokeWidth = 2;
      series5.tensionX = 0.7;
      series5.yAxis = valueAxis2;
      series5.tooltipText = '{name}\n[bold font-size: 15]{valueY}[/]';
      series5.strokeDasharray = '3,3';

      let bullet5 = series5.bullets.push(new am4charts.Bullet());
      bullet5.width = 7;
      bullet5.height = 7;
      bullet5.fill = am4core.color("#fff");
      bullet5.horizontalCenter = "middle";
      bullet5.verticalCenter = "middle";

      let rectangle5 = bullet5.createChild(am4core.Rectangle);
      rectangle5.strokeWidth = 2;
      rectangle5.width = 5;
      rectangle5.height = 5;

//Orders Closed
      let series6 = chart.series.push(new am4charts.LineSeries());
      series6.dataFields.valueY = 'orders_closed';
      series6.dataFields.dateX = 'date';
      series6.dataFields.dateP = 'orders_percent';
      series6.name = 'Orders Paid, Qty';
      series6.strokeWidth = 2;
      series6.tensionX = 0.7;
      series6.yAxis = valueAxis2;
      series6.tooltipText = '{name}\n[bold font-size: 15]{valueY}[/] ({dateP}%)';

      let bullet6 = series6.bullets.push(new am4charts.Bullet());
      bullet6.width = 7;
      bullet6.height = 7;
      bullet6.fill = am4core.color("#fff");
      bullet6.horizontalCenter = "middle";
      bullet6.verticalCenter = "middle";

      let rectangle6 = bullet6.createChild(am4core.Rectangle);
      rectangle6.strokeWidth = 2;
      rectangle6.width = 5;
      rectangle6.height = 5;

      // Add cursor
      chart.cursor = new am4charts.XYCursor();

      // Add legend
      chart.legend = new am4charts.Legend();

      // Add scrollbar
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.marginBottom = 35;
      chart.scrollbarX.series.push(series2);
      chart.scrollbarX.series.push(series3);
      chart.scrollbarX.parent = chart.topAxesContainer;

    },

  },

  computed: {},

  watch: {
    method_selected: function() {
      this.getXYChartData().then(this.getXYChart);
    },

    type_selected: function() {
      this.getXYChartData().then(this.getXYChart);
    },

    hoster_selected: function() {
      this.getXYChartData().then(this.getXYChart);
    },

    source_selected: function() {
      this.getXYChartData().then(this.getXYChart);
    },

    contract_selected: function() {
      this.getXYChartData().then(this.getXYChart);
    },

    order_type_selected: function() {
      this.getXYChartData().then(this.getXYChart);
    },

//    menu (val) {
//      val && this.$nextTick(() => (this.picker.activePicker = 'YEAR'))
//    }
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

}
</script>

<style scoped>
.dashboardXYChart {
  width: 100%;
  height: 700px;
}
</style>