import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'

import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import i18n from './i18n'

import './registerServiceWorker'
import 'material-design-icons-iconfont/dist/material-design-icons.css'


Axios.interceptors.response.use(
    response => response,
    error => {
      // Reject promise if usual error
        if (error.response.status === 401) {
            localStorage.setItem('auth', false);
//            localStorage.setItem('session_key', null);
            localStorage.setItem('user_role', JSON.stringify({}));
            localStorage.setItem('permissions', JSON.stringify({}));
            localStorage.setItem('user_info',
                JSON.stringify({
                  'name': 'anonymous_user',
                  'first_name': 'Anonymous',
                  'last_name': 'User',
                  'role': {
                    'is_admin': false,
                    'is_manager': false,

                    'is_counter': false,
                    'is_support': false,
                    'is_sim_support': false,

                    'is_customer': false,
                  },
                  'avatar': 'anonym.png',
                })
            );

//            this.$emit('updateUserInfo');

            router.push({name: 'Login'}).catch(() => {});

            return Promise.reject(error);
        }
    }
);


function djangoServerPort() {
  console.log('process.env.NODE_ENV:', process.env.NODE_ENV);

  switch (process.env.NODE_ENV) {
//    case 'production': return '/ProductionEnv/'
    case 'development': return ':8000'
    default: return ''
  }
}

Axios.defaults.baseURL = location.protocol + '//' + location.hostname + djangoServerPort();

Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;

Vue.prototype.$http = Axios;

Vue.config.productionTip = false;

import { VueMaskDirective } from 'v-mask';
Vue.directive('mask', VueMaskDirective);

import VueMask from 'v-mask';
Vue.use(VueMask);

// vue-qrcode-component
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);

// vue-qrcode-reader
//import VueQrcodeReader from "vue-qrcode-reader";
//Vue.use(VueQrcodeReader);


new Vue({
  router,
  store,
  vuetify,
  i18n,

  VueMask,
//    StreamBarcodeReader,

  render: h => h(App)
}).$mount('#app')
